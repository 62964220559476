import React, { useState, useEffect } from 'react';
import http from '../../service'
import IndicatorsDetails from '../../Components/IndicatorsDetails';
import OccupationIcon from "../../images/OccupationIcon";
import * as S from './OccupationDetails.style';

const OccupationDetails = ({ setModal }) => {
  const [occupation, setOccupation] = useState([]);

  useEffect(() => {
    http.get(`greenhouse/greenhouseOccupacy`)
      .then(({ data }) => setOccupation(data.data))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  const tableItems = [
    { label: 'Estufa', name: 'label' },
    { label: 'Capacidade', name: 'capacity' },
    { label: 'Ocupação', name: 'actualQuantity' },
    { label: 'Percentual de Ocupação', name: 'actualPercentage' },
  ]

  let data = [];

  occupation.map((occupationDetail) => {
    data.push(
      {
        id: occupationDetail.estufa_id,
        label: occupationDetail.sectors[0].greenhouse_name,
        actualQuantity: occupationDetail.sectors[0].occupancy,
        capacity: occupationDetail.sectors[0].capacity,
        actualPercentage: occupationDetail.sectors[0].percentualOccupancy,
        totalPercentage: occupationDetail.sectors[0].totalOccupancyPercentual
      });
  });

  let percentualShortLabel = '';

  if (data?.length) {
    percentualShortLabel = data[0].totalPercentage;
  }

  const greenhouseInfo = {
    tableItems,
    label: "ocupação das estufas",
    shortLabel: "Ocupação",
    percentualShortLabel: percentualShortLabel,
    units: 485,
    color: "#ff6b57",
    icon: OccupationIcon,
    link: '/indicadores/ocupacao',
    path: ['ocupação das estufas'],
    data: data
  }

  return (
    <S.OccupationDetails>
      <IndicatorsDetails {...greenhouseInfo} />
    </S.OccupationDetails>
  );
};

export default OccupationDetails;
