import React, { useEffect, useState } from "react";
import * as S from "./DashboardWeather.style";
import http from '../../../service'
import httpPostalCode from '../../../servicePostalCode'
import httpWeather from '../../../serviceWeather'

const DashboardWeather = () => {
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const [title, setTitle] = useState('-')
  const [city, setCity] = useState('-')
  const [temp, setTemp] = useState('-')
  const [weather, setWeather] = useState('-')


  http.get(`organization/${currentUser.organization}`)
    .then(({ data: { data } }) => {


      setTitle(data.razaoSocial);

      httpPostalCode.get(`ws/${data.cep}/json`).then((dataPostalCode) => {
        let postalCode = dataPostalCode.data;

        setCity(postalCode.localidade);

        httpWeather.get(`data/2.5/weather?q=${postalCode.localidade},${postalCode.uf},bra&appid=14d447b03c4684b78bfa8f0c2a6e9567&units=metric&lang=pt_br`).then((dataWeather) => {
          let weather = dataWeather.data;

          setTemp(weather.main.temp.toFixed(0));
          setWeather(weather.weather[0].description);

        });
      });
    });

  return (
    <S.DashboardWeather>
      <S.DashboardWeatherTitle>{`${title}`}</S.DashboardWeatherTitle>
      <S.DashboardWeatherInfo>
        <S.DashboardWeatherInfoCity>{`${city}`}</S.DashboardWeatherInfoCity>
        <S.DashboardWeatherInfoTemperature>{`${temp}`}° C</S.DashboardWeatherInfoTemperature>
        <S.DashboardWeatherInfoLabel>{`${weather}`}</S.DashboardWeatherInfoLabel>
      </S.DashboardWeatherInfo>
    </S.DashboardWeather>
  );
};

export default DashboardWeather;
