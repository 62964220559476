import React from "react";
import * as S from "./DashboardNextActivities.style";

const DashboardNextActivitiesTab = ({ data }) => {
  
  return (
    <S.DashboardNextActivitiesTab>
      { data.map(({ value, label, color }) => (
        <S.DashboardNextActivitiesInfo>
          <S.DashboardNextActivitiesInfoValue color={color}>{value}</S.DashboardNextActivitiesInfoValue>
          <S.DashboardNextActivitiesInfoLabel>{label}</S.DashboardNextActivitiesInfoLabel>
        </S.DashboardNextActivitiesInfo>
      ))}
    </S.DashboardNextActivitiesTab>
  );
};

export default DashboardNextActivitiesTab;
