import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'greenhouses',
  singular: 'estufa',
  plural: 'estufas'
};

export const greenhousesSearchFieldsState = () => ({
  query: { value: '' },
});

export const greenhousesSearchFields = ({ fields }) => ({
  query:{
    ...fields.query,
    name: 'query',
    label: 'Nome',
    placeholder: 'Digite',  
  },
});

export const cardInfos = [
  { fieldLabel: 'Nome', fieldType: 'name' },
  { fieldLabel: 'Quantidades de setores', fieldType: 'sectorsAmount' },
  { fieldLabel: 'Quantidade de bancas', fieldType: 'standsAmount' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } }}) => screenXS}) {
      grid-template: ${`
        "query ."
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "name name name name"
    "sectorsAmount sectorsAmount sectorsAmount sectorsAmount"
    "standsAmount standsAmount standsAmount standsAmount"
    "history history delete delete"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "name sectorsAmount standsAmount history delete"
      ;
      grid-template-columns: 1fr 1fr 1fr 40px 40px;
    }
  }
`
