import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'measure',
  singular: 'controle de ec & ph',
  plural: 'controles de ec & ph',
  newLabel: 'Novo',
  editLabel: 'Salvar'
};

export const measureFieldsState = () => ({
  reservatory: { value: '' },
  oldEc: { value: 0 },
  ec: { value: 0 },
  oldPh: { value: 0 },
  ph: { value: 0 },
  oldTemperature: { value: 0 },
  temperature: { value: 0 },
  comments: { value: '' },
});

export const measureFields = ({ fields, id }) => {
  const newFields = ({
    reservatory: { 
      ...fields.reservatory,
      name: 'reservatory',
      label: 'Reservatório',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      disabled: id,
      loadOptions: (query, callback) => {
        http.get('waterTank')
          .then(({data}) => callback(data.data.map((option) => ({ ...option, label: option.name, value: option.id }))))
      },
      validate: (value) => ({ empty: !value }),
    },
    ec: { 
      ...fields.ec,
      name: 'ec',
      placeholder: '0',
      type: 'bigNumber',
      unity: 'EC',
      color: '#13739e',
      validate: (value) => ({ 
        empty: !value,
        invalid: !(value >= .4 && value <= 3.6),
        invalidMessage: 'Os valores precisam estar em um range de 0.4 a 3.6'
      }),
    },
    ph: { 
      ...fields.ph,
      name: 'ph',
      placeholder: '0',
      type: 'bigNumber',
      unity: 'pH',
      color: '#13739e',
      validate: (value) => ({ 
        empty: !value,
        invalid: !(value >= 4.5 && value <= 7.5),
        invalidMessage: 'Os valores precisam estar em um range de 4.5 a 7.5'
      }),
    },
    temperature: { 
      ...fields.temperature,
      name: 'temperature',
      placeholder: '0',
      type: 'bigNumber',
      unity: 'Temperatura',
      color: '#13739e',
      validate: (value) => ({ 
        empty: !value,
        invalid: !(value >= 10 && value <= 40),
        invalidMessage: 'Os valores precisam estar em um range de 10 a 40 graus'
      }),    },
    comments: { 
      ...fields.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
    },
  })
  if (id) {
    newFields.oldEc = {
      ...fields.oldEc,
      name: 'oldEc',
      label: 'EC',
      type: 'measureRef',
      color: 'brandComplementarMedium'
    };
    newFields.oldPh = {
      ...fields.oldPh,
      name: 'oldPh',
      label: 'pH',
      type: 'measureRef',
      color: 'brandComplementarDark'
    };
    delete newFields.temperature;
    if (fields.oldEc.minStd && fields.oldEc.maxStd && (fields.oldEc.value <= fields.oldEc.maxStd && fields.oldEc.value >= fields.oldEc.minStd)) {
      delete newFields.oldEc
      delete newFields.ec
    }
    if (fields.oldPh.minStd && fields.oldPh.maxStd && (fields.oldPh.value <= fields.oldPh.maxStd && fields.oldPh.value >= fields.oldPh.minStd)) {
      delete newFields.oldPh
      delete newFields.ph
    }
  }
  return newFields;
};

export const gridTemplate = css`
  grid-template: ${`
    "reservatory reservatory" 
    "oldEc oldPh" 
    "ec ec" 
    "ph ph"
    "temperature temperature"
    "comments comments"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "reservatory reservatory reservatory" 
        "oldEc oldEc oldEc"
        "oldPh oldPh oldPh"
        "ec ph temperature"
        "comments comments comments"
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
