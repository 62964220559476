import { css } from 'styled-components';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'clients',
  singular: 'cliente',
  plural: 'clientes'
};

export const clientsSearchFieldsState = () => ({
  query: { value: '' },
});

export const clientsSearchFields = ({ fields }) => ({
  query: {
    ...fields.query,
    name: 'query',
    label: 'Nome do cliente',
    placeholder: 'Digite',
  }
});

export const cardInfos = [
  { fieldLabel: 'Nome do cliente', fieldType: 'name' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD }) {
      grid-template:
        "query ."
      ;
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "name name"
    "history delete"
  ;
  grid-template-columns: 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "name history delete"
      ;
      grid-template-columns: 1fr 40px 40px;
    }
  }
`
