import React from 'react';

export default function ReportsIcon () {
  return (
    <svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M19.77 9.31C15.64 9.31 11.99 11.37 9.78 14.51L19.77 21.53V9.31ZM18.67 19.43L11.34 14.28C13.21 12.12 15.83 10.75 18.67 10.47V19.43Z" fill="#1E7C4D"/>
        <path d="M58.12 22.45H39.64C38.86 22.45 38.22 21.81 38.22 21.03C38.22 20.25 38.86 19.61 39.64 19.61H58.11C58.89 19.61 59.53 20.25 59.53 21.03C59.53 21.81 58.9 22.45 58.12 22.45Z" fill="#1E7C4D"/>
        <path d="M64.65 80.14H1.42C0.64 80.14 0 79.51 0 78.72V1.42C0 0.64 0.64 0 1.42 0H64.65C65.43 0 66.07 0.64 66.07 1.42V27C66.07 27.78 65.43 28.42 64.65 28.42C63.87 28.42 63.23 27.78 63.23 27V2.84H2.84V77.3H63.23V59.4C63.23 58.62 63.87 57.98 64.65 57.98C65.43 57.98 66.07 58.62 66.07 59.4V78.73C66.07 79.51 65.44 80.14 64.65 80.14Z" fill="#1E7C4D"/>
        <path d="M58.12 16.48H39.64C38.86 16.48 38.22 15.84 38.22 15.06C38.22 14.28 38.86 13.64 39.64 13.64H58.11C58.89 13.64 59.53 14.28 59.53 15.06C59.54 15.85 58.9 16.48 58.12 16.48Z" fill="#1E7C4D"/>
        <path d="M13.36 67.92C13.06 67.92 12.76 67.83 12.51 67.63C11.88 67.16 11.76 66.27 12.23 65.64L26 47.32C26.25 46.99 26.62 46.79 27.03 46.76C27.44 46.73 27.84 46.87 28.13 47.16L37.63 56.4L48.74 41.5C49.21 40.87 50.1 40.74 50.73 41.21C51.36 41.68 51.49 42.57 51.02 43.2L38.94 59.4C38.7 59.73 38.32 59.93 37.91 59.97C37.5 60 37.1 59.86 36.81 59.57L27.31 50.32L14.49 67.35C14.21 67.73 13.79 67.92 13.36 67.92Z" fill="#1E7C4D"/>
        <path d="M51.63 50.91C50.85 50.91 50.21 50.27 50.21 49.49V42.21L42.28 44.28C41.52 44.48 40.74 44.02 40.55 43.26C40.35 42.5 40.81 41.72 41.57 41.53L51.27 39C51.7 38.89 52.15 38.98 52.5 39.25C52.85 39.52 53.05 39.93 53.05 40.37V49.49C53.05 50.27 52.41 50.91 51.63 50.91Z" fill="#1E7C4D"/>
        <path d="M6.75 23.36C6.75 27.8 9.14 31.7 12.68 33.83L18.96 23.36L8.97 16.34C7.57 18.33 6.75 20.74 6.75 23.36ZM17.5 23.67L12.33 32.28C9.54 30.19 7.84 26.87 7.84 23.36C7.84 21.42 8.33 19.55 9.28 17.89L17.5 23.67Z" fill="#1E7C4D"/>
        <path d="M58.12 28.42H39.64C38.86 28.42 38.22 27.78 38.22 27C38.22 26.22 38.86 25.58 39.64 25.58H58.11C58.89 25.58 59.53 26.22 59.53 27C59.53 27.78 58.9 28.42 58.12 28.42Z" fill="#1E7C4D"/>
        <path d="M32.73 23.65C32.73 21.52 32.18 19.52 31.21 17.77L20.51 23.65L14.23 34.12C16.07 35.23 18.21 35.86 20.51 35.86C27.26 35.86 32.73 30.39 32.73 23.65Z" fill="#1E7C4D"/>
        <path d="M26.29 10.61C24.71 9.87 22.96 9.46 21.11 9.46V21.68L31.81 15.8C30.58 13.54 28.64 11.72 26.29 10.61Z" fill="#1E7C4D"/>
      </g>
    </svg>
  )
}
