import { css } from 'styled-components';
import http from '../../service';
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid';

export const type = 'list'
export const pageColor = '#8bc53f'

export const pageLabel = {
  name: 'campNotebook',
  singular: 'caderno de campo',
  plural: 'cadernos de campo',
  newLabel: 'Novo',
  editLabel: 'Editar',
  application: "Aplicação",
  maintenance: "Manutenção",
  deficiency: "Deficiência",
  pest: "Praga ou doença",
};

const resetAddFields = ({ setAddFields, fields }) => {
  setAddFields((oldAddFields) => {
    const newAddFields = { ...oldAddFields }
    for (const application in newAddFields) {
      if (Object.hasOwnProperty.call(newAddFields, application)) {
        for (const field in newAddFields[application]) {
          if (Object.hasOwnProperty.call(newAddFields[application], field)) {
            if (field === 'sector') {
              const sectors = fields.greenHouse.value.sectors.map((sector) => ({ ...sector, label: sector.name, value: sector.id }));
              newAddFields[application][field].value = sectors
            } else if (field === 'stand') {
              const stands = fields.greenHouse.value.sectors?.reduce((arr, sector) => [...arr, ...sector.stand.map((std) => ({ ...std, label: std.name, value: std.id }))], []);
              newAddFields[application][field].value = stands
            } else if (field === 'lot') {
              const lots = fields.greenHouse.value.sectors?.reduce((arr, sector) => [...arr, ...sector.stand?.reduce((sctArr, std) => [...sctArr, ...std.activeLotId.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], [])], []);
              newAddFields[application][field].value = lots
            } else if (field === 'date') {
              newAddFields[application][field].value = new Date();
            } else {
              newAddFields[application][field].value = ''
            }
          }
        }
      }
    }
    return newAddFields;
  });
}

export const plantingLotFieldsState = () => ({
  greenHouse: { value: '' },
  temperature: { value: '' },
  humidity: { value: '' },
  comments: { value: '' },
  status: { value: '' },
  annotations: { value: [] },
});

export const plantingLotAddFieldsState = () => ({
  application: {
    input: { value: '' },
    amount: { value: '' },
    date: { value: new Date() },
    sector: { value: '' },
    stand: { value: '' },
    lot: { value: '' },
    images: { value: '' },
    filename: { value: '' },
    idObject: { value: '' },
    comments: { value: '' },
  },
  maintenance: {
    comments: { value: '' },
    date: { value: new Date() },
    images: { value: '' },
    filename: { value: '' },
    idObject: { value: '' }
  },
  deficiency: {
    input: { value: '' },
    images: { value: '' },
    filename: { value: '' },
    idObject: { value: '' },
    amount: { value: '' },
    date: { value: new Date() },
    sector: { value: '' },
    stand: { value: '' },
    lot: { value: '' },
    comments: { value: '' },
  },
  pest: {
    pest: { value: '' },
    input: { value: '' },
    amount: { value: '' },
    date: { value: new Date() },
    sector: { value: '' },
    stand: { value: '' },
    lot: { value: '' },
    images: { value: '' },
    filename: { value: '' },
    idObject: { value: '' },
    comments: { value: '' },
  },
});

export const plantingLotAddFields = ({ fields, addFields, setFields, setAddFields, setAddField }) => ({
  application: {
    input: {
      ...addFields.application.input,
      name: 'input',
      label: 'Nutriente, receita ou defensivo',
      placeholder: 'Selecione',
      loadEmpty: true,
      isSearchable: false,
      type: 'select',
      loadOptions: async (query, callback) => {
        const inputs = await http.get('/input?inputGroup1=NUTRIENTES&inputGroup2=DEFENSIVOS')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || []
        const recipes = await http.get('/recipes')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || [];
        callback([...inputs, ...recipes])
      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.application[name].value = value;
          return newFields;
        })
      },
    },
    amount: {
      ...addFields.application.amount,
      name: 'amount',
      label: 'Quantidade',
      placeholder: 'Digite',
      type: 'number',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.application[name].value = value;
          return newFields;
        })
      },
    },
    date: {
      ...addFields?.application.date,
      name: 'date',
      label: 'Data',
      type: 'date',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.application[name].value = value;
          return newFields;
        })
      },
    },
    filename: {
      ...addFields?.application.filename,
      name: 'filename',
      placeholder: '',
      type: 'text',
      disabled: true
    },
    images: {
      ...addFields?.application.images,
      name: 'images',
      type: 'file',
      id: 'images',
      accept: '.gif,.jpg,.jpeg,.png',
      onChange: ({ target }) => {
        setAddFields(oldFields => {
          let fileName = '';
          var arquivo = document.getElementById("images");

          if (arquivo.files[0]) {
            fileName = arquivo.files[0]['name'];

            if (fileName.indexOf('.gif', fileName.length - '.gif'.length) === -1
              && fileName.indexOf('.jpg', fileName.length - '.jpg'.length) === -1
              && fileName.indexOf('.jpeg', fileName.length - '.jpeg'.length) === -1
              && fileName.indexOf('.png', fileName.length - '.png'.length) === -1
            ) {
              fileName = '';
            }
            else {
              fileName = uuidv4() + '-' + fileName;

              const uploadFile = file => new Promise(() => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  http.post('uploader', { file: reader.result, originalname: fileName })
                    .then(({ data }) => {

                    })
                    .catch((err) => console.log(err));
                };
                reader.onerror = error => console.log(error);
              });

              uploadFile(arquivo.files[0]);

            }
          }
          else {
            fileName = '';
          }

          const newFields = { ...oldFields }
          newFields.application['filename'].value = fileName;
          newFields.application['idObject'].value = uuidv4();
          return newFields;
        })
      },
    },
    sector: {
      ...addFields.application.sector,
      name: 'sector',
      label: 'Setor',
      placeholder: 'selecione',
      type: 'select',
      loadEmpty: true,
      isMulti: true,
      isSearchable: false,
      loadOptions: (query, callback) => {
        http.get('/greenhouse/' + fields?.greenHouse.value.value)
          .then(({ data }) => callback(data.data.sectors.map((option) => ({ ...option, label: option.name, value: option.id }))))
      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const stands = value?.reduce((arr, sector) => [...arr, ...sector.stand.map((std) => ({ ...std, label: std.name, value: std.id }))], []) || '';
          const lots = value?.reduce((arr, sector) => [...arr, ...sector.stand?.reduce((sctArr, std) => [...sctArr, ...std.activeLotId.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''], []) || '';
          newFields.application[name].value = value;
          newFields.application.stand.value = stands
          newFields.application.stand.options = stands
          newFields.application.lot.value = lots
          newFields.application.lot.options = lots
          return newFields;
        })
      },
    },
    stand: {
      ...addFields.application.stand,
      name: 'stand',
      label: 'Banca',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      loadOptions: (query, callback) => {
        http.get('/greenhouse/' + fields?.greenHouse.value.value + "/sector")
          .then(({ data }) => callback(
            data.data.map(
              option => ({ ...option, label: option.name, value: option.id })
            )
          ))

      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const lots = value?.reduce((sctArr, std) => [...sctArr, ...std?.activeLotId?.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''
          newFields.application[name].value = value;
          newFields.application.lot.value = lots
          newFields.application.lot.options = lots
          return newFields;
        })
      },
    },
    lot: {
      ...addFields.application.lot,
      name: 'lot',
      label: 'Lote',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      loadOptions: (query, callback) => {
        http.get('/greenhouse/' + fields?.greenHouse.value.value + "/lot")
          .then(({ data }) => callback(
            data.data.map(
              option => ({ ...option, label: option, value: option })
            )
          ))

      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.application[name].value = value;
          return newFields;
        })
      },
    },
    comments: {
      ...addFields.application.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.application[name].value = value;
          return newFields;
        })
      },
    },
    addButton: {
      name: 'addButton',
      label: 'Adicionar',
      type: 'button',
      variation: 'primary',
      onClick: () => {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          const newAnnotation = cloneDeep(addFields.application);
          newFields.annotations.value = [...oldFields.annotations.value, { ...newAnnotation, type: 'application' }]
          return newFields;
        })
        setAddField();
      },
      disabled: !addFields.application.date?.value || !addFields.application.sector.value?.length || !addFields.application.stand.value?.length || !addFields.application.lot.value?.length
    },
    cancelButton: {
      name: 'cancelButton',
      label: 'Cancelar',
      type: 'button',
      variation: 'danger',
      onClick: () => {
        setAddField();
      },
    },
  },
  maintenance: {
    comments: {
      ...addFields?.maintenance.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.maintenance[name].value = value;
          return newFields;
        })
      },
    },
    date: {
      ...addFields?.maintenance.date,
      name: 'date',
      label: 'Data',
      type: 'date',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.maintenance[name].value = value;
          return newFields;
        })
      },
    },
    filename: {
      ...addFields?.maintenance.filename,
      name: 'filename',
      placeholder: '',
      type: 'text',
      disabled: true
    },
    images: {
      ...addFields?.maintenance.images,
      name: 'images',
      type: 'file',
      id: 'images',
      accept: '.gif,.jpg,.jpeg,.png',
      onChange: ({ target }) => {
        setAddFields(oldFields => {
          let fileName = '';
          var arquivo = document.getElementById("images");

          if (arquivo.files[0]) {
            fileName = arquivo.files[0]['name'];

            if (fileName.indexOf('.gif', fileName.length - '.gif'.length) === -1
              && fileName.indexOf('.jpg', fileName.length - '.jpg'.length) === -1
              && fileName.indexOf('.jpeg', fileName.length - '.jpeg'.length) === -1
              && fileName.indexOf('.png', fileName.length - '.png'.length) === -1
            ) {
              fileName = '';
            }
            else {
              fileName = uuidv4() + '-' + fileName;

              const uploadFile = file => new Promise(() => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  http.post('uploader', { file: reader.result, originalname: fileName })
                    .then(({ data }) => {

                    })
                    .catch((err) => console.log(err));
                };
                reader.onerror = error => console.log(error);
              });

              uploadFile(arquivo.files[0]);

            }
          }
          else {
            fileName = '';
          }

          const newFields = { ...oldFields }
          newFields.maintenance['filename'].value = fileName;
          newFields.maintenance['idObject'].value = uuidv4();
          return newFields;
        })
      },
    },
    addButton: {
      name: 'addButton',
      label: 'Adicionar',
      type: 'button',
      variation: 'primary',
      onClick: () => {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          const newAnnotation = cloneDeep(addFields.maintenance);
          newFields.annotations.value = [...oldFields.annotations.value, { ...newAnnotation, type: 'maintenance' }]
          return newFields;
        })
        setAddField();
      },
    },
    cancelButton: {
      name: 'cancelButton',
      label: 'Cancelar',
      type: 'button',
      variation: 'danger',
      onClick: () => {
        setAddField();
      },
    },
  },
  deficiency: {
    input: {
      ...addFields?.deficiency.input,
      name: 'input',
      label: 'Nutriente ou receita',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      loadOptions: async (query, callback) => {
        const inputs = await http.get('/input?inputGroup1=NUTRIENTES')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || []
        const recipes = await http.get('/recipes')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || []
        callback([...inputs, ...recipes])
      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.deficiency[name].value = value;
          return newFields;
        })
      },
    },
    amount: {
      ...addFields?.deficiency.amount,
      name: 'amount',
      label: 'Quantidade',
      placeholder: 'Digite',
      type: 'number',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.deficiency[name].value = value;
          return newFields;
        })
      },
    },
    date: {
      ...addFields?.deficiency.date,
      name: 'date',
      label: 'Data',
      type: 'date',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.deficiency[name].value = value;
          return newFields;
        })
      },
    },
    filename: {
      ...addFields?.deficiency.filename,
      name: 'filename',
      placeholder: '',
      type: 'text',
      disabled: true
    },
    images: {
      ...addFields?.deficiency.images,
      name: 'images',
      type: 'file',
      id: 'images',
      accept: '.gif,.jpg,.jpeg,.png',
      onChange: ({ target }) => {
        setAddFields(oldFields => {
          let fileName = '';
          var arquivo = document.getElementById("images");

          if (arquivo.files[0]) {
            fileName = arquivo.files[0]['name'];

            if (fileName.indexOf('.gif', fileName.length - '.gif'.length) === -1
              && fileName.indexOf('.jpg', fileName.length - '.jpg'.length) === -1
              && fileName.indexOf('.jpeg', fileName.length - '.jpeg'.length) === -1
              && fileName.indexOf('.png', fileName.length - '.png'.length) === -1
            ) {
              fileName = '';
            }
            else {
              fileName = uuidv4() + '-' + fileName;

              const uploadFile = file => new Promise(() => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  http.post('uploader', { file: reader.result, originalname: fileName })
                    .then(({ data }) => {

                    })
                    .catch((err) => console.log(err));
                };
                reader.onerror = error => console.log(error);
              });

              uploadFile(arquivo.files[0]);

            }
          }
          else {
            fileName = '';
          }

          const newFields = { ...oldFields }
          newFields.deficiency['filename'].value = fileName;
          newFields.deficiency['idObject'].value = uuidv4();
          return newFields;
        })
      },
    },
    sector: {
      ...addFields?.deficiency.sector,
      name: 'sector',
      label: 'Setor',
      placeholder: 'selecione',
      type: 'select',
      loadEmpty: true,
      isMulti: true,
      isSearchable: false,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const stands = value?.reduce((arr, sector) => [...arr, ...sector.stand.map((std) => ({ ...std, label: std.name, value: std.id }))], []) || '';
          const lots = value?.reduce((arr, sector) => [...arr, ...sector.stand?.reduce((sctArr, std) => [...sctArr, ...std.activeLotId.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''], []) || '';
          newFields.deficiency[name].value = value;
          newFields.deficiency.stand.value = stands
          newFields.deficiency.stand.options = stands
          newFields.deficiency.lot.value = lots
          newFields.deficiency.lot.options = lots

          return newFields;
        })
      },
    },
    stand: {
      ...addFields?.deficiency.stand,
      name: 'stand',
      label: 'Banca',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const lots = value?.reduce((sctArr, std) => [...sctArr, ...std?.activeLotId?.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''
          newFields.deficiency[name].value = value;
          newFields.deficiency.lot.value = lots
          newFields.deficiency.lot.options = lots
          return newFields;
        })
      },
    },
    lot: {
      ...addFields?.deficiency.lot,
      name: 'lot',
      label: 'Lote',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.deficiency[name].value = value;
          return newFields;
        })
      },
    },
    comments: {
      ...addFields?.deficiency.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.deficiency[name].value = value;
          return newFields;
        })
      },
    },
    addButton: {
      name: 'addButton',
      label: 'Adicionar',
      type: 'button',
      variation: 'primary',
      onClick: () => {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          const newAnnotation = cloneDeep(addFields.deficiency);
          newFields.annotations.value = [...oldFields.annotations.value, { ...newAnnotation, type: 'deficiency' }]
          return newFields;
        })
        setAddField();
      },
      disabled: !addFields.deficiency.date.value || !addFields.deficiency.sector.value?.length || !addFields.deficiency.stand.value?.length || !addFields.deficiency.lot.value?.length
    },
    cancelButton: {
      name: 'cancelButton',
      label: 'Cancelar',
      type: 'button',
      variation: 'danger',
      onClick: () => {
        setAddField();
      },
    },
  },
  pest: {
    pest: {
      ...addFields?.pest.pest,
      name: 'pest',
      label: 'Praga ou doença',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      loadOptions: (query, callback) => {
        http.get('/bugs')
          .then(({ data }) => callback(data.data.map((option) => ({ ...option, label: option.nomeVulgar, value: option.id }))))
      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    input: {
      ...addFields?.pest.input,
      name: 'input',
      label: 'Defensivos ou receitas',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      loadOptions: async (query, callback) => {
        const inputs = await http.get('/input?inputGroup1=DEFENSIVOS')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || []
        const recipes = await http.get('/recipes')
          .then(({ data }) => data.data.map((option) => ({ ...option, label: option.name, value: option.id })))
          .catch((res) => console.log(res)) || []
        callback([...inputs, ...recipes])
      },
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    amount: {
      ...addFields?.pest.amount,
      name: 'amount',
      label: 'Quantidade',
      placeholder: 'Digite',
      type: 'number',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    date: {
      ...addFields?.pest.date,
      name: 'date',
      label: 'Data',
      type: 'date',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    filename: {
      ...addFields?.pest.filename,
      name: 'filename',
      placeholder: '',
      type: 'text',
      disabled: true
    },
    images: {
      ...addFields?.pest.images,
      name: 'images',
      type: 'file',
      id: 'images',
      accept: '.gif,.jpg,.jpeg,.png',
      onChange: ({ target }) => {
        setAddFields(oldFields => {
          let fileName = '';
          var arquivo = document.getElementById("images");

          if (arquivo.files[0]) {
            fileName = arquivo.files[0]['name'];

            if (fileName.indexOf('.gif', fileName.length - '.gif'.length) === -1
              && fileName.indexOf('.jpg', fileName.length - '.jpg'.length) === -1
              && fileName.indexOf('.jpeg', fileName.length - '.jpeg'.length) === -1
              && fileName.indexOf('.png', fileName.length - '.png'.length) === -1
            ) {
              fileName = '';
            }
            else {
              fileName = uuidv4() + '-' + fileName;

              const uploadFile = file => new Promise(() => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  http.post('uploader', { file: reader.result, originalname: fileName })
                    .then(({ data }) => {

                    })
                    .catch((err) => console.log(err));
                };
                reader.onerror = error => console.log(error);
              });

              uploadFile(arquivo.files[0]);

            }
          }
          else {
            fileName = '';
          }

          const newFields = { ...oldFields }
          newFields.pest['filename'].value = fileName;
          newFields.pest['idObject'].value = uuidv4();
          return newFields;
        })
      },
    },
    sector: {
      ...addFields?.pest.sector,
      name: 'sector',
      label: 'Setor',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const stands = value?.reduce((arr, sector) => [...arr, ...sector.stand.map((std) => ({ ...std, label: std.name, value: std.id }))], []) || '';
          const lots = value?.reduce((arr, sector) => [...arr, ...sector.stand?.reduce((sctArr, std) => [...sctArr, ...std.activeLotId.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''], []) || '';

          newFields.pest[name].value = value;

          newFields.pest.stand.value = stands
          newFields.pest.stand.options = stands

          newFields.pest.lot.value = lots
          newFields.pest.lot.options = lots

          return newFields;
        })
      },
    },
    stand: {
      ...addFields?.pest.stand,
      name: 'stand',
      label: 'Banca',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          const lots = value?.reduce((sctArr, std) => [...sctArr, ...std?.activeLotId?.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], []) || ''

          newFields.pest[name].value = value;

          newFields.pest.lot.value = lots
          newFields.pest.lot.options = lots
          return newFields;
        })
      },
    },
    lot: {
      ...addFields?.pest.lot,
      name: 'lot',
      label: 'Lote',
      placeholder: 'selecione',
      type: 'select',
      isMulti: true,
      isSearchable: false,
      loadEmpty: true,
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    comments: {
      ...addFields?.pest.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
      onChange: ({ target: { value, name } }) => {
        setAddFields(oldFields => {
          const newFields = { ...oldFields }
          newFields.pest[name].value = value;
          return newFields;
        })
      },
    },
    addButton: {
      name: 'addButton',
      label: 'Adicionar',
      type: 'button',
      variation: 'primary',
      onClick: () => {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          const newAnnotation = cloneDeep(addFields.pest);
          newFields.annotations.value = [...oldFields.annotations.value, { ...newAnnotation, type: 'pest' }]
          return newFields;
        })
        setAddField();
      },
      disabled: !addFields.pest.date.value || !addFields.pest.sector.value?.length || !addFields.pest.stand.value?.length || !addFields.pest.lot.value?.length

    },
    cancelButton: {
      name: 'cancelButton',
      label: 'Cancelar',
      type: 'button',
      variation: 'danger',
      onClick: () => {
        setAddField();
      },
    },
  }
})

export const plantingLotFields = ({ fields, setAddField, setAddFields }) => ({
  greenHouse: {
    ...fields?.greenHouse,
    name: 'greenHouse',
    label: 'Estufa',
    disabled: fields.status?.value === 'Concluído',
    placeholder: 'Selecione',
    loadOptions: (query, callback) => {
      http.get('/greenhouse')
        .then(({ data }) => callback(data.data.map((option) => ({ ...option, label: option.name, value: option.id }))))
    },
    onChange: ({ target: { value, name }, setFields }) => {
      setAddFields(oldFields => {
        const newFields = { ...oldFields }
        const sectors = value.sectors.map((sector) => ({ ...sector, label: sector.name, value: sector.id }));
        const stands = value.sectors?.reduce((arr, sector) => [...arr, ...sector.stand.map((std) => ({ ...std, label: std.name, value: std.id }))], []);
        const lots = value.sectors?.reduce((arr, sector) => [...arr, ...sector.stand?.reduce((sctArr, std) => [...sctArr, ...std.activeLotId.map((lot) => ({ ...lot, label: `${lot.substring(0, 16)}...`, value: lot }))], [])], []);

        newFields.application.sector.value = sectors
        newFields.application.sector.options = sectors
        newFields.deficiency.sector.value = sectors
        newFields.deficiency.sector.options = sectors
        newFields.pest.sector.value = sectors
        newFields.pest.sector.options = sectors

        newFields.application.stand.value = stands
        newFields.application.stand.options = stands
        newFields.deficiency.stand.value = stands
        newFields.deficiency.stand.options = stands
        newFields.pest.stand.value = stands
        newFields.pest.stand.options = stands

        newFields.application.lot.value = lots
        newFields.application.lot.options = lots
        newFields.deficiency.lot.value = lots
        newFields.deficiency.lot.options = lots
        newFields.pest.lot.value = lots
        newFields.pest.lot.options = lots

        return newFields;
      })
      setFields(oldFields => {
        const newFields = { ...oldFields }
        newFields[name].value = value;
        return newFields;
      })
    },
    loadEmpty: true,
    isSearchable: false,
    type: 'select',
    validate: (value) => ({
      empty: !value
    })
  },
  temperature: {
    ...fields?.temperature,
    name: 'temperature',
    label: 'Temperatura',
    disabled: fields.status?.value === 'Concluído',
    placeholder: 'Digite',
    type: 'number',
    suffix: '°',
    validate: (value) => ({
      empty: !value
    })
  },
  humidity: {
    ...fields?.humidity,
    name: 'humidity',
    label: 'Umidade',
    disabled: fields.status?.value === 'Concluído',
    placeholder: 'Digite',
    type: 'number',
    suffix: '%',
    validate: (value) => ({
      empty: !value
    })
  },
  comments: {
    ...fields?.humicommentsdity,
    name: 'comments',
    label: 'Observações',
    disabled: fields.status?.value === 'Concluído',
    placeholder: 'Insira sua observação',
    type: 'textarea',
  },
  addApplication: {
    name: 'addApplication',
    label: 'Adicionar aplicação +',
    type: 'button',
    variation: 'primary',
    disabled: !fields.greenHouse?.value || fields.status?.value === 'Concluído',
    onClick: () => {
      resetAddFields({ setAddFields, fields })
      setAddField('application')
    },
  },
  addMaintenance: {
    name: 'addMaintenance',
    label: 'Adicionar manutenção +',
    type: 'button',
    variation: 'warning',
    disabled: !fields.greenHouse?.value || fields.status?.value === 'Concluído',
    onClick: () => {
      resetAddFields({ setAddFields, fields })
      setAddField('maintenance')
    }
  },
  addDeficiency: {
    name: 'addDeficiency',
    label: 'Adicionar deficiência +',
    type: 'button',
    variation: 'danger',
    disabled: !fields.greenHouse?.value || fields.status?.value === 'Concluído',
    onClick: () => {
      resetAddFields({ setAddFields, fields })
      setAddField('deficiency')
    }
  },
  addPest: {
    name: 'addPest',
    label: 'Adicionar praga +',
    type: 'button',
    variation: 'danger',
    disabled: !fields.greenHouse?.value || fields.status?.value === 'Concluído',
    onClick: () => {
      resetAddFields({ setAddFields, fields })
      setAddField('pest')
    }
  },
});

export const gridAddTemplate = {
  application: css`
    grid-template:
      "input"
      "amount"
      "images"
      "filename"
      "date"
      "sector"
      "stand"
      "lot"
      "comments"
      ${({ disabled }) => !disabled && `
        "addButton"
        "cancelButton"
      `}
    ;

    @media screen {
      @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
        grid-template: 
          "date date images filename filename filename"
          "input input input amount amount ." 
          "sector sector stand stand lot lot"
          "comments comments comments comments comments comments"
          ${({ disabled }) => !disabled && `"addButton addButton cancelButton cancelButton . ."`}
        ;
        grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr 1fr;
      }
    }
  `,
  maintenance: css`
    grid-template: 
      "comments"
      "date"
      "images"
      "filename"
      ${({ disabled }) => !disabled && `
        "addButton"
        "cancelButton"
      `}
    ;

    @media screen {
      @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
        grid-template: 
          "date date images filename filename filename"
          "comments comments comments comments comments comments" 
          ${({ disabled }) => !disabled && `"addButton addButton cancelButton cancelButton . ."`}
        ;
        grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr 1fr;
      }
    }
  `,
  deficiency: css`
    grid-template:
      "input"
      "amount"
      "date"
      "images"
      "sector"
      "stand"
      "lot"
      "comments"
      ${({ disabled }) => !disabled && `
        "addButton"
        "cancelButton"
      `}
    ;

    @media screen {
      @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
        grid-template:
          "date date images filename filename filename" 
          "input input input amount amount ."           "sector sector stand stand lot lot"
          "comments comments comments comments comments comments"
          ${({ disabled }) => !disabled && `"addButton addButton cancelButton cancelButton . ."`}
        ;
        grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr 1fr;
      }
    }
  `,
  pest: css`
    grid-template:
      "pest"
      "input"
      "amount"
      "date"
      "images"
      "sector"
      "stand"
      "lot"
      "comments"
      ${({ disabled }) => !disabled && `
        "addButton"
        "cancelButton"
      `}
    ;

    @media screen {
      @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
        grid-template: 
          "date date images filename filename filename" 
          "pest pest pest . . ." 
          "input input input amount amount amount" 
          "sector sector stand stand lot lot"
          "comments comments comments comments comments comments"
          ${({ disabled }) => !disabled && `"addButton addButton cancelButton cancelButton . ."`}
        ;
        grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr 1fr;
      }
    }
  `
}

export const gridTemplate = css`
  grid-template: ${`
    "greenHouse" 
    "temperature" 
    "humidity"
    "comments"
    "addApplication"
    "addMaintenance"
    "addDeficiency"
    "addPest"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template: ${`
        "greenHouse greenHouse temperature humidity" 
        "comments comments comments comments"
        "addApplication addApplication addMaintenance addMaintenance"
        "addDeficiency addDeficiency addPest addPest"
      `};
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`
