import React from 'react';
import { inputChange, inputValidate } from '../../helpers/fieldFunctions';
import * as S from './Input.style';

export default function InputText({ onChange, setFields, i, parentI, validate, onBlur, ...props }) {
  return (
    <S.InputDate
      {...props}
      disableClock
      onChange={(value) => (onChange
          ? onChange({ target: { value, name: props.name }, setFields })
          : inputChange({ target: { value, name: props.name  }, setFields })
        )}
      maskChar=""
      onBlur={({ target }) => inputValidate({ target: { ...target, value: props.value, i, parentI, name: props.name }, setFields, validate, onBlur })}
    />
  );
}
