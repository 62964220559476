import styled from "styled-components";

export const LoginForm = styled.div`
  ${({ gridTemplate }) => gridTemplate};
  position: relative;
  background-color: white;
  border-radius: 20px;
  padding: 84px 20px 20px;
  grid-row-gap: 16px;
  display: grid;
  margin: auto;
  box-shadow: ${({theme: { boxShadow: { boxShadowLG }}}) => boxShadowLG};
  margin-bottom: 30px;


  >button{
    box-shadow: 0 4px 4px rgba(0,0,0,.15);
  }

`
export const PassRemember = styled.div`
  position: absolute;
  bottom: 11.5em;
  right: 20px;
  font-size: ${({theme: { fontSize: { fontSizeXXXXS }}}) => fontSizeXXXXS};
  color: ${({theme: { color: { brandComplementar }}}) => brandComplementar};
`
