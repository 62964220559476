import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';
import { addDays } from 'date-fns';
export const type = 'list'
export const pageColor = '#00a1a5'
export const pageLabel = {
  name: 'activities',
  singular: 'Atividade',
  plural: 'Atividades'
};

export const activitiesSearchFieldsState = () => ({
  query: { value: ''},
});

export const activitiesSearchFields = ({ fields, setFields }) => ({
  query: { 
    ...fields.query,
    name: 'query',
    label: 'Atividade',
    placeholder: 'Selecione',
    options: [
      { label: 'Transferência', value: 'Transferência' },
      { label: 'Colheita', value: 'Colheita' },
      { label: 'Aplicação Nutriente', value: 'Aplicação Nutriente' },
      { label: 'Lavagem de Caixa', value: 'Lavagem de Caixa' }
    ],
    type: 'select'
  },
});

export const cardInfos = [
  { fieldLabel: 'Data', fieldType: 'plannedDate' },
  { fieldLabel: 'Descrição da atividade', fieldType: 'description' },
  { fieldLabel: '', fieldType: 'activityType' },
  { fieldLabel: '', fieldType: 'activityPostpone' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } }}) => screenXS}) {
      grid-template: ${`
        "query query" 
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "plannedDate"
    "description"
    "activityPostpone"
    "activityType"
  ;
  grid-template-columns: 1fr;

  @media screen {  
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD }) {
      grid-template:
        "plannedDate description activityPostpone activityType"
      ;
      grid-template-columns: 80px 1fr 80px 220px;
    }
  }
`
