import Dashboard from '../Pages/Dashboard';
import MaternityDetails from '../Pages/MaternityDetails';
import NurseryDetails from '../Pages/NurseryDetails';
import VivariumDetails from '../Pages/VivariumDetails';
import FinalPhaseDetails from '../Pages/FinalPhaseDetails';
import OccupationDetails from '../Pages/OccupationDetails';
import PlantingLots from '../Pages/PlantingLots';
import PlantingLot from '../Pages/PlantingLot';
import CampNotebooks from '../Pages/CampNotebooks';
import CampNotebook from '../Pages/CampNotebook';
import Measures from '../Pages/Measures';
import Measure from '../Pages/Measure';
import Plans from '../Pages/Plans';
import Plan from '../Pages/Plan';
import Farm from '../Pages/Farm';
import Users from '../Pages/Users';
import User from '../Pages/User';
import NutritiveSolutions from '../Pages/NutritiveSolutions'
import NutritiveSolution from '../Pages/NutritiveSolution'
import Inputs from '../Pages/Inputs'
import Input from '../Pages/Input'
import Greeneries from '../Pages/Greeneries'
import Greenery from '../Pages/Greenery'
import Recipes from '../Pages/Recipes'
import Recipe from '../Pages/Recipe'
import Pests from '../Pages/Pests'
import Pest from '../Pages/Pest'
import Groups from '../Pages/Groups'
import Group from '../Pages/Group'
import Manufacturer from'../Pages/Manufacturer'
import Manufacturers from'../Pages/Manufacturers'
import Greenhouses from '../Pages/Greenhouses'
import Greenhouse from '../Pages/Greenhouse'
import WaterTanks from '../Pages/WaterTanks'
import WaterTank from '../Pages/WaterTank'
import Activities from '../Pages/Activities'
import Activity from '../Pages/Activity'
import NewActivity from '../Pages/NewActivity'
import NewPlantingLot from '../Pages/NewPlantingLot'
import PlantingLotsReport from '../Pages/PlantingLotsReport'
import HarvestedLotsReport from '../Pages/HarvestedLotsReport'
import ActivitiesReport from '../Pages/ActivitiesReport'
import Clients from '../Pages/Clients'
import Client from '../Pages/Client'
import Shipping from '../Pages/Shipping'
import Shippings from '../Pages/Shippings'

export const routes = [
  { path: '/atividades/novo', component: NewActivity},
  { path: '/atividades/:id', component: Activity},
  { path: '/atividades', component: Activities},
  { path: '/solucoes-nutritivas/novo', component: NutritiveSolution},
  { path: '/solucoes-nutritivas/:id', component: NutritiveSolution},
  { path: '/solucoes-nutritivas', component: NutritiveSolutions},
  { path: '/estufas/novo', component: Greenhouse},
  { path: '/estufas/:id', component: Greenhouse},
  { path: '/estufas', component: Greenhouses },
  { path: '/fabricantes/novo', component: Manufacturer},
  { path: '/fabricantes/:id', component: Manufacturer},
  { path: '/fabricantes', component: Manufacturers},
  { path: '/pragas/novo', component: Pest},
  { path: '/pragas/:id', component: Pest},
  { path: '/pragas', component: Pests} ,
  { path: '/grupos/novo', component: Group},
  { path: '/grupos/:id', component: Group},
  { path: '/grupos', component: Groups} ,
  { path: '/receitas/novo', component: Recipe},
  { path: '/receitas/:id', component: Recipe},
  { path: '/receitas', component: Recipes },
  { path: '/hortalicas/novo', component: Greenery},
  { path: '/hortalicas/:id', component: Greenery},
  { path: '/hortalicas', component: Greeneries },
  { path: '/usuarios/novo', component: User},
  { path: '/usuarios/:id', component: User},
  { path: '/usuarios', component: Users },
  { path: '/insumos/novo', component: Input},
  { path: '/insumos/:id', component: Input},
  { path: '/insumos', component: Inputs },
  { path: '/expedicoes/novo', component: Shipping},
  { path: '/expedicoes/:id', component: Shipping},
  { path: '/expedicoes', component: Shippings },
  { path: '/clientes/novo', component: Client},
  { path: '/clientes/:id', component: Client},
  { path: '/clientes', component: Clients },
  { path: '/hidroponia/:id', component: Farm },
  { path: '/planos/novo', component: Plan } ,
  { path: '/planos/:id', component: Plan },
  { path: '/planos', component: Plans},
  { path: '/caixas-dagua/novo', component: WaterTank },
  { path: '/caixas-dagua/:id', component: WaterTank },
  { path: '/caixas-dagua', component: WaterTanks },
  { path: '/cadernos-de-campo/novo', component: CampNotebook },
  { path: '/cadernos-de-campo/:id', component: CampNotebook },
  { path: '/cadernos-de-campo', component: CampNotebooks },
  { path: '/controles-de-ph-&-ec/novo', component: Measure },
  { path: '/controles-de-ph-&-ec/:id', component: Measure },
  { path: '/controles-de-ph-&-ec', component: Measures },
  { path: '/lotes-de-plantio/novo', component: NewPlantingLot },
  { path: '/lotes-de-plantio/:id', component: PlantingLot },
  { path: '/lotes-de-plantio', component: PlantingLots },
  { path: '/indicadores/maternidade', component: MaternityDetails },
  { path: '/indicadores/bercario', component: NurseryDetails },
  { path: '/indicadores/viveiro', component: VivariumDetails },
  { path: '/indicadores/fase-final', component: FinalPhaseDetails },
  { path: '/indicadores/ocupacao', component: OccupationDetails },
  { path: '/relatorios/atividades', component: ActivitiesReport },
  { path: '/relatorios/atividade/:dias', component: ActivitiesReport },
  { path: '/relatorios/previsao-de-colheita', component: PlantingLotsReport },
  { path: '/relatorios/previsao-de-colheitas/:dias', component: PlantingLotsReport },
  { path: '/relatorios/colheita', component: HarvestedLotsReport },
  { path: '/relatorios/colheitas/:dias', component: HarvestedLotsReport },
  { path: '/', component: Dashboard },
]
