import React from 'react';
import * as S from './ConfirmModal.style';
import Button from './../Button';
import http from '../../service'

export default function ConfirmModal({ setModal, item, index }) {

  return (
    <S.ConfirmModal>
      <S.ConfirmModalCall>Confirma excluir item ?</S.ConfirmModalCall>
      <Button name="cancel" label="Cancelar" variation="danger" onClick={() => setModal({ isOpen: false })} />
      <Button
        name="submit"
        label="Confirmar"
        variation="primary"
        onClick={() => http.delete(`${item.url}/${item.id}`).then(() => {
          item.setList((oldList) => {
            const newList = [...oldList]
            newList.splice(index, 1)
            return newList
          })
          setModal({ isOpen: false })
        }, (error) => {
          setModal({ isOpen: false })

          setModal({ isOpen: true, text: error.message })
        })} />
    </S.ConfirmModal>
  )
}