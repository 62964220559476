import React from 'react';

export default function RecipeIcon () {
  return (
    <svg width="98" height="118" viewBox="0 0 98 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M66.71 50.91C66.05 50.91 65.42 50.55 65.1 49.92L62.11 43.94C61.67 43.05 62.03 41.98 62.91 41.53C63.79 41.09 64.87 41.44 65.32 42.33L68.31 48.31C68.75 49.2 68.39 50.27 67.51 50.72C67.26 50.85 66.99 50.91 66.71 50.91Z" fill="#3BBC7B"/>
      <path d="M58.52 64.85C58.25 64.85 57.97 64.79 57.72 64.66C56.84 64.22 56.48 63.14 56.92 62.25L60.98 54.13C61.42 53.24 62.51 52.88 63.39 53.33C64.27 53.77 64.63 54.85 64.19 55.74L60.13 63.86C59.81 64.48 59.18 64.85 58.52 64.85Z" fill="#3BBC7B"/>
      <path d="M81.88 24.23C79.21 24.23 77.04 22.06 77.04 19.39C77.04 16.72 79.21 14.55 81.88 14.55C84.55 14.55 86.72 16.72 86.72 19.39C86.72 22.06 84.54 24.23 81.88 24.23ZM81.88 18.14C81.19 18.14 80.63 18.7 80.63 19.4C80.63 20.09 81.19 20.65 81.88 20.65C82.57 20.65 83.14 20.09 83.14 19.4C83.13 18.7 82.57 18.14 81.88 18.14Z" fill="#3BBC7B"/>
      <path d="M76.8 32.36C76.53 32.36 76.26 32.3 76 32.17C75.12 31.73 74.76 30.65 75.2 29.76L79.26 21.64C79.7 20.75 80.78 20.39 81.67 20.84C82.55 21.28 82.91 22.36 82.47 23.25L78.41 31.37C78.09 31.99 77.46 32.36 76.8 32.36Z" fill="#3BBC7B"/>
      <path d="M76.8 56.73H62.59C61.91 56.73 61.29 56.35 60.98 55.74L54.89 43.56C54.64 43.05 54.64 42.46 54.89 41.95L60.98 29.77C61.29 29.16 61.91 28.78 62.59 28.78H76.8C77.48 28.78 78.1 29.16 78.41 29.77L84.5 41.95C84.75 42.46 84.75 43.05 84.5 43.56L78.41 55.74C78.1 56.34 77.48 56.73 76.8 56.73ZM63.7 53.14H75.69L80.89 42.75L75.69 32.36H63.7L58.5 42.75L63.7 53.14Z" fill="#3BBC7B"/>
      <path d="M83.18 103.7H14.59C13.6 103.7 12.8 102.9 12.8 101.91C12.8 100.92 13.6 100.12 14.59 100.12H83.18C84.17 100.12 84.97 100.92 84.97 101.91C84.97 102.9 84.17 103.7 83.18 103.7Z" fill="#3BBC7B"/>
      <path d="M83.18 90.26H14.59C13.6 90.26 12.8 89.46 12.8 88.47C12.8 87.48 13.6 86.68 14.59 86.68H83.18C84.17 86.68 84.97 87.48 84.97 88.47C84.97 89.46 84.17 90.26 83.18 90.26Z" fill="#3BBC7B"/>
      <path d="M82.87 76.82H14.28C13.29 76.82 12.49 76.02 12.49 75.03C12.49 74.04 13.29 73.24 14.28 73.24H82.87C83.86 73.24 84.66 74.04 84.66 75.03C84.66 76.02 83.86 76.82 82.87 76.82Z" fill="#3BBC7B"/>
      <path d="M14.28 61.99C11.93 61.99 10.02 60.08 10.02 57.73C10.02 55.38 11.93 53.47 14.28 53.47C16.63 53.47 18.54 55.38 18.54 57.73C18.55 60.08 16.63 61.99 14.28 61.99ZM14.28 57.04C13.91 57.04 13.6 57.35 13.6 57.72C13.6 58.47 14.96 58.47 14.96 57.72C14.96 57.35 14.65 57.04 14.28 57.04Z" fill="#3BBC7B"/>
      <path d="M14.28 49.01C11.93 49.01 10.02 47.1 10.02 44.75C10.02 42.4 11.93 40.49 14.28 40.49C16.63 40.49 18.54 42.4 18.54 44.75C18.55 47.1 16.63 49.01 14.28 49.01ZM14.28 44.07C13.91 44.07 13.6 44.38 13.6 44.75C13.6 45.5 14.96 45.5 14.96 44.75C14.96 44.37 14.65 44.07 14.28 44.07Z" fill="#3BBC7B"/>
      <path d="M14.28 36.03C11.93 36.03 10.02 34.12 10.02 31.77C10.02 29.42 11.93 27.51 14.28 27.51C16.63 27.51 18.54 29.42 18.54 31.77C18.54 34.12 16.63 36.03 14.28 36.03ZM14.28 31.09C13.91 31.09 13.6 31.4 13.6 31.77C13.6 32.52 14.96 32.52 14.96 31.77C14.96 31.4 14.65 31.09 14.28 31.09Z" fill="#3BBC7B"/>
      <path d="M14.28 23.06C11.93 23.06 10.02 21.15 10.02 18.8C10.02 16.45 11.93 14.54 14.28 14.54C16.63 14.54 18.54 16.45 18.54 18.8C18.55 21.14 16.63 23.06 14.28 23.06ZM14.28 18.11C13.91 18.11 13.6 18.41 13.6 18.79C13.6 19.54 14.96 19.54 14.96 18.79C14.96 18.42 14.65 18.11 14.28 18.11Z" fill="#3BBC7B"/>
      <path d="M44.29 59.29H22.78C21.79 59.29 20.99 58.49 20.99 57.5C20.99 56.51 21.79 55.71 22.78 55.71H44.29C45.28 55.71 46.08 56.51 46.08 57.5C46.08 58.49 45.28 59.29 44.29 59.29Z" fill="#3BBC7B"/>
      <path d="M44.29 46.32H22.78C21.79 46.32 20.99 45.52 20.99 44.53C20.99 43.54 21.79 42.74 22.78 42.74H44.29C45.28 42.74 46.08 43.54 46.08 44.53C46.08 45.52 45.28 46.32 44.29 46.32Z" fill="#3BBC7B"/>
      <path d="M44.29 33.34H22.78C21.79 33.34 20.99 32.54 20.99 31.55C20.99 30.56 21.79 29.76 22.78 29.76H44.29C45.28 29.76 46.08 30.56 46.08 31.55C46.08 32.54 45.28 33.34 44.29 33.34Z" fill="#3BBC7B"/>
      <path d="M44.29 20.36H22.78C21.79 20.36 20.99 19.56 20.99 18.57C20.99 17.58 21.79 16.78 22.78 16.78H44.29C45.28 16.78 46.08 17.58 46.08 18.57C46.08 19.56 45.28 20.36 44.29 20.36Z" fill="#3BBC7B"/>
      <path d="M95.54 117.3H1.79C0.8 117.3 0 116.5 0 115.51V1.79C0 0.8 0.8 0 1.79 0H95.54C96.53 0 97.33 0.8 97.33 1.79V115.5C97.34 116.49 96.53 117.3 95.54 117.3ZM3.58 113.71H93.75V3.58H3.58V113.71Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="97.34" height="117.3" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
