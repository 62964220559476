import React from 'react';
import ItemPageHeader from './ItemPageHeader';
import SaveIcon from '../../images/Save';
import { useParams } from 'react-router-dom';
import ItemPageForm from './ItemPageForm';
import Button from '../Button';
import CancelIcon from '../../images/Delete';
import * as S from './ItemPage.style';
import { useHistory } from 'react-router-dom';
import Field from '../../Components/Field/FieldInput';

export default function ItemPage(ItemPageProps) {
  const { id } = useParams();
  const history = useHistory();
  const { pageLabel: { singular, newLabel, editLabel }, children, onSubmit, setError, error, editable, edit, setEdit, loading, disabledParent } = ItemPageProps;


  const disabled = Object.values(ItemPageProps.fields).some((field) => {
    if (field.validate) {
      const validation = field.type === 'select' ? field.validate(field.value?.value) : field.validate(field.value)

      return (validation.empty || (field.value && (validation.invalid || field.error)))
    }
  })

  return (
    <S.ItemPage>
      <ItemPageHeader {...ItemPageProps} />
      <S.ItemPageTitle>{id ? editLabel : newLabel} {singular}</S.ItemPageTitle>
      <ItemPageForm {...ItemPageProps} />
      {children}
      <S.ItemPageButtons>
        <Button
          variation="primary"
          label="Salvar"
          rightIcon={SaveIcon}
          onClick={() => { setError && setError(''); onSubmit() }}
          disabled={disabled || disabledParent}
          loading={loading}
        />
        <Button variation="danger" label="Voltar" rightIcon={CancelIcon} onClick={() => history.goBack()} />
        {editable && (
          <div style={{ justifySelf: 'end' }}>
            <Field type="switch" value={edit} label="Editar" onChange={() => setEdit(!edit)} />
          </div>
        )}
      </S.ItemPageButtons>
      {error && <S.ItemPageError>{error}</S.ItemPageError>}
    </S.ItemPage>
  );
}
