import { css } from 'styled-components';
import { validateCpfCnpj } from '../../utils/validateCpfCnpj';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'hidroponics',
  singular: `hidroponia`,
  plural: `hidroponias`,
  newLabel: 'Nova',
  editLabel: 'Minha'
};

export const farmFieldsState = () => ({
  fantasyName: { value: '' },
  razaoSocial: { value: '' },
  cpfCnpj: { value: '', mask: '999.999.999-99', disabled : '' },
  phoneNumber: { value: '', mask: '99 9999-9999' },
  mobileNumber: { value: '', mask: '99 99999-9999' },
  webSite: { value: '' },
  cep: { value: '' },
  logradouro: { value: '' },
  numeroResidencia: { value: '' },
  complemento: { value: '' },
  bairro: { value: '' },
  technicalResponsable: { value: '' },
});

export const farmFields = ({ fields, edit }) => ({
  fantasyName:  { 
    ...fields.fantasyName,
    name: 'fantasyName',
    label: 'Nome fantasia',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  razaoSocial:  { 
    ...fields.razaoSocial,
    name: 'razaoSocial',
    label: 'Razão Social',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  cpfCnpj:  { 
    ...fields.cpfCnpj,
    name: 'cpfCnpj',
    label: 'CNPJ / CPF',
    placeholder: 'Digite',
    mask: fields.cpfCnpj.mask,
    
    onChange: ({ target, setFields }) => {
      const { name, value } = target;
      setFields((oldFields) => {
        oldFields[name].error = false;
        oldFields[name].errorMessage = '';
        oldFields[name].value = value;
        oldFields[name].mask = value.length > 14 ? '99.999.999/9999-99' : '999.999.999-999';
        
        return ({ ...oldFields });
      });
    },
    validate: (value) => ({
      empty: !value,
      invalid: !validateCpfCnpj(value),
      invalidMessage: 'Por favor coloque um documento válido',
    }),
    // disabled: !edit
    
  },
  phoneNumber:  { 
    ...fields.phoneNumber,
    name: 'phoneNumber',
    label: 'Telefone comercial',
    mask: '(99) 9999-99999',
    placeholder: 'Digite',
    validate: (value) => ({
      empty: !value,
      invalid: (value?.length < 14),
      invalidMessage: 'Por favor coloque um telefone válido'
    }),
    disabled: !edit
  },
  mobileNumber:  { 
    ...fields.mobileNumber,
    name: 'mobileNumber',
    label: 'Telefone celular',
    placeholder: 'Digite',
    mask: '(99) 99999-9999',
    // validate: (value) => ({
    //   invalid: (value?.length !== 15 && value?.length !== 1),
    //   invalidMessage: 'Por favor coloque um telefone celular válido'
    // }),
    disabled: !edit
  },
  webSite:  { 
    ...fields.webSite,
    name: 'webSite',
    label: 'Site',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  cep:  { 
    ...fields.cep,
    name: 'cep',
    label: 'CEP',
    placeholder: 'Digite',
    mask: '99999-999',
    validate: (value) => ({
      empty: !value,
      invalid: !(value.length === 9),
      invalidMessage: 'Por favor coloque um CEP válido'
    }),
    disabled: !edit
  },
  logradouro:  { 
    ...fields.logradouro,
    name: 'logradouro',
    label: 'Endereço',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  numeroResidencia:  { 
    ...fields.numeroResidencia,
    name: 'numeroResidencia',
    label: 'Número',
    placeholder: 'Digite',
    type: 'number',
    //validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  complemento:  { 
    ...fields.complemento,
    name: 'complemento',
    label: 'Complemento',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  bairro:  { 
    ...fields.bairro,
    name: 'bairro',
    label: 'Bairro',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
  technicalResponsable:  { 
    ...fields.technicalResponsable,
    name: 'technicalResponsable',
    label: 'Responsável Técnico',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
    disabled: !edit
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "fantasyName"
    "razaoSocial"
    "cpfCnpj"
    "webSite"
    "cep"
    "logradouro"
    "numeroResidencia"
    "complemento"
    "bairro" 
    "technicalResponsable"
    "phoneNumber"
    "mobileNumber"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      grid-template: ${`
        "fantasyName fantasyName fantasyName fantasyName fantasyName fantasyName"
        "razaoSocial razaoSocial razaoSocial razaoSocial razaoSocial razaoSocial"
        "cpfCnpj cpfCnpj cpfCnpj webSite webSite webSite"
        "logradouro logradouro logradouro logradouro numeroResidencia numeroResidencia"
        "complemento complemento bairro bairro cep cep"
        "technicalResponsable technicalResponsable technicalResponsable technicalResponsable technicalResponsable technicalResponsable"
        "phoneNumber phoneNumber phoneNumber mobileNumber mobileNumber mobileNumber"
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
