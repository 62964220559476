import React from "react";

export default function NutritiveSolution() {
  return (
    <svg width="87" height="121" viewBox="0 0 87 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M39.43 120.33C24.15 120.33 11.88 116.86 11.11 116.64C10.43 116.45 9.92997 115.87 9.82997 115.18L0.0199732 47.97C-0.120027 46.99 0.559973 46.08 1.53997 45.94C2.51997 45.81 3.42997 46.47 3.56997 47.46L13.21 113.5C18.49 114.83 41.68 119.95 62.11 113.59L70.03 47.77C70.15 46.79 71.03 46.1 72.03 46.2C73.01 46.32 73.71 47.21 73.6 48.19L65.54 115.15C65.46 115.84 64.98 116.42 64.33 116.64C56.01 119.4 47.32 120.33 39.43 120.33Z" fill="#3BBC7B"/>
      <path d="M36.8199 58.86C18.5399 58.86 0.039917 55.12 0.039917 47.98C0.039917 40.83 18.5399 37.1 36.8199 37.1C55.0999 37.1 73.5999 40.84 73.5999 47.98C73.6099 55.12 55.0999 58.86 36.8199 58.86ZM36.8199 40.68C15.3399 40.68 3.61992 45.5 3.61992 47.98C3.61992 50.46 15.3399 55.27 36.8199 55.27C58.2999 55.27 70.0199 50.45 70.0199 47.98C70.0199 45.5 58.2999 40.68 36.8199 40.68Z" fill="#3BBC7B"/>
      <path d="M37.85 74.58C18.89 74.58 5.00003 69.83 4.14003 69.53C3.21003 69.2 2.71003 68.18 3.04003 67.24C3.37003 66.3 4.40003 65.81 5.32003 66.14C5.63003 66.25 36.74 76.85 68.97 66.13C69.92 65.83 70.93 66.33 71.24 67.27C71.55 68.21 71.04 69.23 70.1 69.54C58.74 73.32 47.62 74.58 37.85 74.58Z" fill="#3BBC7B"/>
      <path d="M38.5 107.16C37.51 107.16 36.71 106.36 36.71 105.37V30.8C36.71 29.81 37.51 29.01 38.5 29.01C39.49 29.01 40.29 29.81 40.29 30.8V105.37C40.3 106.36 39.49 107.16 38.5 107.16Z" fill="#3BBC7B"/>
      <path d="M38.6 34.54C38.22 34.54 37.84 34.42 37.53 34.18C37.13 33.89 36.88 33.44 36.82 32.95C36.65 31.44 36.6 29.85 36.66 28.23C36.81 24.32 37.65 20.84 39.14 17.89C40.64 14.92 42.73 12.47 45.33 10.6C47.82 8.82 50.44 7.42 53.12 6.43C55.75 5.46 58.82 4.69 62.24 4.14L83.13 0.0300008C84.06 -0.149999 84.99 0.430001 85.22 1.37C86.85 8.08 86.07 13.98 82.91 18.89C79.36 24.41 72.9 28.32 64.72 29.9L38.91 34.52C38.81 34.53 38.71 34.54 38.6 34.54ZM82.09 3.89L62.87 7.67C59.62 8.19 56.78 8.9 54.36 9.79C51.98 10.67 49.65 11.92 47.41 13.52C45.28 15.04 43.57 17.06 42.33 19.51C41.07 21.99 40.37 24.97 40.24 28.37C40.21 29.14 40.21 29.9 40.24 30.64L64.07 26.38C71.25 25 76.88 21.65 79.89 16.96C82.25 13.3 82.98 8.91 82.09 3.89Z" fill="#3BBC7B"/>
      <path d="M22.9799 92.06C22.1199 92.06 21.3599 91.44 21.2099 90.56C21.0499 89.58 21.7099 88.66 22.6799 88.5C31.0899 87.1 37.1799 81.82 37.2399 81.77C37.9799 81.12 39.1199 81.19 39.7699 81.93C40.4299 82.67 40.3599 83.8 39.6099 84.46C39.3299 84.71 32.7299 90.46 23.2699 92.04C23.1699 92.05 23.0699 92.06 22.9799 92.06Z" fill="#3BBC7B"/>
      <path d="M55.69 99.62C52.92 99.62 41.35 99.13 36.93 91.13C36.45 90.26 36.77 89.17 37.63 88.69C38.5 88.22 39.59 88.53 40.07 89.39C43.94 96.4 56.02 96 56.13 96.02C57.13 96.03 57.95 96.76 57.99 97.75C58.03 98.74 57.26 99.57 56.27 99.61C56.21 99.61 56.01 99.62 55.69 99.62Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="86.05" height="120.33" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
