import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';
import http from '../../service';
import * as moment from 'moment';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'shippings',
  singular: 'Expedição',
  plural: 'Expedições'
};

export const shippingsSearchFieldsState = () => ({
  query: { value: '' },
  de: { value: '' },
  ate: { value: ''},
  hortalica: { value: ''},
  nota: { value: ''},
  lote: { value: ''}
});


export const shippingsSearchFields = ({ fields,setList, setModal }) => ({
  de: { 
    ...fields.de,
    name: 'de',
    label: 'De',
    type: 'date',
    placeholder: 'Digite',
  },
  ate: { 
    ...fields.ate,
    name: 'ate',
    label: 'Até',
    type: 'date',
    placeholder: 'Digite',
  },
  query: { 
    ...fields.query,
    name: 'query',
    label: 'Cliente',
    placeholder: 'Selecione',
    type: 'select',
    loadEmpty: true,
    isSearchable: false,
    loadOptions: (_query, callback) => {
      http.get('customers')
        .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    }, 
    isClearable: true,
  },
  hortalica: { 
    ...fields.hortalica,
    name: 'hortalica',
    label: 'Hortaliça',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    loadOptions: (query, callback, setFields) => {
      http.get('greeneries')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
          .catch((err) => callback([]))
    }
  },
  nota: {
    ...fields.nota,
    name: 'nota',
    label: 'Número da nota',
    placeholder: 'Digite',
  },
  lote: {
    ...fields.lote,
    name: 'lote',
    label: 'Lote',
    placeholder: 'Digite',
  },
  submitButton: { 
    name: 'submitButton',
    label: 'Pesquisar',
    type: 'button',
    variation: 'primary',
    rightIcon: SearchIcon,
    onClick: () => {
      const params = {
        startDate: fields.de.value, 
        endDate: fields.ate.value, 
        customerId: fields.query.value?.value, 
        greeneryId: fields.hortalica.value?.value, 
        invoiceNumber: fields.nota.value, 
        lotId: fields.lote.value 
      }

      if (fields.de.value === '') delete params.startDate;
      if (fields.ate.value === '') delete params.endDate;
      if(!fields.query.value?.value) delete params.customerId;
      if(!fields.hortalica.value?.value) delete params.greeneryId;
      if(fields.nota.value === '') delete params.invoiceNumber;
      if(fields.lote.value === '') delete params.lotId;

      http.get('expedition', { params })
        .then(({ data }) => {
          let obj = [];
          data.data.forEach( el => {
            obj.push({
              customerName: el.customer.name,
              invoiceNumber: el.invoiceNumber,
              lotId: el.lotId,
              quantity: el.quantity,
              id: el.id,
              expeditionDate: moment(el.expeditionDate).format('DD-MM-YYYY'),
              greeneryName: el.lot.greenery.name
            })
          })
          setList(obj.filter((item) => item))
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))   
    }
  },
});

export const cardInfos = [
  { fieldLabel: 'Lote', fieldType: 'lotId' },
  { fieldLabel: 'Hortaliça', fieldType: 'greeneryName' },
  { fieldLabel: 'Cliente', fieldType: 'customerName' },
  { fieldLabel: 'Número da Nota', fieldType: 'invoiceNumber'},
  { fieldLabel: 'Data da expedição', fieldType: 'expeditionDate'},
  { fieldLabel: 'Quantidade', fieldType: 'quantity'},
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
  { fieldLabel: '', fieldType: 'qrCode'}
]

export const searchGridTemplate = css`
  grid-template: ${`
    "de"
    "ate"
    "query"
    "hortalica"
    "nota"
    "lote"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD }) {
      grid-template:
        "de ate query"
        "hortalica nota lote"
        "submitButton . ."
      ;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template:
    "customerName invoiceNumber greeneryName lotId expeditionDate quantity qrCode history delete"
  ;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 40px 40px 40px  ;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "customerName invoiceNumber greeneryName lotId expeditionDate quantity qrCode history delete"
      ;
      grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 40px 40px 40px;
    }
  }
`
