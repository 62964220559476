import React from 'react';
import { inputChange, inputValidate } from '../../helpers/fieldFunctions';
import * as S from './Input.style';
import { Range } from 'rc-slider';
import "rc-slider/assets/index.css";

export default function InputText({ onChange, setFields, type, i, parentI, validate, onBlur, ...props }) {
  const { name, min, max, marks, toFixed, suffix } = props;

  const marksRange = {};
  for (let i = min; i.toFixed(toFixed) <= max; i += marks) {
    marksRange[i] = `${i.toFixed(toFixed)}${suffix || ''}`
  }

  return (
    <S.InputRange
      {...props}
      marks={marksRange}
      onChange={(value) => inputChange({ target: { i, parentI, name: props.name, value }, setFields, onChange })}
      onBlur={(value) => inputValidate({ target: { i, parentI, name: props.name, value }, setFields, validate, onBlur })}
    />
  );
}
