import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './User.constants';
import * as S from './User.style';
import { useParams, useHistory } from 'react-router-dom';

export default function User ({ setModal }) {
  const { id } = useParams();
  const { userFieldsState, userFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(userFieldsState);
  const itemFields = userFields({fields, id});
  const history = useHistory();

  useEffect(() => {
    const currentUser = JSON.parse(window.localStorage.getItem('currentUser'));

    if(id) {
      http.get(`users/${id}`)
        .then(({ data }) => setFields({
          name: { value: data.data.name },
          email: { value: data.data.username },
          password: { value: '' },
          rePassword: { value: '' },
          farm: { value: data.data.organization.razaoSocial || data.data.organization.fantasyName },
          roles: { value: { label: data.data.roles === 'TECNICO' ? 'TÉCNICO' : data.data.roles, value: data.data.roles } },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    } else {
      setFields({
        name: { value: '' },
        email: { value: '' },
        password: { value: '' },
        rePassword: { value: '' },
        farm: { value: currentUser.fantasyName },
        roles: { value: '' },
      })
    }
  }, [id])

  const onSubmit = () => {
    setLoading(true);
    http[id ? 'patch' : 'post'](`users${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      username: fields.email.value,
      password: fields.password.value || null,
      roles: fields.roles.value.value,
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))  
      .finally(() => setLoading(false))
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.User>
      <ItemPage {...itemPageObject} />
    </S.User>
  );
};
