import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'greenery',
  singular: `hortaliça`,
  plural: `hortaliças`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const greeneryFieldsState = () => ({
  name: { value: '' },
  initials: { value: '' },
  boxUnits: { value: '' },
  planned: { value: '' },
  maternityCycle: { value: '' },
  vivariumCycle: { value: '' },
  nurseryCycle: { value: '' },
  finalCycle: { value: '' },
});

export const greeneryFields = ({ fields }) => ({
  name: { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
  },
  initials: { 
    ...fields.initials,
    name: 'initials',
    label: 'Sigla',
    placeholder: 'Digite',
    validate: (value) => ({ 
      empty: !value, 
      invalid: value.length !== 4, 
      invalidMessage: 'A Sigla deve possuir 4 caracteres.' }),
  },
  boxUnits: { 
    ...fields.boxUnits,
    name: 'boxUnits',
    label: 'Unidades por caixa',
    placeholder: 'Digite',
    type: 'number',
    validate: (value) => ({ 
      empty: !value,
      invalid: value > 999,
      invalidMessage: 'A Quantidade não pode ser maior que 999'
    }),
  },
  planned: { 
    ...fields.planned,
    name: 'planned',
    label: 'Planejado',
    placeholder: 'Digite',
    type: 'number',
    validate: (value) => ({ empty: !value }),
  },
  cycleCaption: {
    name: 'cycleCaption',
    label: 'Defina o período de cada ciclo para essa hortaliça',
    type: 'caption',
  },
  maternityCycle: { 
    ...fields.maternityCycle,
    name: 'maternityCycle',
    label: 'Maternidade',
    placeholder: '0',
    type: 'bigNumber',
    unity: 'dias',
    color: '#16c4c4',
    validate: (value) => ({ empty: !value }),
  },
  vivariumCycle: { 
    ...fields.vivariumCycle,
    name: 'vivariumCycle',
    label: 'Viveiro',
    placeholder: '0',
    type: 'bigNumber',
    unity: 'dias',
    color: '#13739e',
    validate: (value) => ({ empty: !value }),
  },
  nurseryCycle: { 
    ...fields.nurseryCycle,
    name: 'nurseryCycle',
    label: 'Berçário',
    placeholder: '0',
    type: 'bigNumber',
    unity: 'dias',
    color: '#1e7c4d',
    validate: (value) => ({ empty: !value }),
  },
  finalCycle: { 
    ...fields.finalCycle,
    name: 'finalCycle',
    label: 'Ciclo final',
    placeholder: '0',
    type: 'bigNumber',
    unity: 'dias',
    color: '#8bc53f',
    validate: (value) => ({ empty: !value }),
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "initials" 
    "boxUnits" 
    "planned" 
    "cycleCaption"
    "maternityCycle" 
    "vivariumCycle" 
    "nurseryCycle" 
    "finalCycle" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name name name initials" 
        "boxUnits boxUnits planned planned" 
        "cycleCaption cycleCaption cycleCaption cycleCaption"
        "maternityCycle vivariumCycle nurseryCycle finalCycle" 
      `};
    }
  }
`
