import React from 'react';

export default function HistoryIcon () {
  return (
    <svg width="25" height="24" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 30C21.7614 30 24 27.7614 24 25C24 22.2386 21.7614 20 19 20C16.2386 20 14 22.2386 14 25C14 27.7614 16.2386 30 19 30Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 16.6L20 3.4C20 2.76348 19.7498 2.15303 19.3044 1.70294C18.859 1.25286 18.2549 1 17.625 1L3.375 1C2.74511 1 2.14102 1.25286 1.69562 1.70295C1.25022 2.15303 0.999998 2.76348 0.999998 3.4L1 22.6C1 23.2365 1.25022 23.847 1.69562 24.2971C2.14102 24.7471 2.74511 25 3.375 25L10.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 7L6 7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 13L6 13M12 18.5L6 18.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19 22V25L21 26" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
