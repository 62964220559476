import React, { useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './NewActivity.constants';
import * as S from './NewActivity.style';
import { useHistory } from 'react-router-dom';

export default function NewActivity ({setModal}) {
  const { newActivityFieldsState, newActivityFields } = constants;
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(newActivityFieldsState);
  const itemFields = newActivityFields({fields});
  const history = useHistory();

  const onSubmit = () => {
    const data = {
      title: fields.title.value,
      plannedDate: fields.plannedDate.value,
      frequency: Number(fields.frequency.value),
      description: fields.description.value,
      occurrences: Number(fields.occurrency.value),
      endDate: fields.endDate.value,
      type: 'Tarefas',
    }
    http.post(`tasks`, data)
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })  
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, item, fields: itemFields,
    setLoading, setItem, setFields, onSubmit
  }

  return (
    <S.NewActivity>
      <ItemPage {...itemPageObject} />
    </S.NewActivity>
  );
};
