import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import UsersIcon from '../../images/UsersIcon';
import * as S from './Users.style';
import * as constants from './Users.constants';
import ConfirmModal from '../../Components/ConfirmModal';

export default function Users({ setModal }) {
  const { usersSearchFieldsState, usersSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(usersSearchFieldsState);
  const searchFields = usersSearchFields({ fields });
  const url = 'users'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: UsersIcon, setList, setFields,
  }

  useEffect(() => {
    const query = (searchFields.query.value ? searchFields.query.value : '');

    http.get(url)
      .then(({ data }) => {
        data.data.map( el => {
          el.roles = el.roles === 'TECNICO' ? 'TÉCNICO' : el.roles;
        })

        setList(data.data.filter((item) => 
          (item.name ? item.name : '').toLowerCase().includes(query.toLowerCase())
        ))
        
      })
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Users>
      <ListPage {...listPageObject} />
    </S.Users>
  );
};
