import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'greenhouse',
  singular: `estufa`,
  plural: `estufas`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const newSector = (fields, setFields, i) => ({
  sectorId: {
    name: 'sectorId',
    label: `Setor ${i + 1}`,
    type: 'id'
  },
  sectorLabel: {
    name: 'sectorLabel',
    label: `Setor ${i + 1}`,
    type: 'caption',
  },
  sectorName: {
    value: `Setor ${i + 1}`,
    name: 'sectorName',
    label: 'Nome do setor',
    placeholder: 'Digite',
    onChange: ({ target }) => setFields((oldFields) => {
      const { name, value, i } = target;
      const sectorsValue = [...oldFields.sectors.value]
      sectorsValue[i][name].value = value
      sectorsValue[i][name].error = false
      sectorsValue[i][name].errorMessage = ''
      sectorsValue[i].sectorLabel.label = value
      return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({ target, setFields, validate }) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if (validation.empty) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
        });
      } else if (value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  waterTank: {
    value: '',
    name: 'waterTank',
    label: `Caixa d'agua`,
    placeholder: 'Digite e selecione',
    type: 'select',
    loadEmpty: true,
    isSearchable: false,
    loadOptions: (query, callback) => {
      http.get('watertank')
        .then(({ data }) => callback(data.data.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch(({ error }) => callback([]))
    },
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => {
      const sectorsValue = [...oldFields.sectors.value]
      sectorsValue[i][name].value = value
      sectorsValue[i][name].error = false
      sectorsValue[i][name].errorMessage = ''
      return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({ target, setFields, validate }) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if (validation.empty) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
        });
      } else if (value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  removeButton: {
    name: 'removeButton',
    label: '-',
    type: 'button',
    variation: 'danger',
    onClick: (e, i) => {
      setFields((oldFields) => {
        const sectorsValue = [...oldFields.sectors.value]
        sectorsValue.splice(i, 1)
        return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
      })
    }
  },
  standsLabel: {
    name: 'standsLabel',
    label: 'Bancas',
    type: 'caption',
  },
  addStandButton: {
    name: 'addStandButton',
    label: '+',
    type: 'button',
    variation: 'primary',
    onClick: (e, i) => setFields((oldFields) => {
      const newFields = { ...oldFields };
      newFields.sectors.value[i].stands.value = [...oldFields.sectors.value[i].stands.value, newStand(fields, setFields, oldFields.sectors.value[i].stands.value.length)]
      return newFields
    })
  },
  stands: {
    value: [newStand(fields, setFields, 0)],
    name: 'stands',
    label: 'Setores',
    type: 'subFields',
    gridTemplate: css`
      grid-template-columns: "1fr 1fr 65px";
      grid-template: ${`
        "standLabel" 
        "standName" 
        "standHoles" 
        "removeButton"
      `};
      
      @media screen {
        @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
          grid-template: ${`
            "standLabel standLabel removeButton"
            "standName standHoles standHoles"
          `};
          grid-template-columns: 1fr 65px 65px;
        }
      };
    `,
    validate: (value) => ({
      empty: value.some((fields) => {
        return Object.values(fields).some((field) => {
          return field.validate && field.validate(field.value).empty;
        })
      })
    })
  },
});

export const newStand = (fields, setFields, i) => ({
  standId: {
    name: 'standId',
    label: `Banca ${i + 1}`,
    type: 'id'
  },
  standLabel: {
    name: 'standLabel',
    label: `Banca ${i + 1}`,
    type: 'caption',
  },
  standName: {
    value: `Banca ${i + 1}`,
    name: 'standName',
    label: 'Nome da banca',
    placeholder: 'Digite',
    onChange: ({ target: { value, name, i, parentI } }) => setFields((oldFields) => {
      const newFields = { ...oldFields };
      newFields.sectors.value[parentI].stands.value[i][name].value = value
      newFields.sectors.value[parentI].stands.value[i][name].error = false
      newFields.sectors.value[parentI].stands.value[i][name].errorMessage = ''
      newFields.sectors.value[parentI].stands.value[i].standLabel.label = value
      return newFields
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({ target, setFields, validate }) => {
      const { name, value, i, parentI } = target;
      const validation = validate(value);
      if (validation.empty) {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.sectors.value[parentI].stands.value[i][name].error = true;
          newFields.sectors.value[parentI].stands.value[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return newFields
        });
      } else if (value && validation.invalid) {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.sectors.value[parentI].stands.value[i][name].error = true;
          newFields.sectors.value[parentI].stands.value[i][name].errorMessage = validation.invalidMessage;
          return newFields;
        });
      }
    }
  },
  standHoles: {
    value: '',
    name: 'standHoles',
    label: 'Furos',
    placeholder: 'digite',
    mask: '999999',
    onChange: ({ target: { value, name, i, parentI } }) => setFields((oldFields) => {
      const newFields = { ...oldFields };
      newFields.sectors.value[parentI].stands.value[i][name].value = value
      newFields.sectors.value[parentI].stands.value[i][name].error = false
      newFields.sectors.value[parentI].stands.value[i][name].errorMessage = ''
      return newFields
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({ target, setFields, validate }) => {
      const { name, value, i, parentI } = target;
      const validation = validate(value);
      if (validation.empty) {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.sectors.value[parentI].stands.value[i][name].error = true;
          newFields.sectors.value[parentI].stands.value[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return newFields
        });
      } else if (value && validation.invalid) {
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.sectors.value[parentI].stands.value[i][name].error = true;
          newFields.sectors.value[parentI].stands.value[i][name].errorMessage = validation.invalidMessage;
          return newFields;
        });
      }
    }
  },
  removeButton: {
    name: 'removeButton',
    label: '-',
    type: 'button',
    variation: 'danger',
    onClick: (e, i, parentI) => {
      setFields((oldFields) => {
        const newFields = { ...oldFields };
        newFields.sectors.value[parentI].stands.value.splice(i, 1)
        return newFields
      })
    }
  },
});

export const greenhouseFieldsState = () => ({
  name: { value: '' },
  sectors: { value: [] },
});

export const greenhouseFields = ({ fields, setFields }) => ({
  name: {
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
  },
  sectorsLabel: {
    name: 'sectorsLabel',
    label: 'Setores',
    type: 'caption',
  },
  addSectorsButton: {
    name: 'addSectorsButton',
    label: '+',
    type: 'button',
    variation: 'primary',
    onClick: () => setFields((oldFields) => {
      const sectorsValue = [...oldFields.sectors.value, newSector(fields, setFields, oldFields.sectors.value.length)]
      return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
    })
  },
  sectors: {
    ...fields.sectors,
    name: 'sectors',
    label: 'Setores',
    type: 'subFields',
    gridTemplate: css`
      grid-template-columns: "1fr 1fr 65px";
      grid-template: ${`
        "sectorLabel" 
        "sectorName" 
        "waterTank" 
        "removeButton"
        "standsLabel"
        "standsLabel"
        "addStandButton"
        "stands"
      `};
      
      @media screen {
        @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
          grid-template: ${`
            "sectorLabel sectorLabel sectorLabel removeButton"
            "sectorName sectorName waterTank waterTank"
            "standsLabel standsLabel standsLabel standsLabel"
            "stands stands stands stands" 
            "addStandButton . . ." 
          `};
          grid-template-columns: 65px 1fr 1fr 65px;
        }
      };
    `,
    validate: (value) => ({
      empty: value.some((fields) => {
        return Object.values(fields).some((field) => {
          return field.validate && field.validate(field.value).empty;
        })
      })
    })
  }
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "sectorsLabel"
    "addSectorsButton"
    "sectors" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template: ${`
        "name name . . " 
        "sectorsLabel sectorsLabel sectorsLabel sectorsLabel" 
        "sectors sectors sectors sectors" 
        "addSectorsButton . . ." 
      `};
      grid-template-columns: 65px 1fr 1fr 1fr;
    }
  }
`
