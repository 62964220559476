import React, { useState, useEffect } from "react";
import { addDays } from 'date-fns'
import { useHistory } from 'react-router-dom';
import http from '../../../service'
import DashboardTitle from "../DashboardTitle";
import DashboardTabs from "../DashboardTabs";
import DashboardNextHarvestTab from "./DashboardNextHarvestTab";
import Button from "../../Button";
import * as S from "./DashboardNextHarvest.style";

const colors = ['#1e7c4d', '#13739e', '#00a1a5', '#8bc53f', '#1e7c4d', '#13739e', '#00a1a5', '#8bc53f', '#1e7c4d', '#13739e', '#00a1a5', '#8bc53f', '#1e7c4d', '#13739e', '#00a1a5', '#8bc53f']

const DashboardNextActivities = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [tabs, setTabs] = useState([
    { label: '7 dias', value: 7, data: [] },
    { label: '15 dias', value: 15, data: [] },
    { label: '30 dias', value: 30, data: [] },
  ])

  const history = useHistory();

  useEffect(() => {
    const params7Days = {
      startDate: new Date().toDateString(),
      endDate: addDays(new Date(), 7).toDateString(),
    }
    const params15Days = {
      startDate: new Date().toDateString(),
      endDate: addDays(new Date(), 15).toDateString(),
    }
    const params30Days = {
      startDate: new Date().toDateString(),
      endDate: addDays(new Date(), 30).toDateString(),
    }
    const params = {
      isHarvestReport: true,
    }
    http.get('lot', { params: { ...params, ...params7Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];
        const tabData = data.data
          .reduce((obj, item) => {
            if (obj.hasOwnProperty(item.greenery.name)) {
              obj[item.greenery.name].value = obj[item.greenery.name].value + item.actualQuantity
            } else { obj[item.greenery.name] = { label: item.greenery.name, value: item.actualQuantity } }
            return obj
          }, {})
        const newTabsArr = Object.values(tabData).map((item, index) => ({ ...item, color: colors[index] }))

        newTabsArr.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        newTabs[0].data = newTabsArr
        return newTabs
      }))
    http.get('lot', { params: { ...params, ...params15Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];
        const tabData = data.data
          .reduce((obj, item) => {
            if (obj.hasOwnProperty(item.greenery.name)) {
              obj[item.greenery.name].value = obj[item.greenery.name].value + item.actualQuantity
            } else { obj[item.greenery.name] = { label: item.greenery.name, value: item.actualQuantity } }
            return obj
          }, {})
        const newTabsArr = Object.values(tabData).map((item, index) => ({ ...item, color: colors[index] }))

        newTabsArr.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        newTabs[1].data = newTabsArr
        return newTabs
      }))
    http.get('lot', { params: { ...params, ...params30Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];
        const tabData = data.data
          .reduce((obj, item) => {
            if (obj.hasOwnProperty(item.greenery.name)) {
              obj[item.greenery.name].value = obj[item.greenery.name].value + item.actualQuantity
            } else {
              obj[item.greenery.name] = { label: item.greenery.name, value: item.actualQuantity }
            }
            return obj
          }, {})
        const newTabsArr = Object.values(tabData).map((item, index) => ({ ...item, color: colors[index] }))

        newTabsArr.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        newTabs[2].data = newTabsArr
        return newTabs
      }))
  }, [])

  return (
    <S.DashboardNextHarvest>
      <DashboardTitle>
        Próximas colheitas
      </DashboardTitle>
      <DashboardTabs
        tabs={tabs}
        component={DashboardNextHarvestTab}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        color="#8bc53f"
      />
      <Button label=". . . ver mais" onClick={() => history.push('/relatorios/previsao-de-colheitas/' + (activeTab === 0 ? '7' : (activeTab === 1 ? '15' : '30')))} />
    </S.DashboardNextHarvest>
  );
};

export default DashboardNextActivities;
