import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';
import NotebookIcon from '../../images/NotebookIcon';
import { subDays, addDays } from 'date-fns';
import http from '../../service'

export const type = 'list'
export const pageColor = '#419645'
export const pageLabel = {
  name: 'campNotebook',
  singular: 'caderno de campo',
  plural: 'cadernos de campo',
  icon: NotebookIcon
};

export const campNotebookSearchFieldsState = () => ({
  greenhouse: { value: '' },
  startDate: { value: undefined },
  endDate: { value: addDays(new Date(), 30) },
  status: { value: '' },
});

export const campNotebookSearchFields = ({ fields, setList, setModal }) => ({
  greenhouse: {
    ...fields.greenhouse,
    name: 'greenhouse',
    label: 'Estufa',
    placeholder: 'Digite',
    loadEmpty: true,
    isSearchable: true,
  },
  startDate: {
    ...fields.startDate,
    name: 'startDate',
    label: 'De',
    type: 'date',
  },
  endDate: {
    ...fields.endDate,
    name: 'endDate',
    label: 'Até',
    type: 'date',
  },
  status: {
    ...fields.status,
    name: 'status',
    label: 'Status',
    placeholder: 'Ativos',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    options: [
      { value: 'Todos', label: 'Todos' },
      { value: 'Ativo', label: 'Ativos' },
      { value: 'Concluído', label: 'Concluídos' },
    ]
  },
  submitButton: {
    name: 'submitButton',
    label: 'Pesquisar',
    type: 'button',
    variation: 'primary',
    rightIcon: SearchIcon,
    onClick: () => {
      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        status: fields.status.value?.value,
      }
      http.get('fieldnote', { params })
        .then(({ data }) => setList(data.data.filter((item) => item.greenhouse.name.toLowerCase().includes(fields.greenhouse.value.toLowerCase()))))
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  },
});

export const cardInfos = [
  { fieldLabel: 'Data', fieldType: 'measuredAt' },
  { fieldLabel: 'Estufa', fieldType: 'greenHouse' },
  { fieldLabel: 'Status', fieldType: 'statusText' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
  "greenhouse"
  "startDate"
  "endDate"
  "status"
  "submitButton"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } } }) => screenXS}) {
      grid-template: ${`
        "greenhouse startDate endDate"
        "status submitButton ."
      `};
      grid-template-columns: 2fr 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template:
    "statusText statusText statusText statusText statusText statusText"
    "greenHouse greenHouse measuredAt measuredAt measuredAt measuredAt"
    "history history history delete delete delete"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  @media screen {

  
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template:
        "statusText greenHouse measuredAt history delete"
      ;
      grid-template-columns: 120px 1fr 1fr 40px 40px;
    }
  }
`
