import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'recipe',
  singular: `receita`,
  plural: `receitas`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const newCompositions = (fields, setFields, i) => ({
  input : {
    value: '',
    name: 'input',
    label: 'Insumo',
    placeholder: 'Digite',
    type: 'select',
    isSearchable: false,
    loadEmpty: true,
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i][name].error = false
      compositionsValue[i][name].errorMessage = ''
      const gracePeriod = compositionsValue.reduce((biggest, comp) => comp.input.value.carencia > biggest ? comp.input.value.carencia : biggest, 0)
      return { 
        ...oldFields, 
        compositions: { ...oldFields.compositions, value: compositionsValue },
        caption: { label: gracePeriod ? `*Esta receita possui carência de ${gracePeriod} dias` : ''}
      } 
    }),
    loadOptions: (query, callback) => {
      http.get(`input/?inputGroup1=DEFENSIVOS&inputGroup2=NUTRIENTES${query ? `&search=${query}` : ''}`)
        .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    }, 
    validate: (value) => ({ empty: !value }),
    onBlur: ({target, setFields, validate}) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if(validation.empty) {
        setFields((oldFields) => {
          const compositionsValue = [...oldFields.compositions.value]
          compositionsValue[i][name].error = true;
          compositionsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
        });
      } else if(value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  amount: {
    value: '',
    name: 'amount',
    label: 'Quantidade',
    placeholder: 'Digite',
    type: 'number',
    suffix: ' g',
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i][name].error = false
      compositionsValue[i][name].errorMessage = ''
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({target, setFields, validate}) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if(validation.empty) {
        setFields((oldFields) => {
          const compositionsValue = [...oldFields.compositions.value]
          compositionsValue[i][name].error = true;
          compositionsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
        });
      } else if(value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  unit: {
    value: {label: 'gramas', value: ' g'},
    name: 'unit',
    label: 'Unidade',
    placeholder: 'Selecione',
    type: 'select',
    isSearchable: false,
    loadEmpty: true,
    options: [
      {label: 'gramas', value: ' g'},
      {label: 'miliLitros', value: ' ml'}
    ],
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i].amount.suffix = value.value
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    })
  },
  removeButton: {
    name: 'removeButton',
    label: '-',
    type: 'button',
    variation: 'danger',
    onClick: (e, i) => { setFields((oldFields) => { 
      const compositionsValue = [ ...oldFields.compositions.value ]
      compositionsValue.splice(i, 1)
      const gracePeriod = compositionsValue.reduce((biggest, comp) => comp.input.value.carencia > biggest ? comp.input.value.carencia : biggest, 0)
      return { 
        ...oldFields,
        compositions: { ...oldFields.compositions, value: compositionsValue },
        caption: { label: gracePeriod ? `*Esta receita possui carência de ${gracePeriod} dias` : ''}
      } 
    })}
  },
});

export const recipeFieldsState = () => ({
  name: { value: '' },
  description: { value: '' },
  compositions: { value: [] },
  caption: { label: '' }
});

export const recipeFields = ({ fields, setFields }) => {
  const newFields = ({
    name: { 
      name: 'name',
      label: 'Nome',
      placeholder: 'Digite',
      validate: (value) => ({
        empty: !value
      }), 
      ...fields.name,
    },
    description: { 
      name: 'description',
      label: 'Observação',
      placeholder: 'Digite',
      ...fields.description,
    },
    compositionsCaption: {
      name: 'compositionsCaption',
      label: 'Composição',
      type: 'caption',
    },
    addCompositionsButton: {
      name: 'addCompositionsButton',
      label: '+',
      type: 'button',
      variation: 'primary',
      onClick: () => setFields((oldFields) => { 
        const compositionsValue = [...oldFields.compositions.value, newCompositions(fields, setFields, oldFields.compositions.value.length)]
        return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
      })
    },
    compositions: { 
      name: 'compositions',
      label: 'composição',
      placeholder: 'Digite',
      type: 'subFields',
      gridTemplate: css`
        grid-template-columns: "1fr 1fr 65px";
        grid-template: ${`
          "input" 
          "amount" 
          "unit" 
          "removeButton"
          `};
        
        @media screen {
          @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
            grid-template: ${`
              ". . . . . . . removeButton"
              "input input input input amount amount unit unit"
              `};
            grid-template-columns: 1fr 1fr 1fr 1fr 80px 80px 100px 65px;
          }
        };
      `,
      validate: (value) => ({
        empty: value.some((fields) => {
          return Object.values(fields).some((field) => {
            return field.validate && field.validate(field.value).empty;
          })
        })
      }),
    ...fields.compositions,
    },
  })

  if (!!fields.compositions.value.length) {
    newFields.caption = {
      ...fields.caption,
      name: 'caption',
      type: 'caption'
    }
  }
  return newFields;
}

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "description" 
    "compositionsCaption"
    "compositions" 
    "addCompositionsButton"
    "caption"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name name description description description description description description" 
        "compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption"
        "compositions compositions compositions compositions compositions compositions compositions compositions" 
        "addCompositionsButton . . . . . . ." 
        "caption caption caption caption caption caption caption caption" 
      `};
      grid-template-columns: 65px calc(25% - 105px) repeat(6, calc(12.5% - 12px));
    }
  }
`
