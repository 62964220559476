import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import ClientsIcon from '../../images/ManufacturerIcon';
import * as S from './Clients.style';
import * as constants from './Clients.constants';
import ConfirmModal from '../../Components/ConfirmModal';

export default function Clients ({setModal}) {
  const {clientsSearchFieldsState, clientsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(clientsSearchFieldsState);
  const searchFields = clientsSearchFields({fields});
  const url = 'customers'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: ClientsIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url)
      .then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Clients>
      <ListPage {...listPageObject} />
    </S.Clients>
  );
};
