import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'group',
  singular: `grupo`,
  plural: `grupos`,
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const groupFieldsState = () => ({
  name: { value: '' },
});

export const groupFields = ({ fields }) => ({
  name: { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
  }
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name" 
      `};
      grid-template-columns: 1fr;
    }
  }
`
