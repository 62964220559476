import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Client.constants';
import * as S from './Client.style';
import { useParams, useHistory } from 'react-router-dom';

export default function Client ({ setModal }) {
  const { id } = useParams();
  const { clientFieldsState, clientFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(clientFieldsState);
  const itemFields = clientFields({fields, id});
  const history = useHistory();

  useEffect(() => {
    const currentUser = JSON.parse(window.localStorage.getItem('currentUser'));

    if(id) {
      http.get(`customers/${id}`)
        .then(({ data }) => setFields({
          name: { value: data.data.name },
          fantasyName: { value: data.data.fantasyName },
          razaoSocial: { value: data.data.razaoSocial },
          cpfCnpj: { value: data.data.cpfCnpj, mask: data.data.cpfCnpj.length > 14 ? '99.999.999/9999-99' : '999.999.999-999' },
          commercialAccount: { value: data.data.commercialAccount },
          phoneNumber: { value: data.data.phoneNumber, mask: '99 9999-9999' },
          cep: { value: data.data.cep },
          logradouro: { value: data.data.logradouro },
          numeroResidencia: { value: data.data.numeroResidencia },
          complemento: { value: data.data.complemento },
          bairro: { value: data.data.bairro },
          icon: { value: data.data.icon, type: 'input', readOnly: true },
          google_maps_cod: { value: data.data.google_maps_cod },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }, [id])

  const onSubmit = () => {
    setLoading(true);
    http[id ? 'put' : 'post'](`customers${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      fantasyName: fields.fantasyName.value,
      razaoSocial: fields.razaoSocial.value,
      cpfCnpj: fields.cpfCnpj.value,
      commercialAccount: fields.commercialAccount.value,
      phoneNumber: fields.phoneNumber.value,
      cep: fields.cep.value,
      logradouro: fields.logradouro.value,
      numeroResidencia: fields.numeroResidencia.value,
      complemento: fields.complemento.value,
      bairro: fields.bairro.value,
      //icon: fields.icon.value,
      //google_maps_cod: fields.google_maps_cod.value,
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setError(`Houve um problema para ${id ? 'editar' : 'salvar'}, por favor tente novamente`))
      .finally(() => setLoading(false))
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Client>
      <ItemPage {...itemPageObject} />
    </S.Client>
  );
};
