import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Pest.constants';
import * as S from './Pest.style';
import { useParams, useHistory } from 'react-router-dom';

export default function Pest ({ setModal }) {
  const { id } = useParams();
  const { pestFieldsState, pestFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(pestFieldsState);
  const itemFields = pestFields({fields});
  const history = useHistory();

  useEffect(() => {
    if(id) {
      http.get(`bugs/${id}`)
        .then(({ data }) => setFields({
          commonName: { value: data.data.nomeVulgar },
          scientificName: { value: data.data.nomeCientifico },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`bugs${id ? `/${id}` : ''}`, {
      nomeVulgar: fields.commonName.value,
      nomeCientifico: fields.scientificName.value,
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })  
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Pest>
      <ItemPage {...itemPageObject} />
    </S.Pest>
  );
};
