import React, { useEffect, useState } from "react";
import http from '../../../service'
import FeaturedNumber from "../../FeaturedNumber";
import * as S from './DashboardLosts.style';
import Button from "../../Button";

const DashboardLosts = ({ setModal }) => {
  const [totalLost, setTotalLost] = useState('-')

  useEffect(() => {
    http.get(`lot/stagesTotal`)
      .then(({ data }) => setTotalLost((data.data.totalLost.percentual ? data.data.totalLost.percentual : 0).toFixed(2)))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])
  return (
    <S.DashboardLosts>
      <FeaturedNumber label="Percentual de perda final" value={`${totalLost}%`} />
    </S.DashboardLosts>
  );
};

export default DashboardLosts;
