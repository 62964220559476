import axios from 'axios'
import { logout } from './auth';

const transformRequest = (data, headers) => {
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  if (currentUser) { headers['Authorization'] = `Bearer ${currentUser.accessToken}` };
  headers.post['Content-Type'] = 'application/json';
  return JSON.stringify(data)
}

const transformResponse = (data) => {
  if(data) {
    const parsedData = JSON.parse(data);
    const { statusCode, message } = parsedData
    if (statusCode === 401) { 
      logout();
      throw new Error(message);
    }
    if ((statusCode < 200 || statusCode >= 300) && statusCode !== 404) {
      throw new Error(message);
    }
    return parsedData
  }
  return
}

const configuredAxios = axios.create({
  baseURL: 'http://localhost:3001',
  // baseURL: 'https://dev-api-sohl.fabrica.dev.br/',
  // baseURL: 'http://Sohl-env-1.eba-47mzaqg9.us-east-1.elasticbeanstalk.com',
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
  transformRequest: [transformRequest],
  transformResponse: [transformResponse]
})

export default configuredAxios