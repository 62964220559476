import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Farm.constants';
import * as S from './Farm.style';
import { useHistory } from 'react-router-dom';

export default function Farm({ setCurrentUser, setModal }) {
  const { farmFieldsState, farmFields } = constants;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(farmFieldsState);
  const itemFields = farmFields({ fields, edit });
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const history = useHistory();

  useEffect(() => {
    if (currentUser.organization) {
      http.get(`organization/${currentUser.organization}`)
        .then(({ data: { data } }) => {
          setFields({
            name: { value: data.name },
            phoneNumber: { value: data.phoneNumber },
            mobileNumber: { value: data.mobileNumber },
            fantasyName: { value: data.fantasyName },
            razaoSocial: { value: data.razaoSocial },
            cpfCnpj: { value: data.cpfCnpj, disabled: true },
            technicalResponsable: { value: data.technicalResponsable },
            celNumber: { value: data.celNumber },
            webSite: { value: data.webSite },
            cep: { value: data.cep },
            logradouro: { value: data.logradouro },
            numeroResidencia: { value: data.numeroResidencia },
            complemento: { value: data.complemento },
            bairro: { value: data.bairro },
          })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    } else {
      setEdit(true)
    }
  }, [])

  const onSubmit = () => {
    setLoading(true);
    http[!currentUser.organization ? 'post' : 'patch'](`organization/${currentUser.organization || ''}`, {
      phoneNumber: fields.phoneNumber.value,
      mobileNumber: (fields.mobileNumber.value === '' ? null : fields.mobileNumber.value),
      name: fields.fantasyName.value,
      fantasyName: fields.fantasyName.value,
      razaoSocial: fields.razaoSocial.value,
      cpfCnpj: fields.cpfCnpj.value,
      technicalResponsable: fields.technicalResponsable.value,
      webSite: fields.webSite.value,
      cep: fields.cep.value,
      logradouro: fields.logradouro.value,
      numeroResidencia: fields.numeroResidencia.value,
      complemento: fields.complemento.value,
      bairro: fields.bairro.value,
    })
      .then(({ data }) => {
        setEdit(false)
        localStorage.setItem('currentUser', JSON.stringify({ ...currentUser, organization: data.data?.id }));
        setCurrentUser && setCurrentUser({ ...currentUser, organization: data.data?.id })
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!' })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))  
      .finally(() => setLoading(false))
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields, editable: currentUser.role === "GESTOR", edit, setEdit,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Farm>
      <ItemPage {...itemPageObject} />
    </S.Farm>
  );
};
