import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'shipping',
  singular: `Expedição`,
  plural: `Expedições`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const shippingFieldsState = () => ({
  customer: { value: '' },
  docNumber: { value: '' },
  hortalica: { value: '' },
  lot: { value: '' },
  shippingDate: { value: '' },
  quantity: { value: '' }
});

export const shippingFields = ({ fields, id }) => ({
  customer: {
    ...fields.customer,
    name: 'customer',
    label: 'Cliente',
    placeholder: 'Selecione',
    type: 'select',
    loadEmpty: true,
    isSearchable: false,
    loadOptions: (_query, callback) => {
      http.get('customers')
        .then(({ data }) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    },
    validate: (value) => ({ empty: !value }),
    isClearable: true,
  },
  docNumber: {
    ...fields.docNumber,
    name: 'docNumber',
    label: 'Número da nota',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
  },
  hortalica: {
    ...fields.hortalica,
    name: 'hortalica',
    label: 'Hortaliça',
    placeholder: 'Selecione',
    type: 'select',
    loadEmpty: true,
    isSearchable: false,
    loadOptions: (_query, callback, setFields) => {
      http.get('greeneries/findallharvested')
        .then(({ data }) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    },
    onChange: async ({ target: { value, name, i }, setFields }) => {
      const options = await http
        .get(`lot/findallharvested/${value?.id}`)
        .then(({ data }) => data?.data?.map((option) => ({ ...option, label: option.id, value: option.id })))
      setFields((oldFields) => {
        const newFields = { ...oldFields };
        newFields[name].value = value
        newFields[name].error = false
        newFields[name].errorMessage = ''
        newFields.lot.value = '';
        newFields.lot.options = options;
        return newFields
      })

    },
    validate: (value) => ({ empty: !value }),
  },

  lot: {
    ...fields.lot,
    name: 'lot',
    label: 'Lote',
    placeholder: 'Selecione',
    type: 'select',
    loadEmpty: true,
    isSearchable: false,
    validate: (value) => ({ empty: !value }),
    isClearable: true,
  },
  shippingDate: {
    ...fields.shippingDate,
    name: 'shippingDate',
    label: 'Data de Expedição',
    type: 'date',
    validate: (value) => ({ empty: !value }),
  },
  quantity: {
    ...fields.quantity,
    name: 'quantity',
    label: 'Quantidade',
    placeholder: 'Digite',
    type: 'number',
    validate: (value) => ({
      empty: !value,
      invalid: (value > fields.lot.value.expeditionAvailableQuantity) || (value < 1),
      invalidMessage: 'Quantidade maior que o lote.'
    })
  }

});

export const gridTemplate = css`
  grid-template: ${`
    "customer" 
    "docNumber" 
    "hortalica"
    "lot"
    "shippingDate"
    "quantity" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template: ${`
        "customer docNumber hortalica"
        "lot shippingDate quantity" 
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
