import axios from 'axios'

const transformRequest = (data, headers) => {
  headers.post['Content-Type'] = 'application/json';
  return JSON.stringify(data)
}

const transformResponse = (data) => {


  if(data) {
    const parsedData = JSON.parse(data);
    const { statusCode, message } = parsedData

    if ((statusCode < 200 || statusCode >= 300) && statusCode !== 404) {
      throw new Error(message);
    }

    return parsedData
  }
  return
}

const configuredAxios = axios.create({
  baseURL: 'https://viacep.com.br/',
  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
  transformRequest: [transformRequest],
  transformResponse: [transformResponse]
})

export default configuredAxios