import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './NutritiveSolution.constants';
import * as S from './NutritiveSolution.style';
import { useHistory, useParams } from 'react-router-dom';

export default function NutritiveSolution ({ setModal }) {
  const { id } = useParams();
  const { nutritiveSolutionFieldsState, nutritiveSolutionFields, newCompositions, newECRecomendation, newPHRecomendation } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(nutritiveSolutionFieldsState);
  const itemFields = nutritiveSolutionFields({fields, setFields});
  const history = useHistory();
  
  useEffect(() => {
    const newComposition = newCompositions(fields, setFields, 0)
    if(id) {
      const newEcRecomendation = newECRecomendation(fields, setFields)
      const newPhRecomendation = newPHRecomendation(fields, setFields)

      http.get(`nutritive-solution/${id}`)
        .then(({ data }) => setFields((oldFields) => {
          const newFields = { ...oldFields }
          newFields.name.value = data.data.name
          newFields.description.value = data.data.description
          newFields.ec.value = [data.data.ecMin, data.data.ecMax]
          newFields.ph.value = [data.data.phMin, data.data.phMax]
          newFields.ecCaption.label = `Intervalo de EC ideal - ${data.data.ecMin} a ${data.data.ecMax}`
          newFields.phCaption.label = `Intervalo de pH ideal - ${data.data.phMin} a ${data.data.phMax}`
          newFields.temperatureCaption.label = `Intervalo de temperatura ideal - ${data.data.tempMin} a ${data.data.tempMax}`
          newFields.temperature.value = [data.data.tempMin, data.data.tempMax]
          newFields.compositions.value = data.data.nutritiveSolutionComposition.map((item) => ({
            ...newComposition, 
            input:  { ...newComposition.input, value: { label: item.input.name, value: item.inputId }},
            amount:  { ...newComposition.amount, value: item.quantity },
            unit:  { ...newComposition.unit, value: newComposition.unit.options.find((option) => option.label === item.measureUnity)},
            compositionsLabel: { ...newComposition.compositionsLabel, label: `${item.input.name} ${item.quantity ? `- ${item.quantity} ${item.measureUnity}` : ''}` }
          }))
          newFields.ecRecomendations.value = data.data.nutritiveSolutionRecommendation
            .filter((item) => item.type === 'EC')
            .map((item) => ({
              ...newEcRecomendation, 
              range:  { ...newEcRecomendation.range, value: [item.measureMin, item.measureMax]},
              recomendation:  { ...newEcRecomendation.recomendation, value: item.recommendation},
              recomendationsLabel: {...newEcRecomendation.recomendationsLabel, label: `EC ${parseFloat(item.measureMin).toFixed(1)} a ${parseFloat(item.measureMax).toFixed(1)} - ${item.recommendation ? item.recommendation : ``} `  }
            }))
          newFields.phRecomendations.value = data.data.nutritiveSolutionRecommendation
            .filter((item) => item.type === 'pH')
            .map((item) => ({
              ...newPhRecomendation, 
              range:  { ...newPhRecomendation.range, value: [item.measureMin, item.measureMax]},
              recomendation:  { ...newPhRecomendation.recomendation, value: item.recommendation },
              recomendationsLabel: {...newPhRecomendation.recomendationsLabel, label: `pH ${item.measureMin} a ${item.measureMax}- ${item.recommendation ? item.recommendation : ``} `  },
            }))
          return newFields
      }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    } else {
      setFields((oldFields) => { 
        const compositionsValue = [...oldFields.compositions.value, newComposition]
        return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
      })
    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'put' : 'post'](`nutritive-solution${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      description: fields.description.value,
      ecMin: Number(fields.ec.value[0]),
      ecMax: Number(fields.ec.value[1]),
      phMin: Number(fields.ph.value[0]),
      phMax: Number(fields.ph.value[1]),
      tempMin: Number(fields.temperature.value[0]),
      tempMax: Number(fields.temperature.value[1]),
      nutritiveSolutionComposition: fields.compositions.value
        .map((comp) => ({ 
          inputId: comp.input.value.value,
          quantity: Number(comp.amount.value),
          measureUnity: comp.unit.value.label,
        })),
      nutritiveSolutionRecommendation: [
        ...fields.ecRecomendations.value.map((rec) => ({
          type: 'EC',
          recommendation: rec.recomendation.value,
          measureMin: Number(rec.range.value[0]),
          measureMax: Number(rec.range.value[1]),
        })),
        ...fields.phRecomendations.value.map((rec) => ({
          type: 'pH',
          recommendation: rec.recomendation.value,
          measureMin: Number(rec.range.value[0]),
          measureMax: Number(rec.range.value[1]),
        }))
      ]
        
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })  
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.NutritiveSolution>
      <ItemPage {...itemPageObject} />
    </S.NutritiveSolution>
  );
};
