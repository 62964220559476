import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Input.constants';
import * as S from './Input.style';
import { useParams, useHistory } from 'react-router-dom';

export default function Input ({ setModal }) {
  const { id } = useParams();
  const { inputFieldsState, inputFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(inputFieldsState);
  const itemFields = inputFields({fields});
  const history = useHistory();

  useEffect(() => {
    if(id) {
      http.get(`input/${id}`)
        .then((response) => {
          const data = response.data.data
          setFields({
            name: { value: data.name },
            group: { value: { label: data.type, value: data.type } },
            gracePeriod: { value: data.carencia },
            greenery: { value: { label: data.greenery?.name, value: data.greenery?.id } },
            manufacturer: { value: { label: data.manufacturer.name, value: data.manufacturer.id } },
          })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`input${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      type: fields.group.value.value,
      carencia: +fields.gracePeriod.value,
      greeneryId: fields.greenery.value.value,
      manufacturerName: fields.manufacturer.value.label,
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })      
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Input>
      <ItemPage {...itemPageObject} />
    </S.Input>
  );
};
