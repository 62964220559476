import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'user',
  singular: `usuario`,
  plural: `usuarios`,
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const userFieldsState = () => ({
  name: { value: '' },
  email: { value: '' },
  password: { value: '' },
  rePassword: { value: '' },
  farm: { value: '' },
  roles: { value: '' },
});

export const userFields = ({ fields, id }) => ({
  name: { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
  },
  email: { 
    ...fields.email,
    name: 'email',
    label: 'E-mail',
    placeholder: 'Digite',
    validate: (value) => ({
      empty: !value,
      invalid: !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value),
      invalidMessage: 'Por favor coloque um email válido'
    }),
    disabled: !!id
  },
  password: { 
    ...fields.password,
    name: 'password',
    label: 'Senha',
    placeholder: id ? '••••••••' : 'Digite',
    validate: (value) => ({
      empty: !value && !id,
      invalid: !/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(fields.password.value),
      invalidMessage: 'Por favor, coloque uma senha mais forte'
    }),
    type: 'password'
  },
  rePassword: { 
    ...fields.rePassword,
    name: 'rePassword',
    label: 'Confirme a senha',
    placeholder: 'Digite',
    validate: (value) => ({
      empty: !value && !id,
      invalid: value !== fields.password.value,
      invalidMessage: 'As senhas precisam ser iguais'
    }),
    type: 'password'
  },
  farm: { 
    ...fields.farm,
    name: 'farm',
    label: 'Fazenda',
    disabled: true,
  },
  roles: { 
    ...fields.roles,
    name: 'roles',
    label: 'Permissões',
    placeholder: 'Selecione',
    options: [
      { label: 'GESTOR', value: 'GESTOR' }, 
      { label: 'OPERADOR', value: 'OPERADOR' }, 
      { label: 'TÉCNICO', value: 'TECNICO' }, 
    ],
    type: 'select',
    validate: (value) => ({ empty: !value }),
    isSearchable: false,
    loadEmpty: true,
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "email" 
    "password" 
    "rePassword" 
    "farm" 
    "roles" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name email" 
        "password rePassword" 
        "farm roles" 
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
