import React from 'react';
import * as S from './Modal.style';

export default function Modal({ modal: { title, text, width, height, transform, isOpen, callBack, component: ChildComponent }, setModal }) {

  const closeModal = () => {
    setModal({ isOpen: false })
    callBack && callBack();
  };

  if (!width) {
    width = '320px';
  }

  if (!height) {
    height = '200px';
  }

  if (!transform) {
    transform = '50vh';
  }

  return isOpen
    ? (
      <S.Modal onClick={closeModal}>
        <S.ModalContainer width={width} height={height} transform={transform} onClick={(e) => e.stopPropagation()}>
          <S.ModalClose onClick={closeModal}>+</S.ModalClose>
          {ChildComponent
            ? <ChildComponent />
            : (
              <>
                <S.ModalTitle>{title}</S.ModalTitle>
                <S.ModalText>{text}</S.ModalText>
                <S.ModalButton variation="primary" label={`OK`} onClick={closeModal} />
              </>
            )}
        </S.ModalContainer>
      </S.Modal>
    ) : <></>
}