import React from 'react';
import DashboardWeather from '../../Components/Dashboard/DashboardWeather';
import DashboardIndicators from '../../Components/Dashboard/DashboardIndicators';
import DashboardNextActivities from '../../Components/Dashboard/DashboardNextActivities';
import DashboardNextHarvest from '../../Components/Dashboard/DashboardNextHarvest';
import DashboardOccupation from '../../Components/Dashboard/DashboardOccupation';
import * as Styled from './Dashboard.style';

const Dashboard = ({setModal}) => {

  return (
    <Styled.Dashboard>
      <DashboardWeather setModal={setModal}/>
      <DashboardIndicators setModal={setModal}/>
      <DashboardNextActivities setModal={setModal}/>
      <DashboardNextHarvest setModal={setModal}/>
      <DashboardOccupation setModal={setModal}/>
    </Styled.Dashboard>
  );
};

export default Dashboard;
