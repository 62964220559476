import React, { useEffect, useCallback, useState } from 'react';
import { format, compareAsc } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import TrashIcon from '../../../images/TrashIcon';
import HistoryIcon from '../../../images/HistoryIcon';
import QrCodeIcon from '../../../images/QrCodeIcon';
import CheckBoxIcon from '../../../images/CheckBoxIcon';
import CheckBoxNoCheckedIcon from '../../../images/CheckBoxNoCheckedIcon';
import TagIcon from '../../../images/TagsIcon';
import PostponeModal from '../../PostponeModal';
import ExecuteModal from '../../ExecuteModal';
import ConfirmModal from '../../ConfirmModal';
import * as S from './ListPageCards.style';
import { useHistory, useRouteMatch } from "react-router-dom";
import ConfirmQuantidadeQrCode from '../../ConfirmQuantidadeQrCode';
import http from '../../../service';
import * as moment from 'moment'
import InputSwitch from '../../Input/InputSwitch'
import Field from '../../../Components/Field'



export default function ListPageCard({ fieldType, fieldLabel, removeItem, index, gridTemplate, setModal, ...listPageCardInfoProps }) {
  const router = useRouteMatch();
  const history = useHistory();


  const [edit, setSelectedFields] = useState(listPageCardInfoProps.selected)

  switch (fieldType) {
    case 'select':
      return (
        <S.ListPageCardFieldIcon tooltip="Selecione" onClick={
          () => {
            listPageCardInfoProps.setSelected(index, !edit);
            setSelectedFields(!edit);
          }
        } name={fieldType}>

          {edit && (
            <CheckBoxIcon />
          )}
          {!edit && (
            <CheckBoxNoCheckedIcon />
          )}


        </S.ListPageCardFieldIcon>
      )


    case 'qrCode':
      return (
        <S.ListPageCardFieldIcon tooltip="QR Code" onClick={
          () => {
            setModal({ isOpen: true, component: () => <ConfirmQuantidadeQrCode setModal={setModal} item={listPageCardInfoProps} index={index} /> })
          }
        } name={fieldType}><QrCodeIcon />
        </S.ListPageCardFieldIcon>
      )
    case 'tags':
      return <S.ListPageCardFieldIcon tooltip="Etiqueta" name={fieldType}><TagIcon /></S.ListPageCardFieldIcon>
    case 'history':
      return <S.ListPageCardFieldIcon tooltip={listPageCardInfoProps.url === 'lot' ? "Histórico" : "Editar"} onClick={() => history.push(`${router.url}/${listPageCardInfoProps.id}`)} name={fieldType}><HistoryIcon /></S.ListPageCardFieldIcon>
    case 'delete':
      return (
        <S.ListPageCardFieldIcon tooltip="Excluir"
          disabled={listPageCardInfoProps.actualStage === 'COLHIDO' || listPageCardInfoProps.status === 'Concluído'}
          name={fieldType}
          onClick={() => setModal({ isOpen: true, component: () => <ConfirmModal setModal={setModal} item={listPageCardInfoProps} index={index} /> })}>
          <TrashIcon />
        </S.ListPageCardFieldIcon>
      )
    case 'createdAt':
    case 'plantDate':
    case 'haverstPlanDate':
    case 'haverstDate':
    case 'plannedDate':
    case 'endDate':
    case 'measuredAt':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {!listPageCardInfoProps[fieldType] ? '' : format(new Date(`${listPageCardInfoProps[fieldType].length !== 10 ? listPageCardInfoProps[fieldType] : `${listPageCardInfoProps[fieldType]} 12:00:00`}`), "dd/MM/yyyy")}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'temperature':
      let temperatureColor = 'brand';
      if (listPageCardInfoProps[fieldType] < listPageCardInfoProps.temperatureMinStd || listPageCardInfoProps[fieldType] > listPageCardInfoProps.temperatureMaxStd) {
        temperatureColor = 'danger';
      }
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValueWarning color={temperatureColor}>{listPageCardInfoProps[fieldType]}</S.ListPageCardFieldValueWarning>
        </S.ListPageCardField>
      )
    case 'ph':
      let phColor = 'brand';
      if (listPageCardInfoProps[fieldType] < listPageCardInfoProps.phMinStd || listPageCardInfoProps[fieldType] > listPageCardInfoProps.phMaxStd) {
        phColor = 'danger';
      }
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValueWarning color={phColor}>{listPageCardInfoProps[fieldType]}</S.ListPageCardFieldValueWarning>
          {listPageCardInfoProps.correctedPh && (
            <>
              <S.ListPageCardFieldLabel>{fieldLabel} corrigido:</S.ListPageCardFieldLabel>
              <S.ListPageCardFieldValueWarning color={'warning'}>{listPageCardInfoProps.correctedPh}</S.ListPageCardFieldValueWarning>
            </>
          )}
        </S.ListPageCardField>
      )
    case 'ec':
      let ecColor = 'brand';
      if (listPageCardInfoProps[fieldType] < listPageCardInfoProps.ecMinStd || listPageCardInfoProps[fieldType] > listPageCardInfoProps.ecMaxStd) {
        ecColor = 'danger';
      }
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValueWarning color={ecColor}>{listPageCardInfoProps[fieldType]}</S.ListPageCardFieldValueWarning>
          {listPageCardInfoProps.corretedEc && (
            <>
              <S.ListPageCardFieldLabel>{fieldLabel} corrigido:</S.ListPageCardFieldLabel>
              <S.ListPageCardFieldValueWarning color={'warning'}>{listPageCardInfoProps.corretedEc}</S.ListPageCardFieldValueWarning>
            </>
          )}
        </S.ListPageCardField>
      )
    case 'sectorsAmount':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.sectors?.length}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'standsAmount':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.sectors?.reduce((total, sec) => total + sec.stand.length, 0)}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'date':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {listPageCardInfoProps.dataPlantio || listPageCardInfoProps.date}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'harvestForecast':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {listPageCardInfoProps.previsaoColheita}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'farm':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {listPageCardInfoProps.organizacao?.nomeFantasia}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'watertank':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {listPageCardInfoProps.watertank?.name}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'plan':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>
            {listPageCardInfoProps.plano?.nome}
          </S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'id':
      return (
        <S.ListPageCardFieldID name={fieldType}>
          <S.ListPageCardFieldIDLabel>{fieldLabel}:</S.ListPageCardFieldIDLabel>
          <S.ListPageCardFieldIDValue>{listPageCardInfoProps.sigla}</S.ListPageCardFieldIDValue>
        </S.ListPageCardFieldID>
      )
    case 'recorrence':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.recorrencia} dias</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'statusText':
      return (
        <S.ListPageCardFieldStatusText name={fieldType} status={listPageCardInfoProps.status}>
          <S.ListPageCardFieldStatusLabel>{fieldLabel}:</S.ListPageCardFieldStatusLabel>
          <S.ListPageCardFieldStatusValue>{listPageCardInfoProps.status}</S.ListPageCardFieldStatusValue>
        </S.ListPageCardFieldStatusText>
      )
    case 'status':
      return (
        <S.ListPageCardFieldStatus name={fieldType} isActive={listPageCardInfoProps.isActive}>
          <S.ListPageCardFieldStatusLabel>{fieldLabel}:</S.ListPageCardFieldStatusLabel>
          <S.ListPageCardFieldStatusValue>{listPageCardInfoProps.isActive ? 'Ativo' : 'Inativo'}</S.ListPageCardFieldStatusValue>
        </S.ListPageCardFieldStatus>
      )
    case 'payment':
      return (
        <S.ListPageCardField name={fieldType} isActive={listPageCardInfoProps.isActive}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.pagamentoRegularizado ? 'Em dia' : 'Atrasado'}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'reportActivityType':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps[fieldType]}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'action':
      let variation = 'primary';
      if (listPageCardInfoProps.status === 'corrigir') { variation = 'danger' }
      if (listPageCardInfoProps.corretedEc) { variation = 'warning' }
      return (
        <S.ListPageCardField name={fieldType} >
          {fieldLabel && <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>}
          <S.ListPageCardFieldActionValue variation={variation} disabled={listPageCardInfoProps.corretedEc} onClick={() => history.push(`${router.url}/${listPageCardInfoProps.id}`)}>
            {listPageCardInfoProps.corretedEc ? 'corrigido' : listPageCardInfoProps.status}
          </S.ListPageCardFieldActionValue>
        </S.ListPageCardField>
      )
    case 'activityType':
      let activityActionVariation = 'primary';
      let isActivityPage = (listPageCardInfoProps.activityType === 'Transferência' || listPageCardInfoProps.activityType === 'Colheita')
      if (compareAsc(new Date(), new Date(listPageCardInfoProps.plannedDate)) === 1) { activityActionVariation = 'danger' }
      if (listPageCardInfoProps.activityType === 'Colheita' && (moment(listPageCardInfoProps.lots[0].lackDate) > moment())) { activityActionVariation = 'warning' }
      return listPageCardInfoProps.status !== 'DONE' ? (
        <S.ListPageCardField name={fieldType} >
          {fieldLabel && <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>}
          <S.ListPageCardFieldActionValue
            variation={activityActionVariation}
            disabled={listPageCardInfoProps.status === 'DONE' || listPageCardInfoProps.activityType === 'Colheita' && (moment(listPageCardInfoProps.lots[0].lackDate) > moment())}
            onClick={() => isActivityPage
              ? history.push(`${router.url}/${listPageCardInfoProps.id}`)
              : setModal({ isOpen: true, component: () => <ExecuteModal setModal={setModal} item={listPageCardInfoProps} /> })}
          >
            {listPageCardInfoProps.activityType === 'Colheita' && (moment(listPageCardInfoProps.lots[0].lackDate) > moment()) ? 'Carência até ' + moment(listPageCardInfoProps.lots[0].lackDate).format('DD-MM-YYYY') : listPageCardInfoProps.activityType}
          </S.ListPageCardFieldActionValue>
        </S.ListPageCardField>
      ) : <></>;
    case 'activityPostpone':
      return listPageCardInfoProps.status !== 'DONE' ? (
        <S.ListPageCardField name={fieldType} >
          {fieldLabel && <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>}
          <S.ListPageCardFieldActionValue variation={'warning'} onClick={() => setModal({ isOpen: true, component: () => <PostponeModal setModal={setModal} item={listPageCardInfoProps} /> })}>
            Adiar
          </S.ListPageCardFieldActionValue>
        </S.ListPageCardField>
      ) : <></>;
    case 'lot':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.id?.substring(0, 5)}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'greenery':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.greenery?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'boxes':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{Math.ceil((listPageCardInfoProps.actualQuantity / listPageCardInfoProps.greenery.qttPerBox))}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'actualStage':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue className={listPageCardInfoProps.actualStage === 'COLHIDO' ? 'primary' : null}>{listPageCardInfoProps.actualStage}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'greenery':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.greenery?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'greenhouse':
    case 'greenHouse':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.greenhouse?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'sector':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.sector?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'stand':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps.stand?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'name':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps?.name}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'fantasyName':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps?.nomeFantasia}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'price':
      return (
        <S.ListPageCardField name={fieldType}>
          <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
          <S.ListPageCardFieldValue>{listPageCardInfoProps?.valor}</S.ListPageCardFieldValue>
        </S.ListPageCardField>
      )
    case 'stages':
    default: return (
      <S.ListPageCardField name={fieldType}>
        <S.ListPageCardFieldLabel>{fieldLabel}:</S.ListPageCardFieldLabel>
        <S.ListPageCardFieldValue>{listPageCardInfoProps[fieldType]}</S.ListPageCardFieldValue>
      </S.ListPageCardField>
    )
  }
}
