import { css } from 'styled-components';
import http from '../../service'
import { subDays, addDays } from 'date-fns';
import SearchIcon from '../../images/SearchIcon';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'plantingLots',
  singular: 'lote de plantio',
  plural: 'lotes de plantio'
};

export const plantingLotsSearchFieldsState = () => ({
  query: { value: '' },
  startDate: { value: addDays(new Date(), -30) },
  endDate: { value: addDays(new Date(), 30) },
  stage: { value: '' },
  greenhouseId: { value: '' },
});

export const plantingLotsSearchFields = ({ fields, setList, setModal, list }) => ({
  query: {
    ...fields.query,
    name: 'query',
    label: 'Hortaliça',
    placeholder: 'Escreva',
  },
  startDate: {
    ...fields.startDate,
    name: 'startDate',
    label: 'De',
    type: 'date',
  },
  endDate: {
    ...fields.endDate,
    name: 'endDate',
    label: 'Até',
    type: 'date',
  },
  stage: {
    ...fields.stage,
    name: 'stage',
    label: 'Estágio',
    placeholder: 'Todos',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    options: [
      { value: 'MATERNIDADE', label: 'Maternidade' },
      { value: 'VIVEIRO', label: 'Viveiro' },
      { value: 'BERÇARIO', label: 'Berçário' },
      { value: 'PERFIL FINAL', label: 'Perfil Final' },
      { value: 'COLHIDO', label: 'Colhido' },
    ]
  },
  greenhouseId: {
    ...fields.greenhouseId,
    name: 'greenhouseId',
    label: 'Estufa',
    placeholder: 'Todos',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    loadOptions: (query, callback, setFields) => {
      http.get('greenhouse')
        .then(({ data }) => {
          callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id })))
          setFields((oldFields) => {
            const newFields = { ...oldFields };
            newFields.greenhouseId.options = data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))
            return newFields
          })
        })
        .catch((err) => callback([]))
    },
  },
  submitButton: {
    name: 'submitButton',
    label: 'Pesquisar',
    type: 'button',
    variation: 'primary',
    rightIcon: SearchIcon,
    onClick: () => {

      if (!fields.stage?.value?.value || fields.stage?.value?.value === 'ALL' || fields.stage?.value?.value === 'COLHIDO') {
        if (!fields.startDate.value || !fields.endDate.value) {
          setModal({ isOpen: true, text: "Para pesquisar 'Todos' ou 'Colhido', é necessário informar datas de início e fim (máximo de 60 dias)!" });

          return;
        }

        else if (parseInt((fields.endDate.value - fields.startDate.value) / 1000 / 24 / 60 / 60) > 60) {
          setModal({ isOpen: true, text: "Para pesquisar 'Todos' ou 'Colhido', é necessário informar datas com o máximo de 60 dias de diferença!" });

          return;
        }
      }

      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        stage: (fields.stage?.value?.value === 'ALL' ? null : fields.stage?.value?.value),
        greenhouseId: fields.greenhouseId?.value?.value,
      }
      http.get('lot/simple', { params })
        .then(({ data }) => {
          data.data.map((item) => {
            item.selected = false;
          })
          setList(data.data.filter((item) => item.greenery.name.toLowerCase().includes(fields.query?.value?.toLowerCase())));
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  },
  qrCodeButton: {
    name: 'qrCodeButton',
    label: 'Gerar QR Code',
    type: 'button',
    variation: 'primary',
    onClick: () => {

      let selecteds = '';

      list.map((item) => {
        if (item.selected === true) {
          if (selecteds === '') {
            selecteds = item.id;
          }
          else {
            selecteds = selecteds + ',' + item.id;
          }
        }
      });

      if (selecteds === '') {
        setModal({ isOpen: true, text: 'Selecione pelo menos um lote!' });
        return;
      }

      http.get(`lot/multiplesqrcode/${selecteds}`)
        .then(({ data }) => {
          const linkSource = `data:application/octet-stream;base64,${data.data.report}`;
          const downloadLink = document.createElement('a');
          const fileName = `${data.data.name}`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          setModal({ isOpen: false })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  },
});

export const cardInfos = [
  { fieldLabel: 'Lote', fieldType: 'lot' },
  { fieldLabel: 'Hortaliça', fieldType: 'greenery' },
  { fieldLabel: 'Quantidade Plantada', fieldType: 'plantQuantity' },
  { fieldLabel: 'Quantidade Atual', fieldType: 'actualQuantity' },
  { fieldLabel: 'Estágio Atual', fieldType: 'actualStage' },
  { fieldLabel: 'Data de plantio', fieldType: 'plantDate' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
  { fieldLabel: '', fieldType: 'qrCode' },
  { fieldLabel: '', fieldType: 'select' }
]

export const searchGridTemplate = css`
  grid-template: ${`
  "query"
  "startDate"
  "endDate"
  "stage"
  "greenhouseId"
  "startDate"
  "submitButton"
  "qrCodeButton"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } } }) => screenXS}) {
      grid-template: ${`
      "query query query query query startDate startDate endDate endDate"
      "stage stage greenhouseId greenhouseId greenhouseId submitButton submitButton qrCodeButton qrCodeButton"
      `};
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "lot greenery plantQuantity actualQuantity actualStage plantDate qrCode select history delete"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template:
        "lot greenery plantQuantity actualQuantity actualStage plantDate qrCode select history delete"
      ;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 40px 40px 40px 40px;
    }
  }
`
