import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'manufacturer',
  singular: `fabricante`,
  plural: `fabricantes`,
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const manufacturerFieldsState = () => ({
  name: { value: '' },
});

export const manufacturerFields = ({ fields }) => ({
  name: { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
  `};
`
