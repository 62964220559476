import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#8bc53f'

export const pageLabel = {
  name: 'plantingLot',
  singular: 'lote de plantio',
  plural: 'lotes de plantio',
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const plantingLotFieldsState = () => ({
  greenery: { value: '' },
  stage: { value: '' },
  seed: { value: '' },
  greenHouse: { value: '' },
  sector: { value: '', options: [] },
  stand: { value: '', options: [] },
  amount: { value: '' },
  from: { value: new Date() },
  comments: { value: '' },
});

export const plantingLotFields = ({ fields }) => {
  const newFields = ({
    greenery: { 
      ...fields.greenery,
      name: 'greenery',
      label: 'Hortaliça',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      loadOptions: (query, callback) => {
        http.get('greeneries')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
          .catch((err) => callback([]))
      }, 
      onChange: async ({ target: { value, name }, setFields }) => {
        let options = [];
        if (fields.stage.value) {
          const groupType = fields.stage.value.value === 'MATERNIDADE' ? 'SEMENTES' : 'MUDAS';
          const greenery = `&greeneryId=${value.value}`
          options = await http
            .get(`input/?inputGroup1=${groupType}${greenery}`)
            .then(({ data }) => data?.data?.map((option) => ({ ...option, label: option.name, value: option.id })))
        }
        setFields((oldFields) => { 
          const newFields = { ...oldFields };
          newFields[name].value = value
          newFields[name].error = false
          newFields[name].errorMessage = ''
          if (fields.stage.value) {
            newFields.seed.value = '';
            newFields.seed.options = options;
          }
          return newFields 
        })
      },
      validate: (value) => ({ empty: !value }),
      isSearchable: false,
    },
    stage: { 
      ...fields.stage,
      name: 'stage',
      label: 'Estágio',
      placeholder: 'Selecione',
      options: [
        { label: 'MATERNIDADE', value: 'MATERNIDADE' },
        { label: 'VIVEIRO', value: 'VIVEIRO' },
        { label: 'BERÇARIO', value: 'BERÇARIO' },
        { label: 'PERFIL FINAL', value: 'PERFIL FINAL' } 
      ],
      type: 'select',
      isSearchable: false,
      loadEmpty: true,
      onChange: async ({ target: { value, name, i }, setFields }) => {
        const groupType = value.value === 'MATERNIDADE' ? 'SEMENTES' : 'MUDAS';
        const greenery = newFields.greenery.value ? `&greeneryId=${newFields.greenery.value.value}` : ''
        const options = await http
          .get(`input/?inputGroup1=${groupType}${greenery}`)
          .then(({ data }) => data?.data?.map((option) => ({ ...option, label: option.name, value: option.id })))
        setFields((oldFields) => { 
          const newFields = { ...oldFields };
          newFields[name].value = value
          newFields[name].error = false
          newFields[name].errorMessage = ''
          newFields.seed.value = '';
          newFields.seed.options = options;
          return newFields 
        })
      },
      validate: (value) => ({ empty: !value }),
    },
    greenHouse: { 
      ...fields.greenHouse,
      name: 'greenHouse',
      label: 'Estufa',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      loadOptions: (query, callback, setFields) => {
        http.get('greenhouse')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
          .catch((err) => callback([]))
      }, 
      onChange: ({ target: { value, name, i }, setFields }) => {
        setFields((oldFields) => { 
          const newFields = { ...oldFields };
          newFields[name].value = value
          newFields[name].error = false
          newFields[name].errorMessage = ''
          newFields.sector.value = ''
          newFields.stand.value = ''
          return newFields 
        })
      },
      validate: (value) => ({ empty: !value && (fields.stage.value.value === 'BERÇARIO' || fields.stage.value.value === 'PERFIL FINAL') }),
      isClearable: true,
    },
    amount: { 
      ...fields.amount,
      name: 'amount',
      label: 'Quantidade',
      placeholder: 'Digite a quantidade',
      type: 'number',
      suffix: ' unidades',
      validate: (value) => ({
        empty: !value,
        invalid: value === "0 unidades",
        invalidMessage: 'Preencher número maior que 0'
      })
    },
    from: { 
      ...fields.from,
      name: 'from',
      label: 'Data de plantio',
      type: 'date',
      validate: (value) => ({ empty: !value }),
    },
    comments: { 
      ...fields.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
    },
  })
  const isSeed = fields.stage.value.value === 'MATERNIDADE';
  const isSeeded = fields.stage.value.value === 'BERÇARIO' || fields.stage.value.value === 'VIVEIRO' || fields.stage.value.value === 'PERFIL FINAL';
  if(isSeed) {
    newFields.seed = { 
      ...fields.seed,
      name: 'seed',
      label: 'Semente',
      placeholder: 'Selecione',
      type: 'select',
      isSearchable: false,
      loadEmpty: true,
      validate: (value) => ({ empty: !value }),
    }
  }
  if(isSeeded) {
    newFields.seed = { 
      ...fields.seed,
      name: 'seed',
      label: 'Muda',
      placeholder: 'Selecione',
      type: 'select',
      isSearchable: false,
      loadEmpty: true,
      validate: (value) => ({ empty: !value }),
    }
  }
  if(fields.greenHouse.value?.sectors) {
    newFields.sector = { 
      ...fields.sector,
      name: 'sector',
      label: 'Setor',
      placeholder: 'Selecione',
      options: fields.greenHouse.value?.sectors?.map((option) => ({ ...option, label: option.name, value: option.id })),
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      onChange: ({ target: { value, name, i }, setFields }) => {
        setFields((oldFields) => { 
          const newFields = { ...oldFields };
          newFields[name].value = value
          newFields[name].error = false
          newFields[name].errorMessage = ''
          newFields.stand.value = ''
          return newFields 
        })
      },
      validate: (value) => ({ empty: !value && (fields.stage.value.value === 'BERÇARIO' || fields.stage.value.value === 'PERFIL FINAL') }),
      isClearable: true,
    }
  } 
  if(!!fields.sector.value) {
    const options = []
    fields.sector.value.stand?.map((option) => options.push({ ...option, label: option.name, value: option.id }));
    newFields.stand = { 
      ...fields.stand,
      name: 'stand',
      label: 'Banca',
      placeholder: 'Selecione',
      options,
      type: 'select',
      loadEmpty: true,
      isClearable: true,
      validate: (value) => ({ empty: !value && (fields.stage.value.value === 'BERÇARIO' || fields.stage.value.value === 'PERFIL FINAL') }),
      isSearchable: false,
    }
  }
  return newFields;
};

export const gridTemplate = css`
  grid-template: ${`
    "greenery" 
    "stage" 
    "seed" 
    "greenHouse" 
    "sector"
    "stand"
    "amount"
    "from"
    "comments"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "greenery stage seed" 
        "greenHouse sector stand"
        "amount from ."
        "comments comments comments"
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
