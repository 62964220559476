import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'waterTank',
  singular: `caixa d'água`,
  plural: `caixas d'água`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const waterTankFieldsState = () => ({
  name: { value: '' },
  capacity: { value: '' },
  cleaningFrequency: { value: '' },
  nutritiveSolution: { value: '' },
  cleaningLastDate: { value: '' },
});

export const waterTankFields = ({ fields, id }) => {
  const newFields = ({
    name: { 
      ...fields.name,
      name: 'name',
      label: 'Nome',
      placeholder: 'Digite',
      validate: (value) => ({ empty: !value }), 
    },
    capacity: { 
      ...fields.capacity,
      name: 'capacity',
      label: 'Capacidade',
      placeholder: 'Digite',
      type: 'number',
      suffix: ' litros',
      validate: (value) => ({ empty: !value }), 
    },
    cleaningFrequency: { 
      ...fields.cleaningFrequency,
      name: 'cleaningFrequency',
      label: 'Frequência de limpeza',
      placeholder: 'Digite',
      type: 'number',
      suffix: ' dias',
      validate: (value) => ({ empty: !value }), 
    },
    nutritiveSolution: { 
      ...fields.nutritiveSolution,
      name: 'nutritiveSolution',
      label: 'Solução nutritiva',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      loadOptions: (query, callback) => {
        http.get('nutritive-solution')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
      },
      validate: (value) => ({ empty: !value }), 
    },
  })
  if (id) {
    newFields.cleaningLastDate = {
      name: 'cleaningLastDate',
      type: 'date',
      label: 'Data da última limpeza',
      ...fields.cleaningLastDate,
    }
  }
  return newFields
};

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "capacity" 
    "nutritiveSolution"
    "cleaningFrequency"
    "cleaningLastDate"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name capacity" 
        "nutritiveSolution nutritiveSolution"
        "cleaningFrequency cleaningLastDate"
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
