import DashboardIcon from '../../images/DashboardIcon';
import RegisterIcon from '../../images/RegisterIcon';
import ReportsIcon from '../../images/ReportsIcon';
import PlantingLotsIcon from '../../images/PlantingLotsIcon';
import NotebookIcon from '../../images/NotebookIcon';
import MeasureIcon from '../../images/MeasureIcon';
import ListIcon from '../../images/ListIcon';
import TagsIcon from '../../images/TagsIcon';
// import PlansIcon from '../../images/PlansIcon';
import FarmsIcon from '../../images/FarmsIcon';
import UsersIcon from '../../images/UsersIcon';
import GreeneryIcon from '../../images/GreeneryIcon';
import WaterBoxIcon from '../../images/WaterBoxIcon';
import NutritiveSolution from '../../images/NutritiveSolution';
import InputsIcon from '../../images/InputsIcon';
import GreenHouseIcon from '../../images/GreenHouseIcon';
import RecipeIcon from '../../images/RecipeIcon';
import PestIcon from '../../images/PestIcon';
import ManufacturerIcon from '../../images/ManufacturerIcon';

export const  navItems = [
  { label: 'Dashboard', icon: DashboardIcon, color: '#FFD255', path: '/' },
  { label: 'Cadastros', icon: RegisterIcon, color: '#00a1a5', childs: [
    { label: 'Hidroponia', icon: FarmsIcon, path: '/hidroponia/editar' },
    { label: 'Usuário', icon: UsersIcon, path: '/usuarios' },
    { label: 'Hortaliça', icon: GreeneryIcon, path: '/hortalicas' },
    { label: 'Insumo', icon: InputsIcon, path: '/insumos' },
    { label: 'Solução nutritiva', icon: NutritiveSolution, path: '/solucoes-nutritivas' },
    { label: `Caixa D'água`, icon: WaterBoxIcon, path: '/caixas-dagua' },
    { label: 'Estufa', icon: GreenHouseIcon, path: '/estufas' },
    { label: 'Receita', icon: RecipeIcon, path: '/receitas' },
    { label: 'Praga', icon: PestIcon, path: '/pragas' },
    { label: 'Clientes', icon: ManufacturerIcon, path: '/clientes' },
  ] },
  { label: 'Lotes de plantio', icon: PlantingLotsIcon, color: '#8bc53f', path: '/lotes-de-plantio' },
  { label: 'Caderno de campo', icon: NotebookIcon, color: '#419645', path: '/cadernos-de-campo' },
  { label: 'Controle de EC & PH', icon: MeasureIcon, color: '#13739e', path: '/controles-de-ph-&-ec' },
  { label: 'Lista de atividades', icon: ListIcon, color: '#00a1a5', path: '/atividades' },
  { label: 'Relatórios', icon: ReportsIcon, color: '#419645', childs: [
    { label: 'Atividades', icon: ListIcon, path: '/relatorios/atividades' },
    { label: 'Previsão de colheita', icon: PlantingLotsIcon, path: '/relatorios/previsao-de-colheita' },
    { label: 'Lotes Colhidos', icon: PlantingLotsIcon, path: '/relatorios/colheita' },
  ] },
  { label: 'Expedição', icon: TagsIcon, color: '#00a1a5', path: '/expedicoes' },
]