import React from "react";

export default function VivariumIcon() {
  return (
    <svg
      width="169"
      height="75"
      viewBox="0 0 169 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.03 37.21C167.92 35.26 166.46 33.43 163.76 31.92C163.49 31.77 163.14 31.86 162.99 32.14C162.84 32.41 162.93 32.76 163.21 32.91C164.9 33.86 166.92 35.41 166.92 37.44C166.92 41.58 158.4 45.1 148.05 45.37V28.37C148.05 28.21 147.98 28.07 147.88 27.97C148.03 26.87 148.1 25.73 148.1 24.56C148.1 21.38 147.54 18.53 146.43 16.11C145.32 13.68 143.73 11.65 141.71 10.08C139.72 8.54002 137.62 7.30002 135.46 6.41002C133.32 5.52002 130.81 4.79002 127.99 4.22002L110.37 0.0300153C110.08 -0.0399847 109.78 0.130015 109.7 0.430015C108.17 5.81002 108.6 10.58 110.98 14.62C113.67 19.19 118.8 22.57 125.43 24.12L146.92 28.84V45.4C146.87 45.4 146.82 45.4 146.77 45.4C135.85 45.4 126.62 41.76 126.62 37.45C126.62 37.14 126.37 36.88 126.05 36.88C125.73 36.88 125.48 37.13 125.48 37.45C125.48 42.54 134.83 46.53 146.76 46.53C156.28 46.53 164.16 43.99 166.95 40.38V62.65C166.95 68.71 162.02 73.64 155.96 73.64H27.21C21.15 73.64 16.22 68.71 16.22 62.65V40.33C18.99 43.96 26.89 46.51 36.44 46.51C48.37 46.51 57.72 42.52 57.72 37.43C57.72 35.39 56.24 33.48 53.44 31.91C53.17 31.76 52.82 31.85 52.67 32.13C52.52 32.4 52.61 32.75 52.89 32.9C54.58 33.85 56.6 35.4 56.6 37.43C56.6 41.74 47.37 45.38 36.45 45.38C25.53 45.38 16.3 41.74 16.3 37.43C16.3 37.17 16.13 36.96 15.89 36.89C15.82 36.86 15.75 36.84 15.66 36.84C15.35 36.84 15.09 37.09 15.09 37.41V62.63C15.09 69.31 20.53 74.75 27.21 74.75H155.95C162.63 74.75 168.07 69.31 168.07 62.63V37.43C168.08 37.36 168.06 37.28 168.03 37.21ZM125.66 23C119.34 21.52 114.46 18.34 111.93 14.03C109.79 10.4 109.35 6.10002 110.62 1.24002L127.72 5.31002C127.73 5.31002 127.73 5.31002 127.74 5.31002C130.49 5.86002 132.93 6.58002 135.01 7.44002C137.07 8.30002 139.09 9.48002 141 10.96C142.88 12.42 144.35 14.3 145.39 16.56C146.43 18.83 146.96 21.52 146.96 24.54C146.96 25.6 146.9 26.63 146.77 27.63L125.66 23Z"
        fill="white"
      />
      <path
        d="M38.11 28.82V44.76C38.11 45.07 38.36 45.33 38.68 45.33C39 45.33 39.25 45.08 39.25 44.76V28.36C39.25 28.2 39.18 28.06 39.08 27.96C39.23 26.86 39.3 25.72 39.3 24.55C39.3 21.37 38.74 18.52 37.63 16.1C36.52 13.67 34.93 11.64 32.91 10.07C30.92 8.53001 28.82 7.29001 26.66 6.40001C24.52 5.51001 22.01 4.78001 19.19 4.21001L1.57 0.0200055C1.28 -0.0499945 0.979997 0.120005 0.899997 0.420006C-0.630003 5.79001 -0.200003 10.56 2.17 14.6C4.86 19.17 9.99 22.55 16.62 24.1L38.11 28.82ZM3.15 14.03C1.01 10.4 0.569997 6.10001 1.84 1.24001L18.94 5.31001C18.95 5.31001 18.95 5.31001 18.96 5.31001C21.71 5.86001 24.15 6.58001 26.23 7.44001C28.29 8.30001 30.31 9.48001 32.22 10.96C34.1 12.42 35.57 14.3 36.61 16.56C37.65 18.83 38.18 21.52 38.18 24.54C38.18 25.6 38.12 26.63 37.99 27.63L16.88 23C10.56 21.52 5.68 18.34 3.15 14.03Z"
        fill="white"
      />
      <path
        d="M70.45 24.1L91.94 28.82V45.36C81.68 45.05 73.28 41.55 73.28 37.44C73.28 37.13 73.03 36.87 72.71 36.87C72.39 36.87 72.14 37.12 72.14 37.44C72.14 42.53 81.49 46.52 93.42 46.52C105.35 46.52 114.7 42.53 114.7 37.44C114.7 35.4 113.22 33.49 110.42 31.92C110.15 31.77 109.8 31.86 109.65 32.14C109.5 32.41 109.59 32.76 109.87 32.91C111.56 33.86 113.58 35.41 113.58 37.44C113.58 41.75 104.35 45.39 93.43 45.39C93.31 45.39 93.19 45.39 93.07 45.39V28.36C93.07 28.2 93 28.06 92.9 27.96C93.05 26.86 93.12 25.72 93.12 24.55C93.12 21.37 92.56 18.52 91.45 16.1C90.34 13.67 88.75 11.64 86.73 10.07C84.74 8.53001 82.64 7.29001 80.48 6.40001C78.34 5.51001 75.83 4.78001 73.01 4.21001L55.4 0.0200055C55.11 -0.0499945 54.81 0.120005 54.73 0.420006C53.19 5.79001 53.62 10.56 56 14.6C58.69 19.18 63.82 22.55 70.45 24.1ZM55.67 1.24001L72.77 5.31001C72.78 5.31001 72.78 5.31001 72.79 5.31001C75.54 5.86001 77.98 6.58001 80.06 7.44001C82.12 8.30001 84.14 9.48001 86.05 10.96C87.93 12.42 89.4 14.3 90.44 16.56C91.48 18.83 92.01 21.52 92.01 24.54C92.01 25.6 91.95 26.63 91.82 27.63L70.7 23C64.38 21.52 59.5 18.34 56.97 14.03C54.84 10.4 54.4 6.10001 55.67 1.24001Z"
        fill="white"
      />
    </svg>
  );
}
