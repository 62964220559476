import React from 'react';

export default function UsersIcon () {
  return (
    <svg width="97" height="102" viewBox="0 0 97 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M49.43 101.61C37.58 101.61 29.4 98.87 24.62 96.58C21.61 95.13 19.53 93.66 18.31 92.69C17.56 92.09 17.41 91 17.99 90.22L23.04 83.37C28.99 75.3 38.15 70.67 48.17 70.67C58.19 70.67 67.36 75.3 73.3 83.37L78.48 90.4C79.01 91.12 78.93 92.12 78.29 92.74C77.29 93.72 75.55 95.19 72.88 96.63C68.69 98.91 61.21 101.61 49.43 101.61ZM21.94 90.91C23 91.63 24.41 92.49 26.18 93.34C30.62 95.47 38.25 98.02 49.43 98.02C60.45 98.02 67.35 95.55 71.19 93.47C72.66 92.68 73.79 91.89 74.63 91.21L70.42 85.5C65.16 78.36 57.05 74.26 48.17 74.26C39.29 74.26 31.19 78.36 25.92 85.5L21.94 90.91Z" fill="#3BBC7B"/>
      <path d="M48.17 64.59C35.48 64.59 25.16 54.27 25.16 41.58C25.16 28.89 35.48 18.57 48.17 18.57C60.86 18.57 71.18 28.89 71.18 41.58C71.19 54.26 60.86 64.59 48.17 64.59ZM48.17 22.15C37.46 22.15 28.75 30.87 28.75 41.58C28.75 52.29 37.46 61 48.17 61C58.88 61 67.6 52.29 67.6 41.58C67.6 30.87 58.89 22.15 48.17 22.15Z" fill="#3BBC7B"/>
      <path d="M7.75 79.09C7.1 79.09 6.47 78.73 6.15 78.12C2.13 70.33 0 61.45 0 52.46C0 23.53 21.64 0 48.24 0C74.84 0 96.48 23.53 96.48 52.46C96.48 61 94.55 69.48 90.9 76.98C90.47 77.87 89.39 78.25 88.5 77.81C87.61 77.38 87.24 76.3 87.67 75.41C91.09 68.39 92.89 60.46 92.89 52.46C92.89 25.51 72.86 3.58 48.23 3.58C23.61 3.58 3.58 25.51 3.58 52.46C3.58 60.88 5.57 69.19 9.33 76.47C9.78 77.35 9.44 78.43 8.56 78.89C8.31 79.03 8.03 79.09 7.75 79.09Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="96.48" height="101.61" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
