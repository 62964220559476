
export const inputChange = ({ target, setFields, onChange }) => {
  const { name, value } = target;
  if (onChange) { return (onChange({target, setFields})) }
  setFields((oldFields) => {
    const newFields = { ...oldFields }
    newFields[name].error = false;
    newFields[name].errorMessage = '';
    newFields[name].value = value;
    return newFields;
  });
};

export const inputValidate = ({ target, setFields, validate, onBlur }) => {
  if (onBlur) { return (onBlur({target, setFields, validate})) }
  if (validate) {
    const { name, value } = target;
    const validation = validate(value);
    if(validation.empty) {
      setFields((oldFields) => {
        oldFields[name].error = true;
        oldFields[name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
        return ({ ...oldFields });
      });
    } else if(value && validation.invalid) {
      setFields((oldFields) => {
        oldFields[name].error = true;
        oldFields[name].errorMessage = validation.invalidMessage;
        return ({ ...oldFields });
      });
    }
  }
};