import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import PlantingLotsIcon from '../../images/PlantingLotsIcon';
import * as S from './Manufacturers.style';
import * as constants from './Manufacturers.constants';

export default function Manufacturers ({setModal}) {
  const { manufacturersSearchFieldsState, manufacturersSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(manufacturersSearchFieldsState);
  const searchFields = manufacturersSearchFields({fields});


  useEffect(() => {
    const params = mapFieldsToData({fields: searchFields});
    http.get('fabricante', { params })
      .then(({ data }) => setList(data))
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }, [])

  const removeItem = ({ id, index }) => {
    http.delete(`fabricante/${id}`)
      .then(res => setList((oldList) => {
        const newList = [ ...oldList ];
        newList.splice(index, 1);
        return newList;
      }))
  }

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: PlantingLotsIcon, setList, setFields, removeItem,
  }

  return (
    <S.Manufacturers>
      <ListPage {...listPageObject} />
    </S.Manufacturers>
  );
};
