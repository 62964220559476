import styled from 'styled-components';

export const DashboardLosts = styled.div`
  padding: 32px;
  grid-area: dashboardLosts;

  >button {
    margin-top: 32px;
  }
`
