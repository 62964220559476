import React, { useEffect, useState } from 'react';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import ActivitiesReportIcon from '../../images/ListIcon';
import * as S from './ActivitiesReport.style';
import * as constants from './ActivitiesReport.constants';
import { subDays, addDays } from 'date-fns';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function ActivitiesReport({ setModal }) {
  const { ActivitiesReportSearchFieldsState, ActivitiesReportSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(ActivitiesReportSearchFieldsState);
  const searchFields = ActivitiesReportSearchFields({ fields, setList, setModal });
  const url = 'tasks';
  const { dias } = useParams();

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: ActivitiesReportIcon, setList, setFields
  };

  useEffect(() => {
    if (dias) {
      fields.startDate.value = subDays(new Date(), 30);
      fields.endDate.value = addDays(new Date(), dias);
    }

    const params = {
      startDate: fields.startDate.value,
      endDate: fields.endDate.value,
      status1: fields.status.value?.value,
      type: fields.reportActivityType.value?.value,
      greenhouseId: fields.greenhouseId.value?.value,
      sectorId: fields.sectorId.value?.value,
    }
    setTimeout(() => {
      http.get(url, { params })
        .then(({ data }) => {
          setList(data.data.map((item) => {
            const sector = { name: item.sectorName };
            const greenhouse = { id: item.greenhouseId, name: item.greenhouseName };
            return ({ ...item, reportActivityType: item.type, sector, greenhouse })
          }))
        })
        .catch((err) => setList([]))
    }, 10)
  }, [])

  return (
    <S.ActivitiesReport>
      <ListPage {...listPageObject} />
    </S.ActivitiesReport>
  );
};
