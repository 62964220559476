import React, { useState, useEffect } from "react";
import { addDays } from 'date-fns'
import { useHistory } from 'react-router-dom';
import * as moment from 'moment'
import http from '../../../service'
import DashboardTitle from "../DashboardTitle";
import DashboardTabs from "../DashboardTabs";
import DashboardNextActivitiesTab from "./DashboardNextActivitiesTab";
import Button from "../../Button";
import * as S from "./DashboardNextActivities.style";

const DashboardNextActivities = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [tabs, setTabs] = useState([
    {
      label: '7 dias', value: 7, data: [
        { label: 'Transferência', value: 0, color: '#FFD255' },
        { label: 'Colheita', value: 0, color: '#8bc53f' },
        { label: 'Limpeza', value: 0, color: '#16c4c4' },
        { label: 'Manutenção', value: 0, color: '#ff6b57' },
      ]
    },
    {
      label: '15 dias', value: 15, data: [
        { label: 'Transferência', value: 0, color: '#FFD255' },
        { label: 'Colheita', value: 0, color: '#8bc53f' },
        { label: 'Limpeza', value: 0, color: '#16c4c4' },
        { label: 'Manutenção', value: 0, color: '#ff6b57' },
      ]
    },
    {
      label: '30 dias', value: 30, data: [
        { label: 'Transferência', value: 0, color: '#FFD255' },
        { label: 'Colheita', value: 0, color: '#8bc53f' },
        { label: 'Limpeza', value: 0, color: '#16c4c4' },
        { label: 'Manutenção', value: 0, color: '#ff6b57' },
      ]
    },
  ])
  const history = useHistory();

  useEffect(() => {
    const params7Days = {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(addDays(new Date(), 7)).format('YYYY-MM-DD'),
    }
    const params15Days = {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(addDays(new Date(), 15)).format('YYYY-MM-DD'),
    }
    const params30Days = {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(addDays(new Date(), 30)).format('YYYY-MM-DD'),
    }
    const params = {
      status1: 'OPEN',
      status2: 'REPLANNED',
      status3: 'IN_PROGRESS',
    }
    http.get('tasks', { params: { ...params, ...params7Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];
        newTabs[0].data[0].value = data.data.filter((item) => item.type.toLowerCase().includes('transferência')).length
        newTabs[0].data[1].value = data.data.filter((item) => item.type.toLowerCase().includes('colheita')).length
        newTabs[0].data[2].value = data.data.filter((item) => item.type.toLowerCase().includes('limpeza') || item.type.toLowerCase().includes('lavagem')).length
        newTabs[0].data[3].value = data.data.filter((item) => item.type.toLowerCase().includes('manutenção') || item.type.toLowerCase().includes('aplicação') || item.type.toLowerCase().includes('tarefas')).length

        newTabs[0].data.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        return newTabs
      }))
    http.get('tasks', { params: { ...params, ...params15Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];

        data.data = data.data.filter((item) => item.plannedDate >= params15Days.startDate && item.plannedDate <= params15Days.endDate);

        newTabs[1].data[0].value = data.data.filter((item) => item.type.toLowerCase().includes('transferência')).length
        newTabs[1].data[1].value = data.data.filter((item) => item.type.toLowerCase().includes('colheita')).length
        newTabs[1].data[2].value = data.data.filter((item) => item.type.toLowerCase().includes('limpeza') || item.type.toLowerCase().includes('lavagem')).length
        newTabs[1].data[3].value = data.data.filter((item) => item.type.toLowerCase().includes('manutenção') || item.type.toLowerCase().includes('aplicação') || item.type.toLowerCase().includes('tarefas')).length

        newTabs[1].data.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        return newTabs
      }))
    http.get('tasks', { params: { ...params, ...params30Days } })
      .then(({ data }) => setTabs((oldTabs) => {
        const newTabs = [...oldTabs];

        data.data = data.data.filter((item) => item.plannedDate >= params30Days.startDate && item.plannedDate <= params30Days.endDate);

        newTabs[2].data[0].value = data.data.filter((item) => item.type.toLowerCase().includes('transferência')).length
        newTabs[2].data[1].value = data.data.filter((item) => item.type.toLowerCase().includes('colheita')).length
        newTabs[2].data[2].value = data.data.filter((item) => item.type.toLowerCase().includes('limpeza') || item.type.toLowerCase().includes('lavagem')).length
        newTabs[2].data[3].value = data.data.filter((item) => item.type.toLowerCase().includes('manutenção') || item.type.toLowerCase().includes('aplicação') || item.type.toLowerCase().includes('tarefas')).length

        newTabs[2].data.sort((a, b) => {
          if (a.value > b.value)
            return -1;
          if (a.value < b.value)
            return 1;
          return 0;
        });

        return newTabs
      }))
  }, [])


  const nextActivities = tabs[activeTab].data.reduce((acc, dataItem) => acc + dataItem.value, 0)

  return (
    <S.DashboardNextActivities>
      <DashboardTitle>
        Próximas atividades
        <S.DashboardNextActivitiesValue>{nextActivities}</S.DashboardNextActivitiesValue>
      </DashboardTitle>
      <DashboardTabs tabs={tabs} component={DashboardNextActivitiesTab} activeTab={activeTab} setActiveTab={setActiveTab} color="#ff6b57" />
      <Button label=". . . ver mais" onClick={() => history.push('/relatorios/atividade/' + (activeTab === 0 ? '7' : (activeTab === 1 ? '15' : '30')))} />
    </S.DashboardNextActivities>
  );
};

export default DashboardNextActivities;
