import React from 'react';
import { inputChange, inputValidate } from '../../helpers/fieldFunctions';
import * as S from './Input.style';

export default function InputTextArea({ setFields, onChange, i, parentI, validate, onBlur, ...props}) {

  return (
    <S.InputTextarea
      {...props}
      rows={4}
      onChange={({ target }) => inputChange({ target: { ...target, i, parentI, name: props.name }, setFields, onChange })}
      onBlur={({ target }) => inputValidate({ target: { ...target, i, parentI, name: props.name }, setFields, validate, onBlur })}
      maskChar=""
    />
  );
}
