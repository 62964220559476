import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Greenery.constants';
import * as S from './Greenery.style';
import { useParams, useHistory } from 'react-router-dom';

export default function Greenery ({ setModal }) {
  const { id } = useParams();
  const { greeneryFieldsState, greeneryFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(greeneryFieldsState);
  const itemFields = greeneryFields({fields});
  const [userOrg, setUserOrg] = useState([]);
  const history = useHistory();

  const useMountEffect = (fun) => useEffect(fun, [])
  const getUsername = () => {
    const storage = localStorage.getItem('currentUser');
    const thisUser = JSON.parse(storage);
    setUserOrg(thisUser.organization);
  }; 
  useMountEffect(getUsername);

  useEffect(() => {
    if(id) {
      http.get(`greeneries/${id}`)
        .then((response) => {
          const data = response.data.data
          setFields({
            name: { value: data.name },
            initials: { value: data.code },
            boxUnits: { value: data.qttPerBox },
            planned: { value: data.qttPlanPlant },
            maternityCycle: { value: data.maternidade },
            vivariumCycle: { value: data.viveiro },
            nurseryCycle: { value: data.bercario },
            finalCycle: { value: data.final }
          })
        })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
      }
  }, [id])

  const onSubmit = () => {
    http[id ? 'put' : 'post'](`greeneries${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      code: fields.initials.value,
      qttPerBox: Number(fields.boxUnits.value),
      qttPlanPlant: Number(fields.planned.value),
      maternidade: Number(fields.maternityCycle.value),
      viveiro: Number(fields.vivariumCycle.value),
      bercario: Number(fields.nurseryCycle.value),
      final: Number(fields.finalCycle.value)
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Greenery>
      <ItemPage {...itemPageObject} />
    </S.Greenery>
  );
};
