import React from 'react';
import http from '../../service'
import * as S from './GreeneryChart.style';
import { lighten } from 'polished'
import { createRef } from 'react';

export default function GreeneryChart({ data, color, shortLabel, percentualShortLabel, sendDataToParent, ...props }) {

  const decimals = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  const getSectorData = (id) => {

    if (id) {
      http.get(`greenhouse/sectorOccupancyByGreenhouse/${id}`)
        .then(({ data }) => {

          let occupation = [];

          data.data[0].sectors.map((occupationDetail) => {
            occupation.push(
              {
                id: occupationDetail.sectors_id,
                label: occupationDetail.sectors_name,
                title: occupationDetail.greenhouse_name,
                actualQuantity: occupationDetail.occupancy,
                capacity: occupationDetail.capacity,
                actualPercentage: occupationDetail.percentualOccupancy
              });
          });

          sendDataToParent(occupation);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <S.GreeneryChart>
      <S.GreeneryChartLabel>
        <S.GreeneryChartLabelValue color={color}>{shortLabel}<hr /> <span>{percentualShortLabel}%</span></S.GreeneryChartLabelValue>
        <S.GreeneryChartCaption>
          <S.GreeneryChartCaptionItem display='flex' color={color}>Plantado</S.GreeneryChartCaptionItem>
          <S.GreeneryChartCaptionItem display={shortLabel === 'Ocupação' ? 'none' : 'flex'} color={lighten('.3', color)}>Planejado</S.GreeneryChartCaptionItem>
        </S.GreeneryChartCaption>
      </S.GreeneryChartLabel>
      <S.GreeneryChartNumbers>
        {decimals.map((decimal) => <S.GreeneryChartNumber>{decimal}</S.GreeneryChartNumber>)}
      </S.GreeneryChartNumbers>
      <S.GreeneryChartWhiteSpace></S.GreeneryChartWhiteSpace>
      <S.GreeneryChartNumberLines>{decimals.map(() => <S.GreeneryChartNumberLine />)}</S.GreeneryChartNumberLines>
      { data.map(({ id, label, actualPercentage, plannedPercentage }) => (
        <>
          <S.GreeneryChartItemLabel cursor={id ? 'pointer' : ''} onClick={() => getSectorData(id)}>{label}</S.GreeneryChartItemLabel>
          <S.GreeneryChartItemBar>
            {actualPercentage && <S.GreeneryChartItemBarValue color={color} value={actualPercentage} />}
            {plannedPercentage && <S.GreeneryChartItemBarValue color={lighten('.3', color)} value={plannedPercentage} />}
          </S.GreeneryChartItemBar>
        </>
      ))}
    </S.GreeneryChart>
  )
}