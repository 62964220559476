import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Recipe.constants';
import * as S from './Recipe.style';
import { useParams, useHistory } from 'react-router-dom';

export default function Recipe ({ setModal }) {
  const { id } = useParams();
  const { recipeFieldsState, recipeFields, newCompositions } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(recipeFieldsState);
  const itemFields = recipeFields({fields, setFields});
  const history = useHistory();

  useEffect(() => {
    const newComposition = newCompositions(fields, setFields, 0)
    if(id) {
      http.get(`recipes/${id}`)
        .then(({ data }) => {
          const compositions = data.data.recipeComposition.map((item) => ({
            ...newComposition, 
            input:  { ...newComposition.input, value: { ...item.input, label: item.input.name, value: item.inputId }},
            amount:  { ...newComposition.amount, value: item.quantity },
            unit:  { ...newComposition.unit, value: newComposition.unit.options.find((option) => option.label === item.measureUnity)},
          }))
          const gracePeriod = compositions.reduce((biggest, comp) => comp.input.value.carencia > biggest ? comp.input.value.carencia : biggest, 0)
          setFields({
            name: { value: data.data.name },
            description: { value: data.data.description },
            compositions: { value: compositions },
            caption: { label: gracePeriod ? `*Esta receita possui carência de ${gracePeriod} dias` : ''}
          })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    } else {
      setFields((oldFields) => { 
        const compositionsValue = [...oldFields.compositions.value, newComposition]
        return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
      })
    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`recipes${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      description: fields.description.value,
      recipeComposition: fields.compositions.value
        .map((comp) => ({ 
          inputId: comp.input.value.value,
          quantity: Number(comp.amount.value),
          measureUnity: comp.unit.value.label,
        })),
        carencia: fields.compositions.value.reduce((biggest, comp) => comp.input.value.carencia > biggest ? comp.input.value.carencia : biggest, 0),
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })  
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Recipe>
      <ItemPage {...itemPageObject} />
    </S.Recipe>
  );
};
