import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import PlantingLotsIcon from '../../images/PlantingLotsIcon';
import * as S from './PlantingLots.style';
import * as constants from './PlantingLots.constants';

import { subDays, addDays } from 'date-fns';
import * as moment from 'moment'

export default function PlantingLots({ setModal }) {
  const { plantingLotsSearchFieldsState, plantingLotsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(plantingLotsSearchFieldsState);
  const searchFields = plantingLotsSearchFields({ fields, setList, setModal, list });
  const url = 'lot'

  const setSelected = (index, selected) => {
    list[index].selected = selected;
  }

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: PlantingLotsIcon, setList, setFields, setSelected
  };

  useEffect(() => {
    const query = searchFields.query.value;
    const params = {
      startDate: moment(addDays(new Date(), -30)).format("YYYY-MM-DD"),
      endDate: moment(addDays(new Date(), 30)).format("YYYY-MM-DD"),
    }

    http.get('lot/simple', { params })
      .then(({ data }) => {
        data.data.map((item) => {
          item.selected = false;
        })
        setList(data.data.filter((item) => item.greenery.name.toLowerCase().includes(query.toLowerCase())));
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [searchFields.query.value])

  return (
    <S.PlantingLots>
      <ListPage {...listPageObject} />
    </S.PlantingLots>
  );
};
