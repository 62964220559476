import React, { useEffect, useState } from 'react';
import Field from '../../Components/Field';
import DownloadIcon from '../../images/DownloadIcon';
import TrashIcon from '../../images/TrashIcon';
import HistoryIcon from '../../images/HistoryIcon';
import ImageModal from '../../Components/ImageModal';
import ItemPage from '../../Components/ItemPage';
import CampNotebookIcon from '../../images/NotebookIcon';
import * as constants from './CampNotebook.constants';
import * as S from './CampNotebook.style';
import http from '../../service'
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from '../../images/SearchIcon';

export default function CampNotebook({ annotation, setFields, pageLabel, gridAddTemplate, addFormFields, setModal, i }) {
  const [editable, setEditable] = useState(false);

  const removeHandler = () => {
    setFields((oldFields) => {
      const newFields = { ...oldFields };
      newFields.annotations.value.splice(i, 1);
      return newFields;
    });
  }

  const editHandler = () => {
    setEditable(!editable)
  }

  const downloadHandler = (filename) => {

    http.get(`/uploader/` + filename)
      .then(({ data }) => {
        const linkSource = `data:application/octet-stream;base64,${data.data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = filename;
        downloadLink.click();
      })
      .catch((err) => console.log(err.message))
  }


  const viewImageHandler = (filename) => {

    http.get(`/uploader/` + filename)
      .then(({ data }) => {

        let image = `data:application/octet-stream;base64,${data.data}`;

        const img = new Image();

        img.onload = function () {

          let width = 0;
          let height = 0;

          if (this.width < 400 && this.height < 400) {
            width = 'auto';
            height = 'auto';
          }
          else if (this.width > this.height) {
            width = 'auto';
            height = '100%';
          }
          else {
            height = 'auto';
            width = '100%';
          }

          setModal({ width: '800px', height: '450px', transform: '30vh', isOpen: true, component: () => <ImageModal image={image} setModal={setModal} height={height} width={width} /> });
        }

        img.src = image;
      })
      .catch((err) => console.log(err.message))
  }

  return (
    <>
      <S.CampNotebookAnnotationsTitle addField={annotation.type}>
        {pageLabel[annotation.type]}
      </S.CampNotebookAnnotationsTitle>
      <S.CampNotebookAnnotations>
        <S.CampNotebookAnnotationsFields addField={annotation.type} gridTemplate={gridAddTemplate[annotation.type]} disabled>
          {Object
            .values(addFormFields[annotation.type])
            .filter((field) => field.type !== 'button')
            .map((field) => (
              <Field
                key={field.name}
                {...field}
                value={annotation[field.name]?.value}
                id={field.name + "_" + annotation["idObject"]?.value}
                disabled={!editable}
                setFields={setFields}
                onChange={({ target }) => {
                  setFields((oldFields) => {
                    const newFields = { ...oldFields };

                    if (target.name === 'images') {
                      let fileName = '';
                      var arquivo = document.getElementById("images_" + newFields.annotations.value[i]['idObject']?.value);

                      if (arquivo.files[0]) {
                        fileName = arquivo.files[0]['name'];

                        if (fileName.indexOf('.gif', fileName.length - '.gif'.length) === -1
                          && fileName.indexOf('.jpg', fileName.length - '.jpg'.length) === -1
                          && fileName.indexOf('.jpeg', fileName.length - '.jpeg'.length) === -1
                          && fileName.indexOf('.png', fileName.length - '.png'.length) === -1
                        ) {
                          fileName = '';
                        }
                        else {
                          fileName = uuidv4() + '-' + fileName;

                          const uploadFile = file => new Promise(() => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              http.post('uploader', { file: reader.result, originalname: fileName })
                                .then(({ data }) => {

                                })
                                .catch((err) => console.log(err));
                            };
                            reader.onerror = error => console.log(error);
                          });

                          uploadFile(arquivo.files[0]);
                        }
                      }
                      else {
                        fileName = '';
                      }

                      newFields.annotations.value[i]['filename'].value = fileName;

                    }
                    else {
                      newFields.annotations.value[i][target.name].value = target.value;
                    }
                    return newFields;
                  })
                }}
              />
            ))
          }
        </S.CampNotebookAnnotationsFields>
        <S.CampNotebookAnnotationsIcons>
          <S.CampNotebookAnnotationsIcon display={annotation.filename?.value?.length > 10 ? 'flex' : 'none'} tooltip="Visualizar Imagem" onClick={() => viewImageHandler(annotation.filename?.value)}>
            <SearchIcon />
          </S.CampNotebookAnnotationsIcon>
          <S.CampNotebookAnnotationsIcon display={annotation.filename?.value?.length > 10 ? 'flex' : 'none'} tooltip="Baixar Imagem" onClick={() => downloadHandler(annotation.filename?.value)}>
            <DownloadIcon />
          </S.CampNotebookAnnotationsIcon>
          <S.CampNotebookAnnotationsIcon display={annotation.applicatedAt ? 'none' : 'flex'} tooltip={editable ? 'Salvar' : 'Editar'} onClick={() => editHandler(i)}>
            <HistoryIcon />
          </S.CampNotebookAnnotationsIcon>
          <S.CampNotebookAnnotationsIcon display={annotation.applicatedAt ? 'none' : 'flex'} tooltip="Excluir" onClick={() => removeHandler()}>
            <TrashIcon />
          </S.CampNotebookAnnotationsIcon>
        </S.CampNotebookAnnotationsIcons>
      </S.CampNotebookAnnotations>
    </>
  );
};
