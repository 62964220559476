import React from 'react';
import BreadCrumb from '../../Components/BreadCrumb';
import GreeneryChart from '../../Components/GreeneryChart';
import UnitsTable from '../../Components/UnitsTable';
import * as S from './IndicatorsDetails.style';



const IndicatorsDetails = (indicatorsProps) => {
  const { color, label, shortLabel, data, icon: Icon, tableItems, percentualShortLabel } = indicatorsProps

  const [centerData, setCenterData] = React.useState(null); // the lifted state
  const [centerTableItems, setCenterTableItems] = React.useState(null); // the lifted state

  const sendDataToParent = (data) => {
    if (data?.length) {
      setCenterTableItems([
        { label: data[0].title, name: 'label' },
        { label: 'Capacidade', name: 'capacity' },
        { label: 'Ocupação', name: 'actualQuantity' },
        { label: 'Percentual de Ocupação', name: 'actualPercentage' },
      ]);
    }

    setCenterData(data);
  };

  return (
    <S.IndicatorsDetails>
      <BreadCrumb {...indicatorsProps} />
      <S.IndicatorsDetailsTitle color={color}>
        <S.IndicatorsDetailsTitleValue color={color}>{label}</S.IndicatorsDetailsTitleValue>
        <hr />
        <Icon />
      </S.IndicatorsDetailsTitle>
      { data[0]?.percentualPlan
        ? <S.IndicatorsDetailsCaption color={color}>{`Percentual de hortaliças em: ${label}`}</S.IndicatorsDetailsCaption>
        : <S.IndicatorsDetailsCaption color={color}>{`Percentual de ${label}`}</S.IndicatorsDetailsCaption>
      }
      <GreeneryChart sendDataToParent={sendDataToParent} data={data} color={color} shortLabel={shortLabel} percentualShortLabel={percentualShortLabel} />
      { centerData
        ? <UnitsTable data={centerData} color={color} tableItems={centerTableItems} />
        : null
      }
      <UnitsTable data={data} color={color} tableItems={tableItems} />
    </S.IndicatorsDetails>
  );

};

export default IndicatorsDetails;
