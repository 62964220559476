import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import GreenHouseIcon from '../../images/GreenHouseIcon';
import * as S from './Greenhouses.style';
import * as constants from './Greenhouses.constants';

export default function Greenhouses ({ setModal }) {
  const { greenhousesSearchFieldsState, greenhousesSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(greenhousesSearchFieldsState);
  const searchFields = greenhousesSearchFields({fields});
  const url = 'greenhouse'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: GreenHouseIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Greenhouses>
      <ListPage {...listPageObject} />
    </S.Greenhouses>
  );
};
