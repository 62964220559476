import React from 'react';
import { inputChange, inputValidate } from '../../helpers/fieldFunctions';
import * as S from './Input.style';

export default function InputText({ onChange, setFields, type, i, parentI, validate, onBlur, ...props }) {
  const { name } = props;
  return (
    <S.InputNumber
      {...props}
      onFocus={({target}) => target.select()}
      onValueChange={({value}) => inputChange({ target: { value, i, parentI, name }, setFields, onChange })}
      onBlur={({ target }) => inputValidate({ target: { ...target, i, parentI, name }, setFields, validate, onBlur })}
    />
  );
}
