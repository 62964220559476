import React, { useEffect, useState } from 'react';
import ListPage from '../../Components/ListPage';
import http from '../../service'
import * as constants from './CampNotebooks.constants';
import * as S from './CampNotebooks.style';
import { subDays, addDays } from 'date-fns';

export default function CampNotebook({ setModal }) {
  const { campNotebookSearchFieldsState, campNotebookSearchFields, pageLabel: { icon } } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(campNotebookSearchFieldsState());
  const searchFields = campNotebookSearchFields({ fields, setList, setModal });
  const url = 'fieldnote'

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon, setList, setFields,
  }

  useEffect(() => {
    const greenhouse = searchFields.greenhouse.value;

    const params = {
      endDate: addDays(new Date(), 30),
      status: 'Ativo',
    }

    http.get(url, { params })
      .then(({ data }) => setList(data.data.filter((item) => item.greenhouse.name.toLowerCase().includes(greenhouse.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.greenhouse.value])

  return (
    <S.CampNotebooks>
      <ListPage {...listPageObject} />
    </S.CampNotebooks>
  );
};
