import React from "react";

export default function WaterBoxIcon() {
  return (
    <svg width="90" height="92" viewBox="0 0 90 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M44.47 29.55C18.56 29.55 1.19 20.24 0.93 20.1C0.36 19.79 0 19.18 0 18.53C0 17.88 0.36 17.27 0.93 16.96L29.46 1.36C29.68 1.24 29.93 1.16 30.19 1.14L45.41 0L60.9 1.14C61.17 1.16 61.42 1.24 61.65 1.37L89.04 16.97C89.63 17.31 89.98 17.95 89.94 18.63C89.9 19.31 89.49 19.91 88.86 20.18C72.64 27.21 57.47 29.55 44.47 29.55ZM5.75 18.41C15.38 22.55 46.44 33.2 84.08 18.28L60.23 4.69L45.41 3.58L30.85 4.69L5.75 18.41Z" fill="#3BBC7B"/>
      <path d="M64.77 50.06C63.89 50.06 63.11 49.41 63 48.5C62.87 47.52 63.56 46.62 64.54 46.49C70.61 45.68 76.53 44.46 82.15 42.86C83.1 42.59 84.09 43.14 84.37 44.09C84.64 45.04 84.09 46.03 83.14 46.31C77.36 47.96 71.26 49.21 65.02 50.04C64.93 50.06 64.85 50.06 64.77 50.06Z" fill="#3BBC7B"/>
      <path d="M25.89 50.01C25.81 50.01 25.74 50.01 25.66 50C14.25 48.55 7.09 46.4 6.79 46.31C6.09 46.1 5.58 45.48 5.52 44.74L3.49 20.33C3.41 19.34 4.14 18.48 5.13 18.39C6.12 18.24 6.98 19.04 7.07 20.03L8.99 43.2C11.44 43.85 17.62 45.35 26.12 46.44C27.1 46.57 27.8 47.46 27.67 48.45C27.56 49.35 26.78 50.01 25.89 50.01Z" fill="#3BBC7B"/>
      <path d="M45.37 74.95C26.68 74.95 13.28 70.54 12.45 70.26C11.8 70.04 11.34 69.48 11.25 68.8L8.16002 45.91C8.03002 44.93 8.72002 44.02 9.70002 43.89C10.68 43.75 11.58 44.45 11.72 45.43L14.65 67.18C20.25 68.81 47.33 75.77 76.42 66.84C77.36 66.53 78.37 67.08 78.66 68.03C78.95 68.98 78.42 69.98 77.47 70.27C66.02 73.78 54.99 74.95 45.37 74.95Z" fill="#3BBC7B"/>
      <path d="M44.25 91.77C36.19 91.77 27.07 90.77 17.79 87.77C17.16 87.57 16.7 87.03 16.58 86.38L13.54 69.64C13.36 68.67 14.01 67.73 14.98 67.56C15.97 67.37 16.89 68.03 17.07 69L19.92 84.68C45.67 92.6 70.79 84.44 71.04 84.36C71.98 84.05 72.99 84.55 73.31 85.5C73.62 86.44 73.11 87.46 72.18 87.77C71.45 88 59.87 91.77 44.25 91.77Z" fill="#3BBC7B"/>
      <path d="M82.51 46.38C82.46 46.38 82.41 46.38 82.36 46.37C81.37 46.29 80.64 45.42 80.72 44.43L82.75 20.02C82.83 19.03 83.68 18.23 84.69 18.38C85.68 18.46 86.41 19.33 86.33 20.32L84.3 44.74C84.22 45.68 83.44 46.38 82.51 46.38Z" fill="#3BBC7B"/>
      <path d="M76.81 70.35C76.73 70.35 76.65 70.35 76.57 70.34C75.59 70.21 74.9 69.3 75.03 68.32L78.11 45.43C78.24 44.45 79.15 43.76 80.13 43.89C81.11 44.02 81.8 44.93 81.67 45.91L78.58 68.8C78.46 69.7 77.69 70.35 76.81 70.35Z" fill="#3BBC7B"/>
      <path d="M71.48 87.86C71.37 87.86 71.26 87.85 71.14 87.83C70.17 87.65 69.53 86.71 69.71 85.73L72.88 68.99C73.06 68.02 73.99 67.38 74.98 67.56C75.95 67.74 76.59 68.68 76.41 69.66L73.24 86.4C73.08 87.25 72.33 87.86 71.48 87.86Z" fill="#3BBC7B"/>
      <path d="M45.35 58.94C40.38 58.94 36.33 54.64 36.33 49.35C36.33 44.56 43.16 35.73 43.94 34.74C44.62 33.88 46.08 33.88 46.76 34.74C47.54 35.73 54.37 44.57 54.37 49.35C54.38 54.63 50.33 58.94 45.35 58.94ZM45.35 38.83C42.84 42.31 39.92 47.11 39.92 49.34C39.92 52.65 42.36 55.35 45.35 55.35C48.35 55.35 50.79 52.66 50.79 49.34C50.79 47.1 47.86 42.31 45.35 38.83Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="89.95" height="91.77" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
