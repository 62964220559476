import { css } from 'styled-components';
import { validateCpfCnpj } from '../../utils/validateCpfCnpj';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'client',
  singular: `cliente`,
  plural: `clientes`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const clientFieldsState = () => ({
  name: { value: '' },
  fantasyName: { value: '' },
  razaoSocial: { value: '' },
  cpfCnpj: { value: '', mask: '999.999.999-99' },
  commercialAccount: { value: '' },
  phoneNumber: { value: '', mask: '99 9999-9999' },
  cep: { value: '' },
  logradouro: { value: '' },
  numeroResidencia: { value: '' },
  complemento: { value: '' },
  bairro: { value: '' },
  //icon: { value: '', type: 'file' },
  //google_maps_cod: { value: '' },
});

export const clientFields = ({ fields }) => ({
  name:  { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
  //  validate: (value) => ({ empty: !value }),
  },
  fantasyName:  { 
    ...fields.fantasyName,
    name: 'fantasyName',
    label: 'Nome fantasia',
    placeholder: 'Digite',
  //  validate: (value) => ({ empty: !value }),
  },
  razaoSocial:  { 
    ...fields.razaoSocial,
    name: 'razaoSocial',
    label: 'Razão Social',
    placeholder: 'Digite',
    validate: (value) => ({ empty: !value }),
  },
  cpfCnpj:  { 
    ...fields.cpfCnpj,
    name: 'cpfCnpj',
    label: 'CNPJ / CPF',
    placeholder: 'Digite',
    mask: fields.cpfCnpj?.mask,
    onChange: ({ target, setFields }) => {
      const { name, value } = target;
      setFields((oldFields) => {
        oldFields[name].error = false;
        oldFields[name].errorMessage = '';
        oldFields[name].value = value;
        oldFields[name].mask = value.length > 14 ? '99.999.999/9999-99' : '999.999.999-999';
        return ({ ...oldFields });
      });
    },
    validate: (value) => ({
      empty: !value,
      invalid: !validateCpfCnpj(value),
      invalidMessage: 'Por favor coloque um documento válido'
    }),
  },
  commercialAccount:  { 
    ...fields.commercialAccount,
    name: 'commercialAccount',
    label: 'Contato comercial',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
  },
  phoneNumber:  { 
    ...fields.phoneNumber,
    name: 'phoneNumber',
    label: 'Telefone comercial',
    placeholder: 'Digite',
    validate: (value) => ({
      //empty: !value,
      invalid: !(value?.length === 12),
      invalidMessage: 'Por favor coloque um telefone válido'
    }),
  },
  cep:  { 
    ...fields.cep,
    name: 'cep',
    label: 'CEP',
    placeholder: 'Digite',
    mask: '99999-999',
    validate: (value) => ({
    //  empty: !value,
      invalid: !(value.length === 9),
      invalidMessage: 'Por favor coloque um CEP válido'
    }),
  },
  logradouro:  { 
    ...fields.logradouro,
    name: 'logradouro',
    label: 'Endereço',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
  },
  numeroResidencia:  { 
    ...fields.numeroResidencia,
    name: 'numeroResidencia',
    label: 'Número',
    placeholder: 'Digite',
    type: 'number',
    //validate: (value) => ({ empty: !value }),
  },
  complemento:  { 
    ...fields.complemento,
    name: 'complemento',
    label: 'Complemento',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
  },
  bairro:  { 
    ...fields.bairro,
    name: 'bairro',
    label: 'Bairro',
    placeholder: 'Digite',
    //validate: (value) => ({ empty: !value }),
  },
  // icon:  { 
  //   ...fields.icon,
  //   name: 'icon',
  //   label: 'Icone',
  // },
  // google_maps_cod:  { 
  //   ...fields.google_maps_cod,
  //   name: 'google_maps_cod',
  //   label: 'Código do google map',
  //   placeholder: 'Digite',
  // },
});

export const gridTemplate = css`
  grid-template: ${`
    "name"
    "fantasyName"
    "razaoSocial"
    "cpfCnpj"
    "commercialAccount"
    "phoneNumber"
    "cep"
    "logradouro"
    "numeroResidencia"
    "complemento"
    "bairro" 
    "icon"
    "google_maps_cod"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      grid-template: ${`
        "name name name name name name"
        "fantasyName fantasyName fantasyName fantasyName fantasyName fantasyName"
        "razaoSocial razaoSocial razaoSocial razaoSocial cpfCnpj cpfCnpj"
        "logradouro logradouro logradouro logradouro numeroResidencia numeroResidencia"
        "complemento complemento bairro bairro cep cep"
        "technicalResponsable technicalResponsable technicalResponsable technicalResponsable technicalResponsable technicalResponsable"
        "phoneNumber phoneNumber phoneNumber commercialAccount commercialAccount commercialAccount"
        "icon icon icon google_maps_cod google_maps_cod google_maps_cod"
      `};
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
`
