import styled from 'styled-components';

export const ImageModal = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-template-areas: 
    "imageCall"
    "ImageDiv";
`

export const ImageDiv = styled.div`
  display: flex;
  justify-content:center;
  align-items:center; 
  position: absolute;
  bottom: 10px;
  top: 40px;
  right: 10px;
  left: 10px;  
  background: transparent;
  height: 400px;
  width: 780px;  
  
`

export const Image = styled.img`
  position: absolute;
  width:  ${({ width }) => width};
  height: ${({ height }) => height};
  object-fit: cover;
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenS } } }) => screenS}) {
      width:  ${({ width }) => width};
      height: ${({ height }) => height};
    }
  }

  `
export const ImageCall = styled.p`
  grid-area: imageCall;
`