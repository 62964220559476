import React, { useState, useEffect } from 'react';
import http from '../../service'
import IndicatorsDetails from '../../Components/IndicatorsDetails';
import FinalPhaseIcon from "../../images/FinalPhaseIcon";
import * as S from './FinalPhaseDetails.style';

const FinalPhaseDetails = ({ setModal }) => {
  const [data, setData] = useState();

  useEffect(() => {
    http.get(`lot/getLotDetailDashByGreenery?stage=PERFIL FINAL`)
      .then(({ data }) => setData(data.data.map((item) => ({
        ...item,
        label: item.greenery_name,
        actualPercentage: item.percentualPlanted,
        plannedPercentage: item.percentualPlan,
        percentualPlan: item.percentualPlan
      }))))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  const tableItems = [
    { label: 'Hortaliça', name: 'label' },
    { label: 'Plantado', name: 'actualQuantity' },
    { label: 'Percentual Plantado', name: 'actualPercentage' },
    { label: 'Percentual Planejado', name: 'percentualPlan' },
  ]

  const finalPhaseInfo = {
    tableItems,
    label: "fase final",
    shortLabel: "Hortaliça",
    units: 485,
    color: "#8bc53f",
    icon: FinalPhaseIcon,
    link: '/indicadores/fase-final',
    path: ['berçario'],
    data: data || []
  }
  return (
    <S.FinalPhaseDetails>
      <IndicatorsDetails {...finalPhaseInfo} />
    </S.FinalPhaseDetails>
  );
};

export default FinalPhaseDetails;
