import React from "react";

export default function PestIcon() {
  return (
    <svg width="107" height="114" viewBox="0 0 107 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M37.54 83.18C35.46 83.18 33.22 82.64 30.74 81.53C17.34 75.5 20.34 62.44 26.89 41.65C30.68 29.61 36.97 21.09 44.62 17.66C49.46 15.49 54.6 15.5 59.46 17.68C64.32 19.87 67.71 23.75 69.25 28.93C71.68 37.08 69.32 47.33 62.62 57.77C52.41 73.68 46.32 83.18 37.54 83.18ZM52 19.63C50.01 19.63 48.01 20.06 46.08 20.93C39.39 23.93 33.78 31.67 30.31 42.72C23.14 65.52 22.41 73.85 32.21 78.26C41.83 82.59 46.46 76.32 59.59 55.84C65.63 46.43 67.89 36.99 65.8 29.96C64.57 25.82 61.86 22.71 57.98 20.96C56.02 20.07 54.01 19.63 52 19.63ZM61.11 56.81H61.14H61.11Z" fill="#3BBC7B"/>
      <path d="M10.04 86.56C9.93 86.56 9.82 86.55 9.7 86.53C8.73 86.35 8.09 85.41 8.27 84.43L9.94 75.55C10.87 70.63 14.39 66.56 19.14 64.96L22.46 63.84C23.4 63.52 24.42 64.03 24.73 64.97C25.05 65.91 24.54 66.93 23.6 67.24L20.28 68.36C16.76 69.55 14.15 72.56 13.46 76.22L11.8 85.1C11.64 85.96 10.89 86.56 10.04 86.56Z" fill="#3BBC7B"/>
      <path d="M1.79002 70.16C1.52002 70.16 1.24002 70.1 0.980021 69.96C0.100021 69.51 -0.249979 68.43 0.190021 67.55L4.12002 59.81C6.24002 55.63 10.29 52.82 14.95 52.29L24.72 51.19C25.73 51.07 26.59 51.79 26.71 52.77C26.82 53.75 26.11 54.64 25.13 54.76L15.36 55.86C11.9 56.25 8.90002 58.33 7.33002 61.43L3.40002 69.17C3.08002 69.8 2.45002 70.16 1.79002 70.16Z" fill="#3BBC7B"/>
      <path d="M34.86 30.32C34.47 30.32 34.08 30.2 33.76 29.94L31.28 28.01C25.78 23.72 24.78 15.73 29.07 10.22L33.65 4.32C34.26 3.53 35.39 3.4 36.17 4C36.95 4.61 37.09 5.73 36.49 6.52L31.91 12.42C28.84 16.38 29.55 22.1 33.5 25.18L35.98 27.11C36.76 27.72 36.9 28.85 36.29 29.63C35.92 30.08 35.39 30.32 34.86 30.32Z" fill="#3BBC7B"/>
      <path d="M69.45 67.69C69.18 67.69 68.91 67.63 68.65 67.5C41.77 54.17 56.56 19.1 56.71 18.74C57.1 17.83 58.16 17.42 59.07 17.81C59.98 18.2 60.4 19.26 60.01 20.17C59.44 21.48 46.43 52.48 70.25 64.29C71.14 64.73 71.5 65.81 71.06 66.69C70.75 67.32 70.11 67.69 69.45 67.69Z" fill="#3BBC7B"/>
      <path d="M26.64 45.9C23.19 45.9 19.5 45.22 15.59 43.63C14.67 43.26 14.23 42.21 14.6 41.29C14.97 40.37 16.02 39.93 16.94 40.3C41.59 50.29 56.14 19.98 56.75 18.68C57.17 17.79 58.2401 17.4 59.1301 17.82C60.0301 18.24 60.4101 19.31 59.9901 20.2C59.8501 20.51 47.69 45.9 26.64 45.9Z" fill="#3BBC7B"/>
      <path d="M61.01 21.28C59.93 21.28 58.86 21.05 57.85 20.59C53.96 18.84 52.22 14.25 53.97 10.36C55.72 6.47 60.31 4.73 64.2 6.48C68.09 8.23 69.83 12.81 68.08 16.7C67.23 18.58 65.7 20.02 63.77 20.76C62.87 21.11 61.94 21.28 61.01 21.28ZM61.03 9.4C59.45 9.4 57.93 10.31 57.24 11.85C56.3 13.93 57.24 16.4 59.32 17.34C60.33 17.79 61.45 17.82 62.49 17.43C63.53 17.04 64.35 16.26 64.8 15.25C65.74 13.17 64.8 10.71 62.72 9.77C62.17 9.51 61.6 9.4 61.03 9.4Z" fill="#3BBC7B"/>
      <path d="M60.07 9.48C59.26 9.48 58.53 8.93 58.33 8.12L56.86 2.24C56.62 1.28 57.2 0.300001 58.16 0.0700007C59.13 -0.179999 60.09 0.410001 60.33 1.37L61.8 7.25C62.04 8.21 61.46 9.19 60.5 9.42C60.36 9.46 60.22 9.48 60.07 9.48Z" fill="#3BBC7B"/>
      <path d="M41.84 100.86C41.45 100.86 41.07 100.74 40.74 100.48C39.96 99.87 39.82 98.75 40.42 97.96L45.96 90.82C48.24 87.88 48.75 83.93 47.31 80.5L45.95 77.27C45.56 76.36 45.99 75.31 46.9 74.92C47.8 74.53 48.86 74.96 49.25 75.87L50.61 79.1C52.56 83.72 51.87 89.05 48.79 93.01L43.25 100.15C42.9 100.62 42.37 100.86 41.84 100.86Z" fill="#3BBC7B"/>
      <path d="M59.09 95.93C58.87 95.93 58.65 95.89 58.43 95.8C57.51 95.44 57.06 94.39 57.42 93.47L60.61 85.39C61.89 82.16 61.45 78.52 59.45 75.68L53.79 67.63C53.22 66.82 53.41 65.7 54.22 65.13C55.03 64.55 56.15 64.75 56.72 65.56L62.38 73.61C65.08 77.44 65.66 82.34 63.94 86.7L60.75 94.78C60.48 95.5 59.8 95.93 59.09 95.93Z" fill="#3BBC7B"/>
      <path d="M72.51 45.73C71.75 45.73 70.98 45.66 70.21 45.52L67.12 44.95C66.15 44.77 65.5 43.83 65.68 42.86C65.86 41.88 66.81 41.22 67.77 41.42L70.86 41.99C75.78 42.88 80.54 39.63 81.46 34.71L82.83 27.37C83.01 26.4 83.96 25.77 84.92 25.94C85.89 26.12 86.53 27.06 86.35 28.03L84.9801 35.37C83.8501 41.46 78.5 45.73 72.51 45.73Z" fill="#3BBC7B"/>
      <path d="M66.81 12.5C66.16 12.5 65.54 12.15 65.22 11.53C64.76 10.65 65.1 9.57 65.98 9.11L71.35 6.31C72.23 5.84 73.31 6.2 73.77 7.07C74.23 7.95 73.89 9.03 73.01 9.49L67.64 12.29C67.37 12.44 67.09 12.5 66.81 12.5Z" fill="#3BBC7B"/>
      <path d="M87.6601 67.71C87.1001 67.71 86.5901 67.67 86.0501 67.56C83.6101 67.16 81.3301 65.75 79.8601 63.7C79.8201 63.64 79.7801 63.58 79.7501 63.52C78.6501 61.88 78.0701 60.01 78.0701 58.1C78.0701 57.54 78.1101 57.02 78.2201 56.48C78.6201 54.03 80.0401 51.75 82.1001 50.28C84.2501 48.77 86.9601 48.17 89.3101 48.64C91.7601 49.04 94.0301 50.46 95.5001 52.52C96.6701 54.15 97.2901 56.08 97.2901 58.1C97.2901 58.66 97.2501 59.21 97.1401 59.73C96.7401 62.16 95.3301 64.44 93.2901 65.91C93.2301 65.95 93.1701 65.99 93.1001 66.03C91.4601 67.13 89.5901 67.71 87.6601 67.71ZM92.2101 64.46H92.2401H92.2101ZM82.8501 61.73C83.7801 62.95 85.1601 63.79 86.6701 64.04C88.4201 64.36 89.8401 63.93 91.1601 63C91.2001 62.97 91.2501 62.94 91.2901 62.92C92.5001 61.99 93.3401 60.61 93.5901 59.1C93.6601 58.74 93.6801 58.43 93.6801 58.12C93.6801 56.86 93.2901 55.65 92.5601 54.63C91.6401 53.34 90.2201 52.45 88.6501 52.19C86.9201 51.85 85.4901 52.3 84.1601 53.23C82.8801 54.14 82.0001 55.57 81.7401 57.13C81.6701 57.49 81.6501 57.79 81.6501 58.12C81.6501 59.35 82.0401 60.56 82.7901 61.63C82.8101 61.65 82.8301 61.69 82.8501 61.73Z" fill="#3BBC7B"/>
      <path d="M86.0601 69.53C85.9601 69.53 85.8601 69.52 85.7601 69.5C84.7801 69.33 84.1301 68.41 84.2901 67.43L84.6201 65.5C84.7901 64.53 85.7401 63.86 86.6901 64.03C87.6701 64.2 88.3201 65.12 88.1601 66.1L87.8301 68.03C87.6801 68.91 86.9201 69.53 86.0601 69.53Z" fill="#3BBC7B"/>
      <path d="M88.9401 52.2C88.8401 52.2 88.7401 52.19 88.6401 52.17C87.6601 52 87.0101 51.08 87.1701 50.1L87.5001 48.17C87.6701 47.19 88.6101 46.54 89.5701 46.7C90.5501 46.87 91.2001 47.79 91.0401 48.77L90.7101 50.7C90.5601 51.58 89.8001 52.2 88.9401 52.2Z" fill="#3BBC7B"/>
      <path d="M79.97 58.62C79.87 58.62 79.77 58.61 79.67 58.6L77.74 58.27C76.76 58.1 76.11 57.18 76.27 56.2C76.44 55.22 77.39 54.57 78.34 54.73L80.27 55.06C81.25 55.23 81.9 56.15 81.74 57.13C81.59 58 80.83 58.62 79.97 58.62Z" fill="#3BBC7B"/>
      <path d="M97.29 61.5C97.19 61.5 97.09 61.49 96.99 61.48L95.06 61.15C94.08 60.98 93.43 60.06 93.59 59.08C93.76 58.11 94.7001 57.44 95.6601 57.61L97.59 57.94C98.57 58.11 99.22 59.03 99.06 60.01C98.91 60.88 98.15 61.5 97.29 61.5Z" fill="#3BBC7B"/>
      <path d="M79.72 65.56C79.15 65.56 78.6 65.29 78.25 64.8C77.68 63.99 77.88 62.87 78.69 62.3L80.28 61.18C81.1 60.61 82.22 60.81 82.78 61.62C83.35 62.43 83.15 63.55 82.34 64.12L80.75 65.24C80.43 65.46 80.07 65.56 79.72 65.56Z" fill="#3BBC7B"/>
      <path d="M94.0201 55.36C93.4601 55.36 92.9001 55.1 92.5501 54.61C91.9801 53.8 92.1601 52.68 92.9701 52.11L94.5601 50.98C95.3701 50.41 96.4901 50.59 97.0601 51.4C97.6301 52.21 97.4501 53.33 96.6401 53.9L95.0501 55.03C94.7401 55.25 94.3801 55.36 94.0201 55.36Z" fill="#3BBC7B"/>
      <path d="M93.33 67.84C92.76 67.84 92.21 67.57 91.86 67.08L90.75 65.49C90.18 64.68 90.38 63.56 91.19 62.99C92 62.42 93.12 62.62 93.69 63.43L94.8 65.02C95.37 65.83 95.17 66.95 94.36 67.52C94.04 67.73 93.68 67.84 93.33 67.84Z" fill="#3BBC7B"/>
      <path d="M83.13 53.54C82.57 53.54 82.0101 53.28 81.6601 52.79L80.53 51.2C79.96 50.39 80.14 49.27 80.95 48.7C81.76 48.13 82.88 48.32 83.45 49.12L84.5801 50.71C85.1501 51.52 84.9701 52.64 84.1601 53.21C83.8501 53.44 83.49 53.54 83.13 53.54Z" fill="#3BBC7B"/>
      <path d="M87.45 110.24C86.49 110.24 85.64 110.16 84.78 110C80.66 109.32 76.86 106.97 74.4 103.55C74.34 103.47 74.29 103.39 74.25 103.31C72.38 100.55 71.4 97.4 71.4 94.19C71.4 93.23 71.48 92.39 71.64 91.52C72.32 87.38 74.69 83.58 78.13 81.13C80.91 79.17 84.13 78.14 87.45 78.14C88.44 78.14 89.32 78.22 90.14 78.39C94.26 79.07 98.06 81.43 100.51 84.87C102.47 87.6 103.5 90.82 103.5 94.19C103.5 95.19 103.42 96.06 103.25 96.88C102.57 100.98 100.22 104.79 96.8 107.25C96.73 107.3 96.65 107.35 96.57 107.39C93.86 109.25 90.71 110.24 87.45 110.24ZM77.43 101.62C79.34 104.18 82.24 105.94 85.41 106.47C88.51 107.05 91.94 106.28 94.69 104.32C94.75 104.28 94.82 104.24 94.88 104.2C97.44 102.29 99.2 99.39 99.73 96.22C99.86 95.58 99.92 94.93 99.92 94.18C99.92 91.57 99.11 89.07 97.6 86.95C95.69 84.28 92.74 82.44 89.5 81.91C88.86 81.78 88.21 81.72 87.46 81.72C84.88 81.72 82.38 82.53 80.21 84.05C77.55 85.94 75.71 88.9 75.18 92.14C75.05 92.82 74.99 93.45 74.99 94.18C74.99 96.73 75.8 99.24 77.33 101.44C77.36 101.5 77.4 101.55 77.43 101.62ZM95.76 105.78H95.79H95.76Z" fill="#3BBC7B"/>
      <path d="M84.5301 113.56C84.4301 113.56 84.3301 113.55 84.2301 113.53C83.2601 113.36 82.6001 112.44 82.7601 111.46L83.3601 107.93C83.5301 106.95 84.4401 106.3 85.4301 106.46C86.4001 106.63 87.0601 107.55 86.9001 108.53L86.3001 112.06C86.1401 112.94 85.3801 113.56 84.5301 113.56Z" fill="#3BBC7B"/>
      <path d="M89.79 81.93C89.69 81.93 89.59 81.92 89.49 81.91C88.52 81.74 87.86 80.82 88.02 79.84L88.62 76.31C88.79 75.34 89.71 74.68 90.69 74.84C91.67 75.01 92.32 75.93 92.16 76.91L91.56 80.44C91.4 81.31 90.64 81.93 89.79 81.93Z" fill="#3BBC7B"/>
      <path d="M73.41 93.65C73.31 93.65 73.21 93.64 73.11 93.63L69.58 93.03C68.61 92.86 67.95 91.94 68.11 90.96C68.28 89.99 69.19 89.33 70.18 89.49L73.71 90.09C74.68 90.26 75.34 91.18 75.18 92.16C75.02 93.03 74.26 93.65 73.41 93.65Z" fill="#3BBC7B"/>
      <path d="M105.03 98.91C104.93 98.91 104.83 98.9 104.73 98.89L101.2 98.29C100.23 98.12 99.57 97.2 99.73 96.22C99.9 95.25 100.81 94.59 101.8 94.75L105.33 95.35C106.3 95.52 106.96 96.44 106.8 97.42C106.65 98.29 105.89 98.91 105.03 98.91Z" fill="#3BBC7B"/>
      <path d="M72.96 106.32C72.39 106.32 71.84 106.05 71.49 105.56C70.92 104.75 71.12 103.63 71.93 103.06L74.83 101.03C75.65 100.46 76.76 100.66 77.33 101.47C77.9 102.28 77.7 103.4 76.89 103.97L73.99 106C73.67 106.21 73.31 106.32 72.96 106.32Z" fill="#3BBC7B"/>
      <path d="M99.05 87.7C98.49 87.7 97.93 87.44 97.58 86.95C97.01 86.14 97.19 85.02 98 84.45L100.9 82.39C101.71 81.81 102.83 82 103.4 82.81C103.98 83.62 103.79 84.74 102.98 85.31L100.08 87.37C99.78 87.59 99.41 87.7 99.05 87.7Z" fill="#3BBC7B"/>
      <path d="M97.8001 110.48C97.2301 110.48 96.6801 110.21 96.3301 109.72L94.3001 106.82C93.7301 106.01 93.9301 104.89 94.7401 104.32C95.5601 103.75 96.6701 103.95 97.2401 104.76L99.2701 107.66C99.8401 108.47 99.6401 109.59 98.8301 110.16C98.5101 110.37 98.1501 110.48 97.8001 110.48Z" fill="#3BBC7B"/>
      <path d="M79.17 84.38C78.61 84.38 78.05 84.12 77.7 83.63L75.64 80.73C75.07 79.92 75.25 78.8 76.06 78.23C76.87 77.65 77.99 77.84 78.56 78.65L80.62 81.55C81.19 82.36 81.01 83.48 80.2 84.05C79.9 84.27 79.53 84.38 79.17 84.38Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="106.82" height="113.56" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
