import React from 'react';

export default function PlantingLotsIcon () {
  return (
    <svg width="62" height="81" viewBox="0 0 62 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M23.45 37.48C22.74 37.48 22.16 36.9 22.16 36.19V12.26C22.16 11.55 22.74 10.97 23.45 10.97C24.16 10.97 24.74 11.55 24.74 12.26V36.18C24.74 36.9 24.16 37.48 23.45 37.48Z" fill="#8BC53F"/>
        <path d="M60.4 80.14H1.29C0.58 80.14 0 79.56 0 78.85V49.41C0 48.7 0.58 48.12 1.29 48.12H29.48C30.19 48.12 30.77 48.7 30.77 49.41C30.77 50.12 30.19 50.7 29.48 50.7H2.59V77.56H59.11V57.04C59.11 56.33 59.69 55.75 60.4 55.75C61.11 55.75 61.69 56.33 61.69 57.04V78.85C61.69 79.56 61.11 80.14 60.4 80.14Z" fill="#8BC53F"/>
        <path d="M37.67 80.03C36.96 80.03 36.38 79.45 36.38 78.74V54.82C36.38 54.11 36.96 53.53 37.67 53.53C38.38 53.53 38.96 54.11 38.96 54.82V78.74C38.97 79.45 38.39 80.03 37.67 80.03Z" fill="#8BC53F"/>
        <path d="M37.58 68.57C37.49 68.57 37.39 68.56 37.3 68.54L27.63 66.41C27.62 66.41 27.62 66.41 27.61 66.41C24.38 65.65 21.86 63.98 20.51 61.69C19.32 59.66 19.08 57.19 19.84 54.55C20.03 53.88 20.71 53.49 21.38 53.65L29.21 55.51C30.52 55.77 31.7 56.12 32.72 56.54C33.77 56.97 34.78 57.57 35.74 58.32C36.78 59.12 37.59 60.16 38.15 61.4C38.71 62.62 38.99 64.03 38.99 65.6C38.99 66.24 38.94 66.88 38.85 67.48C38.8 67.83 38.6 68.15 38.3 68.35C38.09 68.49 37.83 68.57 37.58 68.57ZM28.19 63.89L36.41 65.7C36.41 65.67 36.41 65.63 36.41 65.6C36.41 64.4 36.21 63.35 35.81 62.48C35.41 61.61 34.87 60.92 34.16 60.37C33.38 59.77 32.57 59.29 31.73 58.94C30.87 58.58 29.84 58.28 28.69 58.05C28.67 58.05 28.66 58.04 28.64 58.04L22.07 56.47C21.86 57.94 22.08 59.25 22.75 60.38C23.72 62.05 25.66 63.29 28.19 63.89Z" fill="#8BC53F"/>
        <path d="M37.71 56.84C37.43 56.84 37.16 56.75 36.94 56.58C36.65 56.37 36.47 56.04 36.43 55.69C36.36 55.09 36.34 54.45 36.37 53.81C36.43 52.24 36.77 50.84 37.37 49.65C37.98 48.44 38.84 47.43 39.9 46.67C40.89 45.96 41.93 45.41 42.99 45.01C44.02 44.63 45.21 44.33 46.53 44.12L54.43 42.57C55.11 42.44 55.77 42.86 55.94 43.53C56.59 46.2 56.26 48.66 54.99 50.64C53.56 52.87 50.97 54.45 47.71 55.07C47.7 55.07 47.7 55.07 47.69 55.07L37.94 56.81C37.86 56.84 37.79 56.84 37.71 56.84ZM53.64 45.37L47.01 46.67C46.99 46.67 46.98 46.68 46.96 46.68C45.79 46.87 44.76 47.12 43.88 47.45C43.03 47.76 42.2 48.21 41.4 48.78C40.67 49.3 40.1 49.97 39.67 50.82C39.23 51.68 38.99 52.72 38.94 53.92C38.94 53.95 38.94 53.99 38.94 54.02L47.22 52.54C49.77 52.05 51.76 50.88 52.8 49.25C53.51 48.15 53.79 46.85 53.64 45.37Z" fill="#8BC53F"/>
        <path d="M34.66 37.59H1.29C0.58 37.59 0 37.01 0 36.3V6.85001C0 6.14001 0.58 5.56001 1.29 5.56001H11.38C12.09 5.56001 12.67 6.14001 12.67 6.85001C12.67 7.56001 12.09 8.14001 11.38 8.14001H2.59V35H34.66C35.37 35 35.95 35.58 35.95 36.29C35.95 37 35.38 37.59 34.66 37.59Z" fill="#8BC53F"/>
        <path d="M23.35 26.02C23.26 26.02 23.16 26.01 23.07 25.99L13.4 23.86C13.39 23.86 13.39 23.86 13.38 23.86C10.15 23.1 7.63 21.43 6.28 19.14C5.09 17.11 4.85 14.64 5.61 12C5.8 11.33 6.48 10.94 7.15 11.1L14.98 12.96C16.29 13.22 17.47 13.57 18.49 13.99C19.54 14.42 20.55 15.02 21.51 15.77C22.55 16.57 23.36 17.61 23.92 18.85C24.48 20.07 24.76 21.48 24.76 23.05C24.76 23.69 24.71 24.33 24.62 24.93C24.57 25.28 24.37 25.6 24.07 25.8C23.86 25.94 23.61 26.02 23.35 26.02ZM13.96 21.34L22.18 23.15C22.18 23.12 22.18 23.08 22.18 23.05C22.18 21.85 21.98 20.8 21.58 19.93C21.18 19.06 20.64 18.37 19.93 17.82C19.15 17.22 18.34 16.74 17.5 16.39C16.64 16.03 15.61 15.73 14.46 15.5C14.44 15.5 14.43 15.49 14.41 15.49L7.84 13.92C7.63 15.39 7.85 16.7 8.52 17.83C9.49 19.5 11.43 20.74 13.96 21.34Z" fill="#8BC53F"/>
        <path d="M23.49 14.29C23.21 14.29 22.94 14.2 22.72 14.03C22.43 13.82 22.25 13.49 22.21 13.14C22.14 12.54 22.12 11.9 22.15 11.26C22.21 9.69 22.55 8.29 23.15 7.1C23.76 5.89 24.62 4.88 25.68 4.12C26.67 3.41 27.71 2.86 28.77 2.46C29.8 2.08 30.99 1.78 32.31 1.57L40.21 0.0200033C40.89 -0.109997 41.55 0.310003 41.72 0.980003C42.37 3.65 42.04 6.11 40.77 8.09C39.34 10.32 36.75 11.9 33.49 12.52C33.48 12.52 33.48 12.52 33.47 12.52L23.72 14.26C23.64 14.29 23.56 14.29 23.49 14.29ZM39.41 2.82L32.78 4.12C32.76 4.12 32.75 4.13 32.73 4.13C31.56 4.32 30.53 4.57 29.65 4.9C28.8 5.21 27.97 5.66 27.17 6.23C26.44 6.75 25.87 7.42 25.44 8.27C25 9.13 24.76 10.17 24.71 11.37C24.71 11.4 24.71 11.44 24.71 11.47L33 9.99C35.55 9.5 37.54 8.33 38.58 6.7C39.29 5.6 39.57 4.3 39.41 2.82Z" fill="#8BC53F"/>
      </g>
    </svg>
  )
}
