import React from "react";

export default function FarmsIcon() {
  return (
    <svg width="123" height="110" viewBox="0 0 123 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M59.06 109.7C58.24 109.7 57.51 109.14 57.31 108.31C57.09 107.35 57.69 106.38 58.65 106.16L120.04 92C120.99 91.79 121.97 92.38 122.19 93.35C122.41 94.31 121.81 95.28 120.84 95.5L59.46 109.66C59.33 109.69 59.19 109.7 59.06 109.7Z" fill="#3BBC7B"/>
      <path d="M59.06 109.7C58.73 109.7 58.41 109.61 58.11 109.43L0.84002 73.56C1.9908e-05 73.04 -0.24998 71.93 0.27002 71.09C0.80002 70.25 1.91002 70 2.75002 70.52L60.02 106.38C60.86 106.91 61.11 108.01 60.59 108.85C60.24 109.4 59.66 109.7 59.06 109.7Z" fill="#3BBC7B"/>
      <path d="M76.54 103.13C76.21 103.13 75.89 103.04 75.59 102.86L18.33 67C17.81 66.67 17.49 66.1 17.49 65.48V37.21C17.49 36.22 18.29 35.42 19.28 35.42C20.27 35.42 21.07 36.22 21.07 37.21V64.49L77.49 99.82C78.33 100.35 78.58 101.45 78.06 102.29C77.72 102.83 77.14 103.13 76.54 103.13Z" fill="#3BBC7B"/>
      <path d="M111.18 41.87C110.82 41.87 110.45 41.76 110.13 41.53L58.45 3.99L15.03 33.99C14.21 34.55 13.1 34.35 12.54 33.53C11.98 32.72 12.18 31.6 13 31.04L57.45 0.320001C58.08 -0.109999 58.91 -0.0999985 59.52 0.340001L112.23 38.62C113.03 39.2 113.21 40.32 112.63 41.12C112.29 41.62 111.74 41.87 111.18 41.87Z" fill="#3BBC7B"/>
      <path d="M65.14 94.68C64.15 94.68 63.35 93.88 63.35 92.89V58.79C63.35 57.8 64.15 57 65.14 57C66.13 57 66.93 57.8 66.93 58.79V92.89C66.93 93.88 66.13 94.68 65.14 94.68Z" fill="#3BBC7B"/>
      <path d="M65 78.35C64.87 78.35 64.74 78.34 64.61 78.31L50.82 75.28C46.2 74.2 42.62 71.82 40.71 68.58C39.02 65.7 38.69 62.19 39.76 58.44C40.02 57.52 40.96 56.97 41.9 57.18L53.07 59.84C54.95 60.22 56.63 60.71 58.06 61.31C59.54 61.92 60.99 62.77 62.35 63.83C63.82 64.97 64.98 66.45 65.78 68.2C66.57 69.93 66.97 71.93 66.97 74.16C66.97 75.08 66.9 75.97 66.77 76.83C66.7 77.32 66.42 77.76 66.01 78.04C65.71 78.24 65.36 78.35 65 78.35ZM42.84 61.09C42.52 63.23 42.84 65.13 43.8 66.75C45.21 69.14 47.98 70.93 51.61 71.78L63.38 74.37C63.38 74.3 63.38 74.23 63.38 74.17C63.38 72.46 63.09 70.95 62.51 69.69C61.94 68.45 61.17 67.46 60.15 66.67C59.04 65.81 57.87 65.12 56.68 64.63C55.45 64.12 53.99 63.69 52.33 63.36C52.31 63.36 52.29 63.35 52.27 63.35L42.84 61.09Z" fill="#3BBC7B"/>
      <path d="M65.19 61.63C64.81 61.63 64.43 61.51 64.12 61.28C63.72 60.98 63.46 60.54 63.41 60.04C63.31 59.19 63.28 58.29 63.32 57.37C63.41 55.14 63.89 53.15 64.75 51.46C65.62 49.73 66.83 48.31 68.35 47.23C69.75 46.23 71.23 45.43 72.74 44.88C74.2 44.34 75.91 43.91 77.81 43.6L89.04 41.39C89.98 41.21 90.9 41.79 91.13 42.73C92.05 46.52 91.58 50.02 89.78 52.82C87.74 55.99 84.07 58.23 79.43 59.12L65.51 61.6C65.41 61.62 65.3 61.63 65.19 61.63ZM87.95 45.26L78.44 47.13C76.7 47.41 75.22 47.78 73.98 48.24C72.77 48.69 71.57 49.33 70.43 50.15C69.38 50.9 68.57 51.86 67.95 53.08C67.33 54.31 66.98 55.8 66.91 57.51C66.91 57.58 66.91 57.65 66.9 57.71L78.78 55.58C82.42 54.88 85.26 53.21 86.76 50.87C87.78 49.29 88.18 47.41 87.95 45.26Z" fill="#3BBC7B"/>
      <path d="M41.23 52.36C40.9 52.36 40.57 52.27 40.28 52.09L12.3 34.6C11.46 34.07 11.2 32.97 11.73 32.13C12.26 31.29 13.37 31.04 14.2 31.56L41.22 48.44L84.71 20.33C85.54 19.79 86.65 20.03 87.19 20.86C87.73 21.69 87.49 22.8 86.66 23.34L42.21 52.08C41.91 52.27 41.57 52.36 41.23 52.36Z" fill="#3BBC7B"/>
      <path d="M72.74 77.86C71.9 77.86 71.15 77.26 70.98 76.41C70.79 75.44 71.42 74.5 72.39 74.31L101.37 68.62V34.25C101.37 33.26 102.17 32.46 103.16 32.46C104.15 32.46 104.95 33.26 104.95 34.25V70.08C104.95 70.94 104.35 71.67 103.5 71.84L73.08 77.82C72.97 77.85 72.85 77.86 72.74 77.86Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="122.24" height="109.7" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
