import React from 'react';
import { inputChange } from '../../helpers/fieldFunctions';
import * as S from './Input.style';

export default function InputSwitch({ onChange, setFields, type, ...props }) {
  const { name, value } = props;

  return (
    <S.InputSwitch
      {...props}
      checked={value}
      onChange={({}) => {
        return (onChange
          ? onChange({ target: { value: !value, name }, setFields })
          : inputChange({ target: { value: !value, name }, setFields })
        )
      }}
    />
  );
}
