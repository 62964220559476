import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#8bc53f'

export const pageLabel = {
  name: 'activity',
  singular: 'Atividade',
  plural: 'Atividades',
};

export const activityFieldsState = () => ({
  activityType: { value: '' },
  activityId: { value: '' },
  code: { value: '' },
  greenery: { value: '' },
  currentCycle: { value: '' },
  nextCycle: { value: '' },
  greenHouse: { value: '' },
  sector: { value: '' },
  stand: { value: '' },
  initialAmount: { value: '' },
  newGreenHouse: { value: '' },
  newSector: { value: '' },
  newStand: { value: '' },
  newFrom: {value: new Date()} ,
  newAmount: { value: '' },
  splitLot: { value: false },
  comments: { value: '' },
  activityNextDate: { value: ''}
});

export const activityInfoFields = ({ fields }) => {
  const newFields = ({
    code: { 
      ...fields.code,
      name: 'code',
      label: 'Código',
      disabled: true,
    },
    greenery: { 
      ...fields.greenery,
      name: 'greenery',
      label: 'Hortaliça',
      disabled: true,
    },
    greenHouse: { 
      ...fields.greenHouse,
      name: 'greenHouse',
      label: 'Estufa',
      disabled: true,
    },
    currentCycle: { 
      ...fields.currentCycle,
      name: 'currentCycle',
      label: 'Ciclo atual',
      disabled: true,
    },
    sector: { 
      ...fields.sector,
      name: 'sector',
      label: 'Setor',
      disabled: true,
    },
    stand: { 
      ...fields.stand,
      name: 'stand',
      label: 'Banca',
      disabled: true,
    },
    initialAmount: { 
      ...fields.initialAmount,
      name: 'initialAmount',
      label: 'Quantidade atual',
      disabled: true,
    },
  });
  return newFields
};

export const gridInfoTemplate = css`
  grid-template: ${`
    "code" 
    "greenery" 
    "currentCycle"
    "greenHouse" 
    "sector"
    "stand"
    "initialAmount"
    "expectedAmount"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "code greenery currentCycle" 
        "greenHouse sector stand"
        "initialAmount . ."
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`

export const activityActionFields = ({ fields }) => {
  const newFields = ({
    newGreenHouse: { 
      ...fields.newGreenHouse,
      name: 'newGreenHouse',
      label: 'Estufa',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      isClearable: true,
      loadOptions: (query, callback, setFields) => {
        http.get('greenhouse')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
          .catch((err) => callback([]))
      },
      onChange: ({ target: { value, name, i }, setFields }) => {
        setFields((oldFields) => { 
          const newFields = { ...oldFields };
          newFields[name].value = value
          newFields[name].error = false
          newFields[name].errorMessage = ''
          newFields.newSector.value = '';
          newFields.newStand.value = '';
          return newFields
        })
      }
    },
    newAmount: { 
      ...fields.newAmount,
      name: 'newAmount',
      label: 'Quantidade',
      placeholder: 'Digite a quantidade',
      type: 'number',
      validate: (value) => ({
        empty: !value,
        invalid: Number(value) > Number(fields.initialAmount.value) || Number(value) < 1,
        invalidMessage: 'A nova quantidade não pode ser maior que a inicial'
      }),
    },
    newFrom: { 
      ...fields.newFrom,
      name: 'newFrom',
      label: 'Data de transferência',
      type: 'date',
      validate: (value) => ({ empty: !value }),
    },
    splitLot: { 
      ...fields.splitLot,
      name: 'splitLot',
      label: 'Dividir lote',
      type: 'switch',
    },
    comments: { 
      ...fields.comments,
      name: 'comments',
      label: 'Observações',
      placeholder: 'Insira sua observação',
      type: 'textarea',
    },
  });
  if(fields.newGreenHouse.value) {
      newFields.newSector = { 
        ...fields.newSector,
        name: 'newSector',
        label: 'Setor',
        placeholder: 'Selecione',
        options: fields.newGreenHouse.value?.sectors?.map((option) => ({ ...option, label: option.name, value: option.id })),
        type: 'select',
        loadEmpty: true,
        isSearchable: false,
        isClearable: true,
      }
  }
  if(!!fields.newSector.value && fields.newGreenHouse.value) {
    const options = []
    fields.newSector?.value.stand?.map((option) => options.push({ ...option, label: option.name, value: option.id }));
    newFields.newStand = { 
      ...fields.newStand,
      name: 'newStand',
      label: 'Banca',
      placeholder: 'Selecione',
      options,
      type: 'select',
      loadEmpty: true,
      isClearable: true,
      isSearchable: false,
    }
  }
  if (fields.nextCycle.value === 'COLHIDO') {
    delete newFields.newGreenHouse;
    delete newFields.newSector;
    delete newFields.newStand; 
    newFields.splitLot.label = 'Colheita finalizada'
    newFields.newFrom.label = 'Data de colheita'
  }
  if (fields.newAmount.value >= fields.initialAmount.value) {
    delete newFields.splitLot; 
  }

  const activityNextDateField = fields.splitLot.value === false && (fields.newAmount.value !== '' && fields.newAmount.value > 0 && fields.newAmount.value < fields.initialAmount.value);
  if(activityNextDateField && fields.nextCycle.value === 'COLHIDO'){
    newFields.activityNextDate = { 
      ...fields.activityNextDate,
      name: 'activityNextDate',
      label: 'Nova data da colheita',
      type: 'date',
      validate: (value) => ({ empty: !value,
                              invalid: value < newFields.newFrom.value,
                              invalidMessage: 'A data do resto da colheita tem que ser depois da data da colheita atual.'
                            }),
    }
  }

  return newFields
}

export const gridActionTemplate = css`
  grid-template: ${`
    "newGreenHouse" 
    "newSector"
    "newStand"
    "newAmount"
    "newFrom"
    "splitLot"
    "comments"
    "activityNextDate"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "newGreenHouse newSector newStand" 
        "newAmount newFrom splitLot"
        "activityNextDate . ."
        "comments comments comments"
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
