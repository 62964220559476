import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'users',
  singular: 'usuário',
  plural: 'usuários'
};

export const usersSearchFieldsState = () => ({
  query: { value: '' },
});

export const usersSearchFields = ({ fields }) => ({
  query: {
    ...fields.query,
    name: 'query',
    label: 'Nome do usuário',
    placeholder: 'Digite',
  }
});

export const cardInfos = [
  { fieldLabel: 'Nome do Usuário', fieldType: 'name' },
  { fieldLabel: 'Perfil', fieldType: 'roles' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD }) {
      grid-template:
        "query ."
      ;
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "name name"
    "roles roles"
    "history delete"
  ;
  grid-template-columns: 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "name roles history delete"
      ;
      grid-template-columns: 1fr 140px 40px 40px;
    }
  }
`
