import React from 'react';

export default function RegisterIcon () {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M64.65 80.14H1.42C0.64 80.14 0 79.51 0 78.72V1.42C0 0.64 0.64 0 1.42 0H64.65C65.43 0 66.07 0.64 66.07 1.42V22.59C66.07 23.37 65.43 24.01 64.65 24.01C63.87 24.01 63.23 23.37 63.23 22.59V2.84H2.84V77.3H63.23V57.55C63.23 56.77 63.87 56.13 64.65 56.13C65.43 56.13 66.07 56.77 66.07 57.55V78.72C66.07 79.51 65.44 80.14 64.65 80.14Z" fill="#16C4C4"/>
        <path d="M54.28 19.33H27.28C26.5 19.33 25.86 18.69 25.86 17.91C25.86 17.13 26.5 16.49 27.28 16.49H54.28C55.06 16.49 55.7 17.13 55.7 17.91C55.7 18.69 55.07 19.33 54.28 19.33Z" fill="#16C4C4"/>
        <path d="M44.0958 43H26.9042C26.4075 43 26 42.3239 26 41.5C26 40.6761 26.4075 40 26.9042 40H44.0958C44.5925 40 45 40.6761 45 41.5C45 42.3345 44.5989 43 44.0958 43Z" fill="#16C4C4"/>
        <path d="M32.6669 64H26.3331C26.1501 64 26 63.3239 26 62.5C26 61.6761 26.1501 61 26.3331 61H32.6669C32.8499 61 33 61.6761 33 62.5C33 63.3239 32.8522 64 32.6669 64Z" fill="#16C4C4"/>
        <path d="M20.04 24.01H10.52C9.74001 24.01 9.10001 23.37 9.10001 22.59V13.07C9.10001 12.29 9.74001 11.65 10.52 11.65H20.04C20.82 11.65 21.46 12.29 21.46 13.07V22.59C21.46 23.38 20.82 24.01 20.04 24.01ZM11.94 21.17H18.62V14.49H11.94V21.17V21.17Z" fill="#16C4C4"/>
        <path d="M20.04 47.74H10.52C9.74001 47.74 9.10001 47.1 9.10001 46.32V36.8C9.10001 36.02 9.74001 35.38 10.52 35.38H20.04C20.82 35.38 21.46 36.02 21.46 36.8V46.32C21.46 47.11 20.82 47.74 20.04 47.74ZM11.94 44.9H18.62V38.22H11.94V44.9V44.9Z" fill="#16C4C4"/>
        <path d="M20.04 68.49H10.52C9.74001 68.49 9.10001 67.85 9.10001 67.07V57.55C9.10001 56.77 9.74001 56.13 10.52 56.13H20.04C20.82 56.13 21.46 56.77 21.46 57.55V67.07C21.46 67.85 20.82 68.49 20.04 68.49ZM11.94 65.65H18.62V58.97H11.94V65.65V65.65Z" fill="#16C4C4"/>
        <path d="M68.7726 27.7548C69.3289 27.1984 69.9893 26.7571 70.7162 26.456C71.4431 26.155 72.2221 26 73.0089 26C73.7957 26 74.5747 26.155 75.3016 26.456C76.0285 26.7571 76.6889 27.1984 77.2453 27.7548C77.8016 28.3111 78.2429 28.9715 78.544 29.6984C78.845 30.4253 79 31.2043 79 31.9911C79 32.7779 78.845 33.5569 78.544 34.2838C78.2429 35.0107 77.8016 35.6711 77.2453 36.2274L48.6499 64.8227L37 68L40.1773 56.3501L68.7726 27.7548Z" />
      </g>
    </svg>
  )
}
