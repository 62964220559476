import React, { useEffect, useState } from 'react';
import ListPage from '../../Components/ListPage';
import MeasureIcon from '../../images/MeasureIcon';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import * as S from './Measures.style';
import * as constants from './Measures.constants';

export default function Measures () {
  const { measuresSearchFieldsState, measuresSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(measuresSearchFieldsState);
  const searchFields = measuresSearchFields({fields});
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: MeasureIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get('telemetry')
      .then(({ data }) => setList(data.data.filter((item) =>  item.watertank.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Measures>
      <ListPage {...listPageObject} />
    </S.Measures>
  );
};
