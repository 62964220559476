import React, { useEffect, useState } from 'react';
import ListPage from '../../Components/ListPage';
import ListIcon from '../../images/ListIcon';
import http from '../../service'
import * as S from './Activities.style';
import * as constants from './Activities.constants';
import { compareAsc } from 'date-fns';

export default function Activities({ setModal }) {
  const { activitiesSearchFieldsState, activitiesSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(activitiesSearchFieldsState);
  const searchFields = activitiesSearchFields({ fields, setFields });

  const reload = () => {
    const query = searchFields.query.value.value;
    http.get('tasks/tasksimple/?status1=OPEN&status2=REPLANNED&status3=IN_PROGRESS')
      .then(({ data }) => setList(data.data
        .filter((item) => {
          return query ? item.type === query : true
        })
        .sort((itemA, itemB) => compareAsc(new Date(itemA.plannedDate), new Date(itemB.plannedDate)))
        .map((item) => ({ ...item, activityType: item.type }))
      ))
      .catch((err) => setError(err))
  }

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, setModal, reload,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: ListIcon, setList, setFields
  }

  useEffect(() => {
    const query = searchFields.query.value.value;
    http.get('tasks/tasksimple/?status1=OPEN&status2=REPLANNED&status3=IN_PROGRESS')
      .then(({ data }) => setList(data.data
        .filter((item) => {
          return query ? item.type === query : true
        })
        .sort((itemA, itemB) => compareAsc(new Date(itemA.plannedDate), new Date(itemB.plannedDate)))
        .map((item) => ({ ...item, activityType: item.type }))
      ))
      .catch((err) => setError(err))
  }, [searchFields.query.value])




  return (
    <S.Activities>
      <ListPage {...listPageObject} />
    </S.Activities>
  );
};
