import styled from 'styled-components';
import { darken } from 'polished';

export const PlantingLots = styled.div`
  background: white;
  min-height: 100%;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      padding-top: 30px;
    }
  }
`


export const PlantingLotsHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      margin-bottom: 50px;
      padding: 10px 30px;
    }

    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      margin-bottom: 20px;
      padding: 10px 50px;
    }
  }
`;

export const PlantingLotsHeaderTitle = styled.h1`
  background: white;
  text-transform: capitalize;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: normal;

  >svg {
    height: 40px;
    width: 40px;
    padding: 6px;
    background: ${({theme: { color: { white }}}) => white};
    
    path {
      fill: ${({ color }) => color};
    }
  }

  strong {
    margin-left: 6px;
    font-weight: bolder;
  }
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      
      >svg {
        height: 60px;
        width: 60px;
        margin-right: 10px;
      }
    }
  }
`

export const LotAnnotationsTitle = styled.div`
  padding: 4px 20px;
  font-size: 18px;
  font-weight: lighter;
  background: ${({ addField, theme: { color: { brand, warning, danger } }}) => {
    switch (addField) {
      case 'deficiency':
      case 'pest': return danger;
      case 'maintenance': return warning;
      default: return brand;
    }
  }};
  color: ${({ addField, theme: { color: { white, black } }}) => {
    switch (addField) {
      case 'maintenance': return black;
      default: return white;
    }
  }};
  border-radius: 20px;
  position: relative;
  top: 15px;
  left: 45px;
  z-index: 1;
  display: inline-block;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      left: 85px;
    }
  }
`;

export const LotAnnotations = styled.div`
  display: flex;
  margin: 0 20px;
  align-items: center;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      margin: 0 60px;
    }
  }
`

export const LotAnnotationsFields = styled.div`
  ${({ gridTemplate }) => gridTemplate};
  position: relative;
  flex: 1;
  background: ${({ addField, theme: { color: { brand, warning, danger } }}) => {
    switch (addField) {
      case 'deficiency':
      case 'pest': return danger;
      case 'maintenance': return warning;
      default: return brand;
    }
  }};
  border-radius: 0 0 20px 20px;
  padding: 30px 20px 20px;
  margin-right: 20px;
  gap: 16px;
  display: grid;
  max-width: 800px;

  input {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  label: {
    background: ${({ theme: { color: { white }}}) => white };
  }

  .inputSelect__control {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  .react-date-picker {
    background: ${({ theme: { color: { cream }}}) => cream };
  }
`;

export const LotAnnotationsIcons = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
`

export const LotAnnotationsIcon = styled.button`
  grid-area: ${({ name }) => name };
  position: relative;
  display: flex;
  justify-self: center;
  top: calc(50% - 10px);
  flex-direction: column;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  background: ${({ theme: { color: { danger }}}) => danger };
  cursor: pointer;

  svg {
    path {
      stroke: white;
    }
  }

  &:hover {
    background: ${({ theme: { color: { danger }}}) => darken(.2, danger) };

    &:after {
      content: "${({ tooltip }) => tooltip}";
      display: block;
      position: absolute;
      font-weight: bold;
      color: ${({ theme: { color: { white } }}) => white };
      top: calc(50% - 60px);
      border-radius: 4px;
      padding: 5px 8px 6px;
      background: ${({ theme: { color: { brand } }}) => brand };
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 49px);
      height: 20px;
      width: 20px;
      background: ${({ theme: { color: { brand } }}) => brand };
      transform: rotate(45deg);
    }
  }
`

export const PlantingLotInfo = styled.div`
  margin-bottom: 20px;
  padding: 10px 50px;
  position: relative;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: calc(100% + 8px);
    height: 2px;
    background: ${({ theme: { color: { brand } }}) => brand };
    bottom: 15px;
    left: -8px;
    z-index: 1;
    top: 15px;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ theme: { color: { brand } }}) => brand };
    bottom: 15px;
    left: 32px;
    z-index: 1;
    top: 21px;
  }
`

export const PlantingLotName = styled.h1`
  position: relative;
  background: white;
  display: inline-block;
  text-transform: capitalize;
  font-size: 20px;
  z-index: 2;
  min-height: 25px;
  margin-bottom: 16px;
  font-weight: 400;
  padding: 0 12px;
`

export const PlantingLotNumber = styled.div`
  font-weight: 900;
  padding: 0 12px;
`

export const PlantingLotsButtons = styled.div`
  margin-top: 40px;
  padding: 10px 60px;
`