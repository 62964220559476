import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import InputsIcon from '../../images/InputsIcon';
import * as S from './Inputs.style';
import * as constants from './Inputs.constants';

export default function Input ({ setModal }) {
  const { inputsSearchFieldsState, inputsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(inputsSearchFieldsState);
  const searchFields = inputsSearchFields({fields});
  const url = 'input'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: InputsIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())).map( item => ({...item,group:item.type}))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Inputs>
      <ListPage {...listPageObject} />
    </S.Inputs>
  );
};
