import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'input',
  singular: `insumo`,
  plural: `insumos`,
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const inputFieldsState = () => ({
  name: { value: '' },
  group: { value: '' },
  gracePeriod: { value: '' },
  greenery: { value: '' },
  manufacturer: { value: '' } 
});

export const inputFields = ({ fields }) => {
  let initialFields = ({
    name: { 
      ...fields.name,
      name: 'name',
      label: 'Nome',
      placeholder: 'Digite',
      validate: (value) => ({ empty: !value }),
    },
    group: { 
      ...fields.group,
      name: 'group',
      label: 'Grupo',
      placeholder: 'Selecione',
      type: 'select',
      loadEmpty: true,
      isSearchable: false,
      options: [
        { label: 'DEFENSIVOS', value: 'DEFENSIVOS' },
        { label: 'NUTRIENTES', value: 'NUTRIENTES' },
        { label: 'SEMENTES', value: 'SEMENTES' },
        { label: 'MUDAS', value: 'MUDAS' }
      ],
      onChange: ({ target, setFields }) => {
        const { name, value } = target;
        setFields((oldFields) => {
          oldFields[name].error = false;
          oldFields[name].errorMessage = '';
          oldFields[name].value = value;
          oldFields.greenery.value = '';
          oldFields.gracePeriod.value = '';
          return ({ ...oldFields });
        });
      },
      validate: (value) => ({ empty: !value }),
    },
    manufacturer: { 
      ...fields.manufacturer,
      name: 'manufacturer',
      label: 'Fabricante',
      placeholder: 'Selecione ou Digite e depois selecione',
      type: 'select',
      isCreatable: true,
      isSearchable: true,
      loadEmpty: true,
      loadOptions: (query, callback) => {
        http.get('input/manufacturers')
          .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
      }, 
      validate: (value) => ({ empty: !value }),
    },
  })
  if (fields.group.value.value === 'DEFENSIVOS') {
    initialFields = { 
      ...initialFields, 
      gracePeriod: { 
        ...fields.gracePeriod,
        name: 'gracePeriod',
        label: 'Carência',
        placeholder: 'Digite',
        type: 'number',
        suffix: ' dias',
        validate: (value) => ({ empty: !value }),
      }
    }
  }
  if (fields.group.value.value === 'SEMENTES' || fields.group.value.value === 'MUDAS') {
    initialFields = { 
      ...initialFields, 
      greenery: { 
        ...fields.greenery,
        name: 'greenery',
        label: 'Hortaliça',
        placeholder: 'Selecione',
        loadEmpty: true,
        isSearchable: false,
        type: 'select',
        loadOptions: (query, callback) => {
          http.get('greeneries')
            .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        },
        validate: (value) => ({ empty: !value }),
      }
    }
  }
  return initialFields;
};

export const gridTemplate = css`
  ${({fields}) => {
    if (fields.group.value.value === 'DEFENSIVOS') {
      return (
        css`
          grid-template: ${`
            "name" 
            "group" 
            "manufacturer"  
            "gracePeriod"
          `};

          @media screen {
            @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
              grid-template: ${`
                "name group" 
                "manufacturer gracePeriod"
              `};
              grid-template-columns: 1fr 1fr;
            }
          }
        `
      )
    }
    if (fields.group.value.value === 'SEMENTES' || fields.group.value.value === 'MUDAS') {
      return (
        css`
          grid-template: ${`
            "name" 
            "group" 
            "manufacturer"  
            "greenery"
          `};

          @media screen {
            @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
              grid-template: ${`
                "name group" 
                "manufacturer greenery"
              `};
              grid-template-columns: 1fr 1fr;
            }
          }
        `
      )
    }
    else return (
      css`
        grid-template: ${`
          "name" 
          "group" 
          "manufacturer"  
        `};

        @media screen {
            @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
              grid-template: ${`
                "name group" 
                "manufacturer ."
              `};
              grid-template-columns: 1fr 1fr;
            }
          }
      `
    )
  }}
`



