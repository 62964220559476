import React, { useEffect, useState } from 'react';
import Field from '../../Components/Field';
import CampNotebookAnnotation from './CampNotebookAnnotation';
import ItemPage from '../../Components/ItemPage';
import CampNotebookIcon from '../../images/NotebookIcon';
import * as constants from './CampNotebook.constants';
import * as S from './CampNotebook.style';
import http from '../../service'
import { useParams, useHistory } from 'react-router-dom';
import * as moment from 'moment';

export default function CampNotebook({ setModal }) {
  const { id } = useParams();
  const { plantingLotFieldsState, plantingLotFields, plantingLotAddFields, plantingLotAddFieldsState, gridAddTemplate, pageLabel } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [addField, setAddField] = useState();
  const [fields, setFields] = useState(plantingLotFieldsState);
  const [addFields, setAddFields] = useState(plantingLotAddFieldsState);
  const formFields = plantingLotFields({ fields, setFields, setAddFields, setAddField });
  const addFormFields = plantingLotAddFields({ fields, addFields, setFields, setAddFields, setAddField });
  const history = useHistory();

  useEffect(() => {
    if (id) {
      http.get(`fieldnote/${id}`)
        .then((res) => {
          const data = res.data.data
          setFields((oldFields) => {
            const newFields = { ...oldFields }
            newFields.greenHouse.value = { ...data.greenhouse, value: data.greenhouse.id, label: data.greenhouse.name };
            newFields.humidity.value = data.humidity;
            newFields.temperature.value = data.temperature;
            newFields.comments.value = data.notes;
            newFields.status.value = data.status;
            newFields.annotations.value = [
              ...data.fieldnoteApplication.map((annotation) => ({
                id: annotation.id,
                date: { value: new Date(moment(annotation.applicatedAt || annotation.planApplicatedAt)) },
                input: { value: { value: annotation.input?.id, label: annotation.input?.name, type: 'input' } },
                amount: { value: annotation.quantity },
                sector: {
                  value: data.greenhouse.sectors
                    .filter((sector) => sector.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((sector) => ({ ...sector, label: sector.name, value: sector.value }))
                },
                stand: {
                  value: data.greenhouse.sectors
                    .reduce((arr, item) => ([...arr, ...item.stand]), [])
                    .filter((stand) => stand.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((stand) => ({ ...stand, label: stand.name, value: stand.value }))
                },
                lot: { value: annotation.lotId.map((lot) => ({ label: `${lot.substring(0, 16)}...`, value: lot })) },
                comments: { value: annotation.notes },
                task: annotation.task,
                applicatedAt: annotation.applicatedAt,
                filename: { value: annotation.file },
                type: 'application'
              })),
              ...data.fieldnoteDeficit.map((annotation) => ({
                id: annotation.id,
                date: { value: new Date(moment(annotation.applicatedAt || annotation.planApplicatedAt)) },
                input: { value: { value: annotation.input?.id, label: annotation.input?.name, type: 'input' } },
                amount: { value: annotation.quantity },
                sector: {
                  value: data.greenhouse.sectors
                    .filter((sector) => sector.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((sector) => ({ ...sector, label: sector.name, value: sector.value }))
                },
                stand: {
                  value: data.greenhouse.sectors
                    .reduce((arr, item) => ([...arr, ...item.stand]), [])
                    .filter((stand) => stand.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((stand) => ({ ...stand, label: stand.name, value: stand.value }))
                },
                lot: { value: annotation.lotId.map((lot) => ({ label: `${lot.substring(0, 16)}...`, value: lot })) },
                comments: { value: annotation.notes },
                task: annotation.task,
                applicatedAt: annotation.applicatedAt,
                filename: { value: annotation.file },
                type: 'deficiency'
              })),
              ...data.fieldnoteMaintenance.map((annotation) => ({
                id: annotation.id,
                date: { value: new Date(moment(annotation.applicatedAt || annotation.planApplicatedAt)) },
                comments: { value: annotation.notes },
                task: annotation.task,
                applicatedAt: annotation.applicatedAt,
                filename: { value: annotation.file },
                type: 'maintenance'
              })),
              ...data.fieldnotePest.map((annotation) => ({
                id: annotation.id,
                date: { value: new Date(moment(annotation.applicatedAt || annotation.planApplicatedAt)) },
                input: { value: { value: annotation.input?.id, label: annotation.input?.name, type: 'input' } },
                pest: { value: { value: annotation.bug?.id, label: annotation.bug?.nomeVulgar } },
                amount: { value: annotation.quantity },
                sector: {
                  value: data.greenhouse.sectors
                    .filter((sector) => sector.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((sector) => ({ ...sector, label: sector.name, value: sector.value }))
                },
                stand: {
                  value: data.greenhouse.sectors
                    .reduce((arr, item) => ([...arr, ...item.stand]), [])
                    .filter((stand) => stand.lotId.some((lot) => annotation.lotId.includes(lot)))
                    .map((stand) => ({ ...stand, label: stand.name, value: stand.value }))
                },
                lot: { value: annotation.lotId.map((lot) => ({ label: `${lot.substring(0, 16)}...`, value: lot })) },
                comments: { value: annotation.notes },
                task: annotation.task,
                applicatedAt: annotation.applicatedAt,
                filename: { value: annotation.file },
                type: 'pest'
              })),
            ]
            return newFields;
          })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }, [id])

  const onSubmit = () => {

    const data = {
      id: id,
      measuredAt: new Date(),
      humidity: Number(fields.humidity.value),
      temperature: Number(fields.temperature.value),
      notes: fields.comments.value,
      greenhouseId: fields.greenHouse.value.value,
      fieldnoteApplication: fields.annotations.value
        .filter((item) => item.type === 'application')
        .map((item) => ({
          id: item.id,
          fieldnoteId: id,
          planApplicatedAt: new Date(item.date.value),
          inputId: item.input.value?.type ? item.input.value?.value : null,
          recipeId: item.input.value?.type ? null : item.input.value?.value,
          quantity: Number(item.amount.value),
          lots: item.lot.value.map((item) => ({ lotId: item.value })),
          notes: item.comments.value,
          file: (item.filename ? item.filename?.value : null)
        })),
      fieldnoteDeficit: fields.annotations.value
        .filter((item) => item.type === 'deficiency')
        .map((item) => ({
          id: item.id,
          fieldnoteId: id,
          planApplicatedAt: new Date(item.date.value),
          inputId: item.input.value.type ? item.input.value?.value : null,
          recipeId: item.input.value.type ? null : item.input.value?.value,
          quantity: Number(item.amount.value),
          lots: item.lot.value.map((item) => ({ lotId: item.value })),
          notes: item.comments.value,
          file: (item.filename ? item.filename?.value : null)
        })),
      fieldnoteMaintenance: fields.annotations.value
        .filter((item) => item.type === 'maintenance')
        .map((item) => ({
          id: item.id,
          fieldnoteId: id,
          planApplicatedAt: new Date(item.date.value),
          notes: item.comments.value,
          file: (item.filename ? item.filename?.value : null)
        })),
      fieldnotePest: fields.annotations.value
        .filter((item) => item.type === 'pest')
        .map((item) => ({
          id: item.id,
          fieldnoteId: id,
          appointedAt: new Date(),
          planApplicatedAt: new Date(item.date.value),
          inputId: item.input.value.type ? item.input.value?.value : null,
          recipeId: item.input.value.type ? null : item.input.value?.value,
          bugId: item.pest.value?.value,
          quantity: Number(item.amount.value),
          lots: item.lot.value.map((item) => ({ lotId: item.value })),
          notes: item.comments.value,
          file: (item.filename ? item.filename?.value : null)
        })),
    }
    http[id ? 'put' : 'post'](`fieldnote${id ? `/${id}` : ''}`, data)
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: formFields, addFields, onSubmit, pageLabel, gridAddTemplate,
    setLoading, setError, icon: CampNotebookIcon, setItem, setFields, setAddFields, addFormFields, disabledParent: fields.status.value === 'Concluído', setModal
  }

  return (
    <S.CampNotebook>
      <ItemPage {...itemPageObject}>
        {addField && (
          <>
            <S.ItemPageTitle addField={addField}>{pageLabel[addField]}</S.ItemPageTitle>
            <S.ItemPageForm gridTemplate={gridAddTemplate[addField]} addField={addField}>
              {Object
                .values(addFormFields[addField])
                .map((field) => <Field key={field.name} {...field} setFields={setFields} />)
              }
            </S.ItemPageForm>
          </>
        )}
        {!!fields.annotations.value.length && fields.annotations.value.map((annotation, i) => (
          <CampNotebookAnnotation annotation={annotation} i={i} {...itemPageObject} />
        ))}
      </ItemPage>
    </S.CampNotebook>
  );
};
