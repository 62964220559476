import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Manufacturer.constants';
import * as S from './Manufacturer.style';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function Manufacturer ({setModal}) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { manufacturerFieldsState, manufacturerFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(manufacturerFieldsState);
  const itemFields = manufacturerFields({fields});

  useEffect(() => {
    if(id) {
      http.get(`fabricante/${id}`)
        .then(({ data }) => setFields({
          name: { value: data.nome },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`fabricante${id ? `/${id}` : ''}`, {
      organizacaoId: '7080d758-fe8a-11ea-adc1-0242ac120002',
      nome: fields.name.value,
    })
      .then(({ data }) => history.push(`/${router.url.split('/')[1]}`))
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Manufacturer>
      <ItemPage {...itemPageObject} />
    </S.Manufacturer>
  );
};
