import { css } from 'styled-components';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import { login, signup, recovery, recoveryStep2 } from '../../service/auth';

export const loginFieldsState = () => ({
  username: { value: '' },
  token: { value: '' },
  password: { value: '' },
  confirmPassword: { value: '' },
  lote: { value: '' },
  hortalica: { value: '' },
  data: { value: '' },
  produzido: { value: '' },
  cpfCnpj: { value: '' },
  endereco: { value: '' }
})

export const loginFields = ({ fields, qrCode, qrCodeType, setCurrentUser, signUp, setError, setLoading, setForgot, forgot, loading, error, recovered, setRecovered, setFields }) => {
  const newFields = ({
    username: {
      ...fields.username,
      name: 'username',
      label: 'Email',
      placeholder: 'Digite',
      tabIndex: 1,
    },
    password: {
      ...fields.password,
      name: 'password',
      label: 'Senha',
      type: 'password',
      placeholder: 'Digite',
      tabIndex: 2,
    },
    error: {
      label: error,
      name: 'error',
      type: 'error',
    },
    submitButton: {
      label: 'Entrar',
      type: 'button',
      loading,
      onClick: () => {
        const data = { username: fields.username.value, password: fields.password.value }
        login({ data, setCurrentUser, setError, setLoading })
      },
      variation: 'primary',
      tabIndex: 6
    },
  })
  if (recovered) {
    newFields.token = {
      ...fields.token,
      name: 'token',
      label: 'Token',
      type: '',
      placeholder: 'Digite',
      tabIndex: 5
    };
    newFields.password = {
      ...fields.password,
      name: 'password',
      label: 'Nova senha',
      type: 'password',
      placeholder: 'Digite',
      tabIndex: 2
    };
    newFields.confirmPassword = {
      ...fields.confirmPassword,
      name: 'confirmPassword',
      label: 'Confirme',
      type: 'password',
      placeholder: 'Digite ',
      tabIndex: 3
    };
    newFields.submitButton = {
      ...newFields.submitButton,
      label: 'Enviar',
      onClick: () => {
        const data = { username: fields.username.value, password: fields.password.value }
        recoveryStep2({ data, token: fields.token.value, setCurrentUser, setError, setLoading })
      },
    }
  } else if (signUp) {
    newFields.confirmPassword = {
      ...fields.confirmPassword,
      required: true,
      name: 'confirmPassword',
      label: 'Confirme',
      type: 'password',
      placeholder: 'Digite ',
      tabIndex: 3
    }
    newFields.submitButton = {
      ...newFields.submitButton,
      label: 'Cadastrar',
      onClick: () => {
        const data = { username: fields.username.value, password: fields.password.value }
        signup({ data, setCurrentUser, setError, setLoading })
      },
      tabIndex: 6
    }
  } else if (forgot) {
    delete newFields.password;
    newFields.forgotButton = {
      label: 'Voltar',
      name: 'forgotButton',
      type: 'button',
      onClick: () => { setError(''); setForgot(!forgot) },
      variation: 'danger',
    };
    newFields.submitButton = {
      ...newFields.submitButton,
      label: 'Enviar',
      onClick: () => {
        const data = { email: fields.username.value, }
        recovery({ data, setCurrentUser, setError, setLoading, setRecovered, setForgot })
      },
      tabIndex: 6
    }
  } else {
    newFields.forgotButton = {
      label: 'Esqueci a senha',
      name: 'forgotButton',
      type: 'button',
      onClick: () => { setError(''); setForgot(!forgot) },
      variation: 'danger',
      tabIndex: 7
    }
  }
  if (qrCode) {
    delete newFields.username;
    delete newFields.password;
    delete newFields.submitButton;
    delete newFields.forgotButton;

    if (qrCodeType === 'expedition') {
      newFields.lote = {
        ...fields.lote,
        name: 'lote',
        label: 'Lote:',
        readOnly: true
      };
    }
    newFields.hortalica = {
      ...fields.hortalica,
      name: 'hortalica',
      label: 'Hortaliça:',
      readOnly: true
    };
    if (qrCodeType === 'expedition') {
      newFields.data = {
        ...fields.data,
        name: 'data',
        label: 'Data da Colheita:',
        readOnly: true
      };
    }
    newFields.produzido = {
      ...fields.produzido,
      name: 'produzido',
      label: 'Produzido por:',
      readOnly: true
    };
    newFields.cpfCnpj = {
      ...fields.cpfCnpj,
      name: 'cpfCnpj',
      label: 'CPF/CNPJ:',
      readOnly: true
    };
    newFields.endereco = {
      ...fields.endereco,
      name: 'endereco',
      label: 'Endereço:',
      type: 'textarea',
      readOnly: true
    };
  }

  return newFields;
}
export const title = "Hidrotech"

export const gridTemplate = css`
  grid-template:
  ${`"username"
    "password"
    "confirmPassword"
    "token"
    "submitButton" 
    "error" 
    "forgotButton"
  `};
  grid-template-columns: 1fr;
  width: 290px;
  left: -17px;
  height: auto;
  `;


export const gridTemplateExpedition = css`
  grid-template: 
  ${`"lote"
  "hortalica"
  "data"
  "produzido"
  "cpfCnpj"
  "endereco"
`};
  grid-template-columns: 1fr;
  width: 290px;
  left: -17px;
  height: auto;
  `;

export const gridTemplateGreenery = css`
  grid-template: 
  ${`"hortalica"
  "produzido"
  "cpfCnpj"
  "endereco"
`};
  grid-template-columns: 1fr;
  width: 290px;
  left: -17px;
  height: auto;
  `;