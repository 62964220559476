import React, { useEffect, useState } from 'react';
import http from '../../service'
import Field from '../../Components/Field';
import PlantingLotsIcon from '../../images/PlantingLotsIcon';
import * as S from './PlantingLot.style';
import * as constants from './PlantingLot.constants';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../../Components/Button';
import CancelIcon from '../../images/Delete';
import * as moment from 'moment';

export default function PlantingLot({ setModal }) {
  const { id } = useParams();
  const { plantingLotFieldsState, plantingLotFields, pageLabel: { singular }, pageColor, lotInfogridTemplate, lotInfogridTemplateObservacoes } = constants;
  const [fields, setFields] = useState(plantingLotFieldsState);
  const itemFields = plantingLotFields(fields);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      http.get(`lot/history/${id}`)
        .then(({ data }) => setFields(data.data))
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }, [id])

  const { greenery } = fields;

  let notes = [];

  if (fields.description) {
    notes.push({
      label: moment(fields.plantDate).format('DD/MM/YYYY') + ' - Plantio do Lote', value: fields.description
    })
  }

  fields.tasksMovimentLot?.map((item) => {
    if (item.endDate && item?.notes) {
      notes.push({
        label: moment(item.endDate).format('DD/MM/YYYY') + ' - ' + item.title, value: item.notes
      })
    }
  })

  return (
    <S.PlantingLots>
      <S.PlantingLotsHeader color={pageColor}>
        <S.PlantingLotsHeaderTitle color={pageColor}><PlantingLotsIcon />{singular}<strong>Histórico</strong></S.PlantingLotsHeaderTitle>
      </S.PlantingLotsHeader>
      <S.PlantingLotInfo>
        <S.PlantingLotName>{greenery?.name}</S.PlantingLotName>
        <S.PlantingLotNumber>Nr. Lote: {id}</S.PlantingLotNumber>
      </S.PlantingLotInfo>
      <>
        {notes.length > 0 ?
          <>
            <S.LotAnnotationsTitle addField="lotInfo">Observações</S.LotAnnotationsTitle>
            <S.LotAnnotations>
              <S.LotAnnotationsFields addField="lotInfo" gridTemplate={lotInfogridTemplateObservacoes}>
                {Object.values(notes).map((field) => <Field key={field.id} {...field} disabled />)}
              </S.LotAnnotationsFields>
            </S.LotAnnotations>
          </>
          : <></>
        }
        <S.LotAnnotationsTitle addField="lotInfo">Dados Gerais</S.LotAnnotationsTitle>
        <S.LotAnnotations>
          <S.LotAnnotationsFields addField="lotInfo" gridTemplate={lotInfogridTemplate}>
            {Object.values(itemFields?.lotInfo).map((field) => <Field key={field.id} {...field} disabled />)}
          </S.LotAnnotationsFields>
        </S.LotAnnotations>

        { itemFields.annotations?.length > 0 && Object.values(itemFields.annotations)
          .sort((annotationA, annotationB) => annotationA.createdAt > annotationB.createdAt)
          .map(({ annotationType, ...annotationFields }) => (
            <>
              <S.LotAnnotationsTitle addField={annotationType.value}>{annotationType.label}</S.LotAnnotationsTitle>
              <S.LotAnnotations>
                <S.LotAnnotationsFields addField={annotationType.value} gridTemplate={lotInfogridTemplate}>
                  {Object.values(annotationFields).map((field) => <Field key={field.id} {...field} disabled />)}
                </S.LotAnnotationsFields>
              </S.LotAnnotations>
            </>
          ))}
      </>
      <S.PlantingLotsButtons>
        <Button variation="danger" label="Voltar" rightIcon={CancelIcon} onClick={() => history.goBack()} />
      </S.PlantingLotsButtons>
    </S.PlantingLots>
  );
};
