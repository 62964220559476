import { css } from 'styled-components';
import http from '../../service';
import { format } from 'date-fns';
import * as moment from 'moment';

export const type = 'list'
export const pageColor = '#8bc53f'

export const pageLabel = {
  name: 'plantingLot',
  singular: 'lote de plantio',
  plural: 'lotes de plantio',
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const plantingLotFieldsState = () => ({

});

export const plantingLotFields = (fields) => {
  const newFields = ({
    lotInfo: {},
    annotations: []
  })

  if (fields.input?.name) {
    newFields.lotInfo.sementeMuda = {
      label: `Semente / Muda`,
      value: fields.input?.name
    }
  }
  if (fields.input?.manufacturer?.name) {
    newFields.lotInfo.fabricante = {
      label: `Fabricante`,
      value: fields.input?.manufacturer?.name
    }
  }
  newFields.lotInfo.plantDate = {
    label: 'Plantio',
    value: fields.plantDate ? format(new Date(fields.plantDate), "dd/MM/yyyy") : '',
  };
  if (fields.viveiroPlanDate || fields.viveiroDate) {
    newFields.lotInfo.vivariumDate = {
      label: `Entrada no viveiro ${fields.viveiroDate ? '' : '(Previsão)'}`,
      value: moment(fields.viveiroDate || fields.viveiroPlanDate).format('DD/MM/YYYY')
    }
  }
  if (fields.bercarioPlanDate || fields.bercarioDate) {
    newFields.lotInfo.NurseryDate = {
      label: `Entrada no berçário ${fields.bercarioDate ? '' : '(Previsão)'}`,
      value: moment(fields.bercarioDate || fields.bercarioPlanDate).format('DD/MM/YYYY')
    }
  }
  if (fields.FinalPerfilPlanDate || fields.FinalPerfilDate) {
    newFields.lotInfo.finalPhaseDate = {
      label: `Entrada na fase final ${fields.FinalPerfilDate ? '' : '(Previsão)'}`,
      value: moment(fields.FinalPerfilDate || fields.FinalPerfilPlanDate).format('DD/MM/YYYY')
    }
  }
  if (fields.haverstPlanDate || fields.haverstDate) {
    newFields.lotInfo.haverstDate = {
      label: `Data da colheita ${fields.haverstDate ? '' : '(Previsão)'}`,
      value: moment(fields.haverstDate || fields.haverstPlanDate).format('DD/MM/YYYY')
    }
  }

  if (fields.standId) {
    newFields.lotInfo.greenhouse = {
      label: `Estufa`,
      value: fields.greenhouse?.name
    }
    newFields.lotInfo.sector = {
      label: `Setor`,
      value: fields.sector?.name
    }
    newFields.lotInfo.stand = {
      label: `Banca`,
      value: fields.stand?.name
    }
  }

  if (fields.fieldnotePests?.length) {
    newFields.annotations = [
      ...newFields.annotations,
      ...fields.fieldnotePests.map((annotation) => {
        const newAnnotation = {
          annotationType: {
            label: (!annotation.recipe && annotation.recipeId ? (annotation.input?.type === 'NUTRIENTES' ? 'Nutrientes' : (annotation.input?.type === 'DEFENSIVOS' ? 'Defensivos' : 'Praga')) : 'Praga'),
            value: 'pest',
          },
          pest: {
            label: 'Praga',
            value: annotation.bug.nomeVulgar,
          },
          input: {
            label: (annotation.input?.name ? 'Defensivo' : 'Receita'),
            value: (annotation.input?.name ? annotation.input?.name : annotation.recipe?.name),
          },
          amount: {
            label: 'Quantidade',
            value: annotation.quantity,
          },
          applicatedAt: {
            label: `Data de aplicação ${annotation.applicatedAt ? '' : '(Previsão)'}`,
            value: moment(annotation.applicatedAt || annotation.planApplicatedAt).format('DD/MM/YYYY'),
            date: annotation.applicatedAt || annotation.planApplicatedAt
          }
        }
        if (annotation.notes) {
          newAnnotation.notes = {
            label: 'Observações',
            value: annotation.notes,
          }
        }
        return newAnnotation
      })
    ]
  }
  if (fields.fieldnoteDeficits?.length) {
    newFields.annotations = [
      ...newFields.annotations,
      ...fields.fieldnoteDeficits.map((annotation) => {
        const newAnnotation = {
          annotationType: {
            label: (!annotation.recipe && annotation.recipeId ? (annotation.input?.type === 'NUTRIENTES' ? 'Nutrientes' : (annotation.input?.type === 'DEFENSIVOS' ? 'Defensivos' : 'Praga')) : 'Deficiência'),
            value: 'deficiency',
          },
          input: {
            label: (annotation.input?.name ? 'Nutriente' : 'Receita'),
            value: (annotation.input?.name ? annotation.input?.name : annotation.recipe?.name),
          },
          amount: {
            label: 'Quantidade',
            value: annotation.quantity,
          },
          applicatedAt: {
            label: `Data de aplicação ${annotation.applicatedAt ? '' : '(Previsão)'}`,
            value: moment(annotation.applicatedAt || annotation.planApplicatedAt).format('DD/MM/YYYY'),
            date: annotation.applicatedAt || annotation.planApplicatedAt
          }
        }
        if (annotation.notes) {
          newAnnotation.notes = {
            label: 'Observações',
            value: annotation.notes,
          }
        }
        return newAnnotation
      })
    ]
  }
  if (fields.fieldnoteApplications?.length) {
    newFields.annotations = [
      ...newFields.annotations,
      ...fields.fieldnoteApplications.map((annotation) => {
        const newAnnotation = {
          annotationType: {
            label: (!annotation.recipe && annotation.recipeId ? (annotation.input?.type === 'NUTRIENTES' ? 'Nutrientes' : (annotation.input?.type === 'DEFENSIVOS' ? 'Defensivos' : 'Praga')) : 'Aplicação'),
            value: 'maintenance',
          },
          input: {
            label: (annotation.input?.name ? 'Defensivo ou Nutriente' : 'Receita'),
            value: (annotation.input?.name ? annotation.input?.name : annotation.recipe?.name),
          },
          amount: {
            label: 'Quantidade',
            value: annotation.quantity,
          },
          applicatedAt: {
            label: `Data de aplicação ${annotation.applicatedAt ? '' : '(Previsão)'}`,
            value: moment(annotation.applicatedAt || annotation.planApplicatedAt).format('DD/MM/YYYY'),
            date: annotation.applicatedAt || annotation.planApplicatedAt
          }
        }
        if (annotation.notes) {
          newAnnotation.notes = {
            label: 'Observações',
            value: annotation.notes,
          }
        }
        return newAnnotation
      })
    ]
  }

  newFields.annotations.sort((a, b) => {
    if (a.applicatedAt.date > b.applicatedAt.date)
      return -1;
    if (a.applicatedAt.date < b.applicatedAt.date)
      return 1;
    return 0;
  });

  return newFields;
};

export const lotInfogridTemplate = css`
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template-columns: 1fr 1fr;
    }
  }
`
export const lotInfogridTemplateObservacoes = css`
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template-columns: 1fr;
    }
  }
`