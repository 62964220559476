import styled from 'styled-components';

export const Activity = styled.div`
  background: white;
  min-height: 100%;
`

export const ItemPageTitle = styled.h2`
  padding: 4px 20px;
  font-size: 18px;
  font-weight: lighter;
  background: ${({ theme: { color: { gray } }}) => gray};
  color: ${({ theme: { color: { white } } }) => white };
  border-radius: 20px;
  position: relative;
  top: 15px;
  left: 45px;
  z-index: 1;
  display: inline-block;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      left: 85px;
    }
  }
`;

export const ItemPageButtons = styled.div`
  display: grid;
  grid-template-columns: 140px 140px;
  padding: 20px;
  gap: 20px;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      margin: 20px 40px;
    }
  }
`

export const ItemInfoForm = styled.section`
  ${({ gridTemplate }) => gridTemplate};
  position: relative;
  border: 2px solid ${({ theme: { color: { brandDark } } }) => brandDark };
  background: ${({ theme: { color: { brandDark } } }) => brandDark };
  border-radius: 20px;
  padding: 30px 20px 20px;
  margin: 0 20px;
  gap: 16px;
  display: grid;
  max-width: 800px;

  >div {
    border-color: ${({ theme: { color: { cream }}}) => cream };
  }

  input {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  label {
    background: ${({ theme: { color: { white }}}) => white };
  }

  .inputSelect__control {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  .react-date-picker {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  &:after {
    content: '>';
    display: block;
    text-align: center;
    position: absolute;
    width: 20px;
    height: 20px;
    font-weight: bolder;
    color: ${({ theme: { color: { brandDark } } }) => brandDark };
    background: ${({ theme: { color: { white } } }) => white };
    border: 2px solid ${({ theme: { color: { brandDark } } }) => brandDark };
    box-shadow: 0 0 0 6px white;
    transform: rotate(90deg);
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      margin: 0 60px;
    }
  }
`;

export const ItemPageForm = styled.section`
  ${({ gridTemplate }) => gridTemplate};
  position: relative;
  border: 2px solid ${({ theme: { color: { brandDark } } }) => brandDark };
  border-radius: 0 0 20px 20px;
  padding: 30px 20px 20px;
  margin: 0 20px;
  gap: 16px;
  display: grid;
  max-width: 800px;

  >div {
    border-color: ${({ theme: { color: { cream }}}) => cream };
  }

  input {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  label {
    background: ${({ theme: { color: { white }}}) => white };
  }

  .inputSelect__control {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  .react-date-picker {
    background: ${({ theme: { color: { cream }}}) => cream };
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: ${({ theme: { color: { brandDark } } }) => brandDark };
    border-radius: 50%;
    top: -6px;
    left: -6px;
  }

  &:after {
    content: '>';
    display: block;
    text-align: center;
    position: absolute;
    width: 20px;
    height: 20px;
    font-weight: bolder;
    color: ${({ theme: { color: { brandDark } } }) => brandDark };
    background: ${({ theme: { color: { white } } }) => white };
    border: 2px solid ${({ theme: { color: { brandDark } } }) => brandDark };
    box-shadow: 0 0 6px 6px white;
    transform: rotate(90deg);
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      margin: 0 60px;
    }
  }
`;


export const ItemPageHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      margin-bottom: 50px;
      padding: 10px 30px;
    }

    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      margin-bottom: 20px;
      padding: 10px 50px;
    }
  }
`;

export const ItemPageHeaderTitle = styled.h1`
  background: white;
  text-transform: capitalize;
  font-size: 20px;
  display: flex;
  align-items: center;

  >svg {
    height: 40px;
    width: 40px;
    padding: 6px;
    background: ${({theme: { color: { white }}}) => white};
    
    path {
      fill: ${({ color }) => color};
    }
  }
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenSM } }}) => screenSM}) {
      
      >svg {
        height: 60px;
        width: 60px;
        margin-right: 10px;
      }
    }
  }
`