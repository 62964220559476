import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'pest',
  singular: `praga`,
  plural: `pragas`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const pestFieldsState = () => ({
  //name: { value: '' },
  commonName: { value: '' },
  scientificName: { value: '' },
  //attackSite: { value: '' },
  //likeDamage: { value: '' },
});

export const pestFields = ({ fields }) => ({
  commonName: { 
    ...fields.commonName,
    name: 'commonName',
    label: 'Nome vulgar',
    placeholder: 'Digite',
    validate: (value)=> ({ 
      empty: !(value.length > 3),
      emptyMessage: 'Este campo é obrigatório e precisa ter mais de 3 caracteres'
    }),
  },
  scientificName: { 
    ...fields.scientificName,
    name: 'scientificName',
    label: 'Nome científico',
    placeholder: 'Digite',
    validate: (value)=> ({ 
      empty: !(value.length > 3),
      emptyMessage: 'Este campo é obrigatório e precisa ter mais de 3 caracteres'
    }),
  },
  // attackSite: { 
  //   ...fields.attackSite,
  //   name: 'attackSite',
  //   label: 'Local ataque',
  //   placeholder: 'Digite',
  // },
  // likeDamage: { 
  //   ...fields.likeDamage,
  //   name: 'likeDamage',
  //   label: 'Tipo Dano',
  //   placeholder: 'Digite',
  // },
});

export const gridTemplate = css`
  grid-template: ${`
    "commonName" 
    "scientificName"  
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "commonName scientificName"  
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
