import styled from 'styled-components';

export const Tabs = styled.div`
  grid-area: ${({name}) => name};
`

export const TabsHeader = styled.ul`
  display: flex;
  white-space: nowrap;
  align-items: center;
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenLG } }}) => screenLG}) {
      padding: 10px 60px;
    }
  }
`

export const TabsHeaderItem = styled.li`
  border: ${({ color }) => `1px solid ${color}` };
  color: ${({ color, isActive, theme: { color: { white } } }) => isActive ? white : color };
  background: ${({ color, isActive, theme: { color: { white } } }) => isActive ? color : 'transparent' };
  width: 68px;
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  margin-left: 10px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: ${({ color }) => color };
    color: ${({ theme: { color: { white } }}) => white };
  }
`