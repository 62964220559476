import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './WaterTank.constants';
import * as S from './WaterTank.style';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import * as moment from 'moment';

export default function WaterTank({ setModal }) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { waterTankFieldsState, waterTankFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(waterTankFieldsState);
  const itemFields = waterTankFields({ fields, id });

  useEffect(() => {
    if (id) {
      http.get(`watertank/${id}`)
        .then(({ data }) => setFields({
          name: { value: data.data.name },
          capacity: { value: data.data.capacity },
          cleaningFrequency: { value: data.data.cleaningFrequency },
          nutritiveSolution: { value: { label: data.data.nutritiveSolution?.name, value: data.data.nutritiveSolution?.id } },
          cleaningLastDate: { value: new Date(moment(data.data.cleaningLastDate)) },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    const data = {
      name: fields.name.value,
      capacity: Number(fields.capacity.value),
      cleaningFrequency: Number(fields.cleaningFrequency.value),
      cleaningLastDate: fields.cleaningLastDate.value,
      nutritiveSolutionId: fields.nutritiveSolution.value.value
    }
    if (!id) { delete data.cleaningLastDate }
    http[id ? 'put' : 'post'](`watertank${id ? `/${id}` : ''}`, data)
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.WaterTank>
      <ItemPage {...itemPageObject} />
    </S.WaterTank>
  );
};
