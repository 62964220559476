import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import PlantingLotsReportIcon from '../../images/PlantingLotsIcon';
import * as S from './PlantingLotsReport.style';
import * as constants from './PlantingLotsReport.constants';
import { subDays, addDays } from 'date-fns';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function PlantingLotsReport({ setModal }) {
  const { PlantingLotsReportSearchFieldsState, PlantingLotsReportSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(PlantingLotsReportSearchFieldsState);
  const searchFields = PlantingLotsReportSearchFields({ fields, setList, setModal });
  const url = 'lot';
  const { dias } = useParams();

  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: PlantingLotsReportIcon, setList, setFields
  };

  useEffect(() => {
    if (dias) {
      fields.endDate.value = addDays(new Date(), dias);
    }

    const params = {
      endDate: fields.endDate.value,
      isHarvestReport: true,
    }
    setTimeout(() => {
      http.get(url, { params })
        .then(({ data }) => {
          setList(data.data.map((item) => {
            const sector = item.lots[0]?.sector?.name;
            const greenhouse = item.lots[0]?.greenhouse;
            return ({ ...item, reportActivityType: item.type, sector, greenhouse })
          }))
        })
        .catch((err) => setList([]))
    }, 10)
  }, [])

  return (
    <S.PlantingLotsReport>
      <ListPage {...listPageObject} />
    </S.PlantingLotsReport>
  );
};
