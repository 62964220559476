import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'plan',
  singular: `plano`,
  plural: `planos`,
  newLabel: 'Novo',
  editLabel: 'Editar'
};

export const planFieldsState = () => ({
  name: { value: '' },
  recurrence: { value: '' },
  price: { value: '' },
});

export const planFields = ({ fields }) => ({
  name: { 
    ...fields.name,
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
  },
  recurrence: { 
    ...fields.recurrence,
    name: 'recurrence',
    label: 'Recorrência',
    placeholder: 'Selecione',
    type: 'select',
    options: [
      { label: 'Mensal', value: '30' }, 
      { label: 'Semestral', value: '180' }, 
      { label: 'Anual', value: '365' }, 
    ],
    isSearchable: false,
    loadEmpty: true,
  },
  Price: { 
    ...fields.price,
    name: 'price',
    label: 'Preço',
    placeholder: 'Digite',
    type: 'number',
    prefix: 'R$ ',
    decimalScale: 2,
    fixedDecimalScale: 2,
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "recurrence" 
    "price" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name name" 
        "recurrence price"
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
