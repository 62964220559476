import React from 'react';

export default function QrCodeIcon () {
  return (
    <svg width="25" height="25" viewBox="0 0 96 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M18.12 116.33H2.06C0.92 116.33 0 115.41 0 114.27V97.35C0 96.21 0.92 95.29 2.06 95.29C3.2 95.29 4.12 96.21 4.12 97.35V112.2H18.12C19.26 112.2 20.18 113.12 20.18 114.26C20.18 115.4 19.26 116.33 18.12 116.33Z" fill="#2B2B2A"/>
      <path d="M93.85 21.04C92.71 21.04 91.79 20.12 91.79 18.98V4.13H77.79C76.65 4.13 75.73 3.21 75.73 2.07C75.73 0.93 76.65 0 77.79 0H93.85C94.99 0 95.91 0.92 95.91 2.06V18.97C95.91 20.12 94.99 21.04 93.85 21.04Z" fill="#2B2B2A"/>
      <path d="M2.06 21.04C0.92 21.04 0 20.12 0 18.98V2.06C0 0.92 0.92 0 2.06 0H20.57C21.71 0 22.63 0.92 22.63 2.06C22.63 3.2 21.71 4.12 20.57 4.12H4.13V18.97C4.13 20.12 3.2 21.04 2.06 21.04Z" fill="#2B2B2A"/>
      <path d="M93.85 116.33H74.83C73.69 116.33 72.77 115.41 72.77 114.27C72.77 113.13 73.69 112.21 74.83 112.21H91.78V96.53C91.78 95.39 92.7 94.47 93.84 94.47C94.98 94.47 95.9 95.39 95.9 96.53V114.27C95.91 115.41 94.99 116.33 93.85 116.33Z" fill="#2B2B2A"/>
      <path d="M77.28 56.69H51.33C50.19 56.69 49.27 55.77 49.27 54.63V28.67C49.27 27.53 50.19 26.61 51.33 26.61H77.29C78.43 26.61 79.35 27.53 79.35 28.67V54.63C79.34 55.76 78.42 56.69 77.28 56.69ZM53.39 52.56H75.22V30.73H53.39V52.56Z" fill="#2B2B2A"/>
      <path d="M67.5001 39.46H62.1101V44.85H67.5001V39.46Z" fill="#2B2B2A"/>
      <path d="M44.0801 56.69H18.1201C16.9801 56.69 16.0601 55.77 16.0601 54.63V28.67C16.0601 27.53 16.9801 26.61 18.1201 26.61H44.0801C45.2201 26.61 46.1401 27.53 46.1401 28.67V54.63C46.1401 55.76 45.2201 56.69 44.0801 56.69ZM20.1901 52.56H42.0201V30.73H20.1901V52.56Z" fill="#2B2B2A"/>
      <path d="M34.3 39.46H28.91V44.85H34.3V39.46Z" fill="#2B2B2A"/>
      <path d="M44.0801 88.71H18.1201C16.9801 88.71 16.0601 87.79 16.0601 86.65V60.69C16.0601 59.55 16.9801 58.63 18.1201 58.63H44.0801C45.2201 58.63 46.1401 59.55 46.1401 60.69V86.65C46.1401 87.79 45.2201 88.71 44.0801 88.71ZM20.1901 84.59H42.0201V62.76H20.1901V84.59Z" fill="#2B2B2A"/>
      <path d="M34.3 71.48H28.91V76.87H34.3V71.48Z" fill="#2B2B2A"/>
      <path d="M64.81 76.24H51.33C50.19 76.24 49.27 75.32 49.27 74.18V60.69C49.27 59.55 50.19 58.63 51.33 58.63H64.81C65.95 58.63 66.87 59.55 66.87 60.69V74.17C66.87 75.32 65.95 76.24 64.81 76.24ZM53.39 72.11H62.75V62.75H53.39V72.11Z" fill="#2B2B2A"/>
      <path d="M59.7301 66.3H56.9301V69.1H59.7301V66.3Z" fill="#2B2B2A"/>
      <path d="M77.7901 89.72H64.3001C63.1601 89.72 62.2401 88.8 62.2401 87.66V74.18C62.2401 73.04 63.1601 72.12 64.3001 72.12H77.7801C78.9201 72.12 79.8401 73.04 79.8401 74.18V87.66C79.8501 88.8 78.9301 89.72 77.7901 89.72ZM66.3701 85.6H75.7301V76.24H66.3701V85.6Z" fill="#2B2B2A"/>
      <path d="M72.71 79.78H69.91V82.58H72.71V79.78Z" fill="#2B2B2A"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="95.91" height="116.33" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
