import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import RecipeIcon from '../../images/RecipeIcon';
import * as S from './Recipes.style';
import * as constants from './Recipes.constants';

export default function Recipes ({ setModal }) {
  const { recipesSearchFieldsState, recipesSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(recipesSearchFieldsState);
  const searchFields = recipesSearchFields({fields});
  const url = 'recipes'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: RecipeIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Recipes>
      <ListPage {...listPageObject} />
    </S.Recipes>
  );
};
