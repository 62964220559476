import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Greenhouse.constants';
import * as S from './Greenhouse.style';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function Greenhouse({ setModal }) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { greenhouseFieldsState, greenhouseFields, newSector, newStand } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(greenhouseFieldsState);
  const itemFields = greenhouseFields({ fields, setFields });

  useEffect(() => {
    const sector = newSector(fields, setFields, 0)
    const stand = newStand(fields, setFields, 0)
    if (id) {
      http.get(`greenhouse/${id}`)
        .then(({ data }) => setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.name.value = data.data.name
          newFields.sectors.value = data.data.sectors.map((sec) => ({
            ...sector,
            sectorId: { ...sector.sectorId, value: sec.id },
            sectorLabel: { ...sector.sectorLabel, label: sec.name },
            sectorName: { ...sector.sectorName, value: sec.name },
            waterTank: { ...sector.waterTank, value: { label: sec.waterTank?.name, value: sec.waterTank?.id } },
            stands: {
              ...sector.stands, value: sec.stand.map((std) => ({
                ...stand,
                standId: { ...stand.standId, value: std.id },
                standLabel: { ...stand.standLabel, label: std.name },
                standName: { ...stand.standName, value: std.name },
                standHoles: { ...stand.standHoles, value: std.quantity },
              }))
            }
          }))
          return newFields;
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    } else {
      setFields((oldFields) => {
        const sectorsValue = [...oldFields.sectors.value, sector]
        return { ...oldFields, sectors: { ...oldFields.sectors, value: sectorsValue } }
      })
    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'put' : 'post'](`greenhouse${id ? `/${id}` : ''}`, {
      name: fields.name.value,
      sectors: fields.sectors.value
        .map((sector) => ({
          id: sector.sectorId.value,
          name: sector.sectorName.value,
          waterTankId: sector.waterTank.value.value,
          stand: sector.stands.value
            .map((stand) => ({
              id: stand.standId.value,
              name: stand.standName.value,
              quantity: Number(stand.standHoles.value),
            })),
        })),
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })

      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Greenhouse>
      <ItemPage {...itemPageObject} />
    </S.Greenhouse>
  );
};
