import React from 'react';

export default function RegisterIcon () {
  return (
    <svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M64.65 80.14H1.42C0.64 80.14 0 79.51 0 78.72V1.42C0 0.64 0.64 0 1.42 0H64.65C65.43 0 66.07 0.64 66.07 1.42V22.59C66.07 23.37 65.43 24.01 64.65 24.01C63.87 24.01 63.23 23.37 63.23 22.59V2.84H2.84V77.3H63.23V57.55C63.23 56.77 63.87 56.13 64.65 56.13C65.43 56.13 66.07 56.77 66.07 57.55V78.72C66.07 79.51 65.44 80.14 64.65 80.14Z" fill="#16C4C4"/>
        <path d="M54.28 19.33H27.28C26.5 19.33 25.86 18.69 25.86 17.91C25.86 17.13 26.5 16.49 27.28 16.49H54.28C55.06 16.49 55.7 17.13 55.7 17.91C55.7 18.69 55.07 19.33 54.28 19.33Z" fill="#16C4C4"/>
        <path d="M54.28 43.05H27.28C26.5 43.05 25.86 42.41 25.86 41.63C25.86 40.85 26.5 40.21 27.28 40.21H54.28C55.06 40.21 55.7 40.85 55.7 41.63C55.7 42.42 55.07 43.05 54.28 43.05Z" fill="#16C4C4"/>
        <path d="M54.28 63.8H27.28C26.5 63.8 25.86 63.16 25.86 62.38C25.86 61.6 26.5 60.96 27.28 60.96H54.28C55.06 60.96 55.7 61.6 55.7 62.38C55.7 63.16 55.07 63.8 54.28 63.8Z" fill="#16C4C4"/>
        <path d="M20.04 24.01H10.52C9.74001 24.01 9.10001 23.37 9.10001 22.59V13.07C9.10001 12.29 9.74001 11.65 10.52 11.65H20.04C20.82 11.65 21.46 12.29 21.46 13.07V22.59C21.46 23.38 20.82 24.01 20.04 24.01ZM11.94 21.17H18.62V14.49H11.94V21.17V21.17Z" fill="#16C4C4"/>
        <path d="M20.04 47.74H10.52C9.74001 47.74 9.10001 47.1 9.10001 46.32V36.8C9.10001 36.02 9.74001 35.38 10.52 35.38H20.04C20.82 35.38 21.46 36.02 21.46 36.8V46.32C21.46 47.11 20.82 47.74 20.04 47.74ZM11.94 44.9H18.62V38.22H11.94V44.9V44.9Z" fill="#16C4C4"/>
        <path d="M20.04 68.49H10.52C9.74001 68.49 9.10001 67.85 9.10001 67.07V57.55C9.10001 56.77 9.74001 56.13 10.52 56.13H20.04C20.82 56.13 21.46 56.77 21.46 57.55V67.07C21.46 67.85 20.82 68.49 20.04 68.49ZM11.94 65.65H18.62V58.97H11.94V65.65V65.65Z" fill="#16C4C4"/>
      </g>
    </svg>
  )
}
