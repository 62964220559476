import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Group.constants';
import * as S from './Group.style';
import { useParams } from 'react-router-dom';

export default function Group ({setModal}) {
  const { id } = useParams();
  const { groupFieldsState, groupFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(groupFieldsState);
  const itemFields = groupFields({fields});

  useEffect(() => {
    if(id) {
      http.get(`grupo/${id}`)
        .then(({ data }) => setFields({
          name: { value: data.nome },
          capacity: { value: data.capacidade },
          ecMin: { value: data.ecMinima },
          ecMax: { value: data.ecMaxima },
          phMin: { value: data.phMinimo },
          phMax: { value: data.phMaximo },
          temperatureMin: { value: data.tempMinima },
          temperatureMax: { value: data.tempMaxima },
          nutritiveSolution: { value: { value: data.solucao.id, label: data.solucao.nome } },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`groupo${id ? `/${id}` : ''}`, {
      organizacaoId: '7080d758-fe8a-11ea-adc1-0242ac120002',
      nome: fields.name.value,
      capacidade: fields.capacity.value,
      ecMinima: fields.ec.value[0],
      ecMaxima: fields.ec.value[1],
      phMinimo: fields.ph.value[0],
      phMaximo: fields.ph.value[1],
      tempMinima: fields.temperature.value[0],
      tempMaxima: fields.temperature.value[1],
      solucaoId: fields.nutritiveSolution.value.value,
    })
      .then(({ data }) => console.log(data))
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Group>
      <ItemPage {...itemPageObject} />
    </S.Group>
  );
};
