import React from 'react';

export default function MeasureIcon () {
  return (
  <svg width="73" height="74" viewBox="0 0 73 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M54.71 24.93L37.32 15.68C37.11 15.57 36.89 15.51 36.65 15.5L27.63 15.23C27.59 15.23 27.55 15.23 27.51 15.23L18.46 15.7C18.23 15.71 18 15.78 17.8 15.89L0.849998 25.31C0.459998 25.53 0.139998 25.89 0.0399983 26.32C-0.120002 27.03 0.229998 27.73 0.849998 28.03C0.939998 28.07 1.43 28.31 2.27 28.65L3.69 41.29C3.71 41.46 3.74 41.62 3.79 41.78C3.68 42.49 4.08 43.19 4.78 43.44C4.78 43.44 4.8 43.45 4.81 43.45C4.92 43.55 5.04 43.65 5.17 43.73C5.26 43.79 5.32 43.89 5.33 43.99L6.66 56.3C6.77 57.34 7.35 58.26 8.24 58.81L8.32 58.86C8.4 58.91 8.46 59 8.48 59.1L9.78 65.99C9.79 66.02 9.79 66.05 9.81 66.1C10.15 67.33 11.17 68.25 12.42 68.53L13.11 68.68C15.57 69.29 20.32 70.2 27.22 70.37C27.23 70.37 27.24 70.37 27.26 70.37C27.84 70.37 28.39 70.06 28.62 69.53C29.11 68.44 28.33 67.36 27.29 67.34C19.41 67.15 14.43 65.93 12.77 65.46C12.76 65.45 12.76 65.45 12.75 65.44L11.45 58.55C11.43 58.42 11.39 58.3 11.36 58.18C14.72 58.9 20.35 59.75 28.09 59.75C28.95 59.75 29.78 59.74 30.57 59.72C31.31 59.7 31.97 59.19 32.09 58.46C32.25 57.49 31.47 56.66 30.56 56.69C29.77 56.71 28.95 56.72 28.09 56.72C18.06 56.72 11.68 55.21 9.51 54.6L8.41 44.41C9.89 44.74 11.8 45.11 14.15 45.43C14.22 45.44 14.29 45.44 14.36 45.44C15.18 45.44 15.88 44.77 15.87 43.9C15.86 43.14 15.24 42.52 14.48 42.42C10.6 41.88 7.96 41.22 6.68 40.85L5.44 29.82C10.04 31.35 17.85 33.3 27.79 33.3C37.45 33.3 45.59 31.08 50.32 29.42L48.57 40.94C48.55 41.05 48.49 41.14 48.35 41.23C47.48 41.77 46.99 42.76 46.88 43.77L46.42 47.99C46.33 48.82 46.93 49.57 47.76 49.66C47.82 49.67 47.87 49.67 47.93 49.67C48.69 49.67 49.35 49.09 49.43 48.32L49.9 43.99C49.91 43.88 49.97 43.78 50.1 43.7C50.95 43.15 51.43 42.17 51.59 41.17L53.5 28.62C53.52 28.48 53.52 28.34 53.5 28.21C54.21 27.91 54.64 27.71 54.72 27.67C55.35 27.37 55.71 26.66 55.53 25.94C55.43 25.5 55.11 25.14 54.71 24.93ZM27.79 30.27C17.23 30.27 9.07 27.92 5.02 26.45L18.97 18.69L27.61 18.24L36.21 18.5L50.51 26.11C46.28 27.7 37.81 30.27 27.79 30.27Z" fill="#13739E"/>
      <path d="M50.99 10.36C48.58 10.36 46.61 8.4 46.61 5.98C46.61 3.56 48.57 1.6 50.99 1.6C53.4 1.6 55.37 3.56 55.37 5.98C55.37 8.4 53.4 10.36 50.99 10.36ZM50.99 4.62C50.24 4.62 49.63 5.23 49.63 5.98C49.63 6.73 50.24 7.34 50.99 7.34C51.74 7.34 52.35 6.73 52.35 5.98C52.35 5.23 51.74 4.62 50.99 4.62Z" fill="#13739E"/>
      <path d="M65.66 17.13H63.09C62.63 17.13 62.26 16.76 62.26 16.3C62.26 15.84 62.63 15.47 63.09 15.47H65.66C66.12 15.47 66.49 15.84 66.49 16.3C66.49 16.76 66.12 17.13 65.66 17.13Z" fill="#13739E"/>
      <path d="M65.66 10.75H63.09C62.63 10.75 62.26 10.38 62.26 9.92C62.26 9.46 62.63 9.09 63.09 9.09H65.66C66.12 9.09 66.49 9.46 66.49 9.92C66.49 10.38 66.12 10.75 65.66 10.75Z" fill="#13739E"/>
      <path d="M65.42 2.57C66.43 2.57 67.26 3.4 67.26 4.41V25.5C67.26 26.51 66.43 27.34 65.42 27.34C64.41 27.34 63.58 26.51 63.58 25.5V4.41C63.58 3.39 64.41 2.57 65.42 2.57ZM65.42 0C62.99 0 61.01 1.98 61.01 4.41V25.5C61.01 27.93 62.99 29.91 65.42 29.91C67.85 29.91 69.83 27.93 69.83 25.5V4.41C69.83 1.98 67.85 0 65.42 0Z" fill="#13739E"/>
      <path d="M65.66 7.57H63.09C62.63 7.57 62.26 7.2 62.26 6.74C62.26 6.28 62.63 5.91 63.09 5.91H65.66C66.12 5.91 66.49 6.28 66.49 6.74C66.49 7.19 66.12 7.57 65.66 7.57Z" fill="#13739E"/>
      <path d="M65.66 13.94H63.09C62.63 13.94 62.26 13.57 62.26 13.11C62.26 12.65 62.63 12.28 63.09 12.28H65.66C66.12 12.28 66.49 12.65 66.49 13.11C66.49 13.57 66.12 13.94 65.66 13.94Z" fill="#13739E"/>
      <path d="M65.66 20.32H63.09C62.63 20.32 62.26 19.95 62.26 19.49C62.26 19.03 62.63 18.66 63.09 18.66H65.66C66.12 18.66 66.49 19.03 66.49 19.49C66.49 19.95 66.12 20.32 65.66 20.32Z" fill="#13739E"/>
      <path d="M67.27 58.29C67.25 58.27 67.24 58.26 67.22 58.25C63.55 54.11 58.2 51.49 52.25 51.49C46.3 51.49 40.96 54.1 37.3 58.23C37.28 58.24 37.27 58.25 37.25 58.27C37.17 58.35 37.11 58.43 37.05 58.51C34.07 62 32.26 66.53 32.26 71.47C32.26 72.31 32.94 72.98 33.77 72.98C34.6 72.98 35.28 72.3 35.28 71.47C35.28 67.8 36.46 64.39 38.45 61.61L41.53 64.69C40.08 66.79 39.22 69.33 39.22 72.08C39.22 72.92 39.9 73.59 40.73 73.59C41.57 73.59 42.24 72.91 42.24 72.08C42.24 69.67 43.1 67.46 44.52 65.73C44.55 65.7 44.59 65.68 44.62 65.65C44.7 65.57 44.76 65.49 44.82 65.4C46.65 63.37 49.29 62.08 52.24 62.08C57.75 62.08 62.24 66.56 62.24 72.08C62.24 72.92 62.92 73.59 63.75 73.59C64.59 73.59 65.26 72.91 65.26 72.08C65.26 69.35 64.41 66.81 62.97 64.72L66.05 61.64C68.03 64.42 69.2 67.81 69.2 71.47C69.2 72.31 69.88 72.98 70.71 72.98C71.55 72.98 72.22 72.3 72.22 71.47C72.22 66.54 70.42 62.02 67.45 58.53C67.41 58.45 67.35 58.37 67.27 58.29ZM43.54 62.42L40.44 59.32C43.16 56.68 46.75 54.94 50.74 54.59V59.16C47.98 59.48 45.5 60.66 43.54 62.42ZM53.76 59.15V54.58C57.76 54.94 61.36 56.68 64.08 59.33L60.98 62.43C59.02 60.67 56.52 59.48 53.76 59.15Z" fill="#13739E"/>
      <path d="M65.66 23.5H63.09C62.63 23.5 62.26 23.13 62.26 22.67C62.26 22.21 62.63 21.84 63.09 21.84H65.66C66.12 21.84 66.49 22.21 66.49 22.67C66.49 23.13 66.12 23.5 65.66 23.5Z" fill="#13739E"/>
      <path d="M27.77 35.51C27.37 35.51 27 35.71 26.77 36.04L23.29 41.14C21.78 43.35 21.87 46.26 23.52 48.37C24.54 49.68 26.08 50.43 27.75 50.43C27.76 50.43 27.76 50.43 27.77 50.43C29.44 50.42 30.98 49.66 32 48.34L32.06 48.27C33.69 46.15 33.76 43.24 32.24 41.04L28.78 36.03C28.54 35.7 28.16 35.5 27.77 35.51ZM30.13 46.79L30.07 46.86C29.51 47.59 28.67 48 27.75 48.01H27.74C26.83 48.01 25.98 47.6 25.42 46.88C24.42 45.6 24.37 43.84 25.28 42.5L27.77 38.85L30.23 42.41C31.17 43.74 31.12 45.5 30.13 46.79Z" fill="#13739E"/>
    </g>
  </svg>
  )
}
