import React, { useEffect, useCallback, useState } from 'react';
import http from '../../service'
import ActivitysIcon from '../../images/VivariumIcon';
import * as S from './Activity.style';
import * as constants from './Activity.constants';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import ItemPageHeader from '../../Components/ItemPage/ItemPageHeader';
import CancelIcon from '../../images/Delete';
import SaveIcon from '../../images/Save';
import Button from '../../Components/Button';
import Field from '../../Components/Field';

export default function Activity({ setModal }) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { activityFieldsState, activityInfoFields, gridInfoTemplate, activityActionFields, gridActionTemplate, pageColor } = constants;
  const [fields, setFields] = useState(activityFieldsState);
  const itemInfoFields = activityInfoFields({ fields });
  const itemActionFields = activityActionFields({ fields });

  const getGreenhouse = useCallback((id) => http.get(`greenhouse/${id}`), [])

  useEffect(() => {
    http.get(`tasks/${id}`)
      .then(async ({ data }) => {
        let greenHouse;
        let sector;
        let stand;
        if (data.data.lots[0].greenhouseId) {
          greenHouse = await getGreenhouse(data.data.lots[0].greenhouseId).then(({ data }) => data?.data)
          sector = greenHouse.sectors.find((item) => item.id === data.data.lots[0].sectorId)
          stand = greenHouse.sectors.reduce((arr, item) => ([...arr, ...item.stand]), []).find((item) => item.id === data.data.lots[0].standId)
        } else {
          greenHouse = ''
          sector = ''
          stand = ''
        }
        setFields((oldFields) => {
          const newFields = { ...oldFields };
          newFields.code.value = data.data.lots[0].id;
          newFields.greenery.value = data.data.lots[0].greenery.name;
          newFields.currentCycle.value = data.data.lots[0].actualStage;
          newFields.greenHouse.value = greenHouse.name;
          newFields.sector.value = sector?.name;
          newFields.stand.value = stand?.name;
          newFields.initialAmount.value = data.data.lots[0].actualQuantity;
          newFields.activityType.value = data.data.type;
          newFields.activityId.value = data.data.id;
          newFields.nextCycle.value = data.data.lots[0].nextStage;
          return newFields;
        })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  const onSubmit = () => {
    const lotId = fields.code.value
    http.put(`lot${lotId ? `/${lotId}` : ''}`, {
      nextStage: fields.nextCycle.value,
      actualStage: fields.currentCycle.value,
      updateType: fields.activityType.value,
      actualQuantity: Number(fields.newAmount.value),
      greenhouseId: fields.newGreenHouse.value?.value,
      sectorId: fields.newSector.value?.value,
      standId: fields.newStand.value?.value,
      isSplited: fields.splitLot.value,
      activityEndDate: fields.newFrom.value,
      taskId: fields.activityId.value,
      activityNextDate: fields.activityNextDate.value,
      notes: fields.comments?.value
    }).then(() => {
      setModal({ isOpen: true, text: 'Atividade realizada com sucesso!', callBack: () => history.push(`/${router.url.split('/')[1]}`) })
    }, (error) => {
      setModal({ isOpen: true, text: error.response ? error.response.data.message : error.message })
    })

  }

  const disabled = Object.values(itemActionFields).some((field) => {
    if (field.validate) {
      const validation = field.type === 'select' ? field.validate(field.value?.value) : field.validate(field.value)

      return (validation.empty || (field.value && (validation.invalid || field.error)))
    }
  })

  return (
    <S.Activity>
      <S.ItemPageHeader color={pageColor}>
        <S.ItemPageHeaderTitle color={pageColor}>
          <ActivitysIcon />
          {`Atividades ${fields.activityType.value}`}
        </S.ItemPageHeaderTitle>
      </S.ItemPageHeader>
      <S.ItemPageTitle>Informações atuais</S.ItemPageTitle>
      <S.ItemInfoForm gridTemplate={gridInfoTemplate}>
        {Object.values(itemInfoFields)
          .filter((field) => field.value)
          .map((field) => <Field key={field.name} {...field} setFields={setFields} />)
        }
      </S.ItemInfoForm>
      <S.ItemPageTitle>{`Informações da ${fields.nextCycle.value === 'COLHIDO' ? 'Colheita' : `transferência para ${fields.nextCycle.value}`}`}</S.ItemPageTitle>
      <S.ItemPageForm gridTemplate={gridActionTemplate}>
        {Object.values(itemActionFields).map((field) => <Field key={field.name} {...field} setFields={setFields} />)}
      </S.ItemPageForm>
      <S.ItemPageButtons>
        <Button variation="primary" label="Salvar" disabled={disabled} rightIcon={SaveIcon} onClick={onSubmit} />
        <Button variation="danger" label="Voltar" rightIcon={CancelIcon} onClick={() => history.goBack()} />
      </S.ItemPageButtons>
    </S.Activity>
  );
};
