import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deburr } from "lodash";
import * as S from './Header.style';
import { useEffect } from 'react';

export default function HeaderListItem ({ label, icon: Icon, color, path, childs, setMenuIsOpen, menuIsOpen }) {
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isActive = deburr(path).toLowerCase().replace(/\s/g, "-") === `/${location.pathname.split('/')[1]}`;

  useEffect(() => {
    setItemIsOpen(false);
  }, [menuIsOpen]);

  return (
    <S.HeaderNavListItem 
      onClick={() => {
        if(path) {
          setMenuIsOpen(false);
          history.push(`${path}`);
        }
      }}
    >
      <S.HeaderNavListItemButton
        isActive={isActive}
        isDashboard={label === 'Dashboard'}
        isOpen={itemIsOpen}
        childs={childs}
        color={color}
        onClick={() => childs && setItemIsOpen(!itemIsOpen)}
      >
        { Icon && <Icon /> }
        {label}
      </S.HeaderNavListItemButton>
      { childs && (
        <S.HeaderNavListItemChilds isOpen={itemIsOpen}>
          { childs.map(({label: childLabel, icon: Icon, path}) => (
            <S.HeaderNavListItem 
              key={childLabel} 
              onClick={() => {
                if(path) {
                  setMenuIsOpen(false);
                  history.push(`${path}`);
                }
              }}
            >
              <S.HeaderNavListItemButton isChild color={color} >
                { Icon && <Icon /> }
                { childLabel }
              </S.HeaderNavListItemButton>
            </S.HeaderNavListItem>
          )) }
        </S.HeaderNavListItemChilds>
      ) }
    </S.HeaderNavListItem>
  )
}