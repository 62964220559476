import React from 'react';
import FieldInput from './FieldInput';
import Button from '../Button';
import Tabs from '../Tabs';
import FieldCaption from './FieldCaption';
import FieldError from './FieldError';
import FieldBigNumber from './FieldBigNumber';
import FieldSubFields from './FieldSubFields';
import FieldTextarea from './FieldTextarea';
import FieldMeasureRef from './FieldMeasureRef';

export default function Field(props) {
  switch (props.type) {
    case 'id': return null
    case 'button': return <Button {...props} />
    case 'tabs': return <Tabs {...props} />
    case 'caption': return <FieldCaption {...props} />
    case 'error': return <FieldError {...props} />
    case 'bigNumber': return <FieldBigNumber {...props} />
    case 'subFields': return <FieldSubFields {...props} />
    case 'textarea': return <FieldTextarea {...props} />
    case 'measureRef': return <FieldMeasureRef {...props} />
    default: return <FieldInput {...props} />;
  }
}
