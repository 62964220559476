import React, { useState } from 'react';
import * as S from './ConfirmQuantidadeQrCode.style';
import Field from './../Field';
import Button from './../Button';
import { format } from 'date-fns';
import http from '../../service'

export default function ConfirmQuantidadeQrCode ({ setModal, item }) {
  const [fields, setFields] = useState({ date: { value: '' } })

  return (
    <S.ExecuteModal>
      <S.ExecuteCall>Digite a quantidade de QR Code a serem criados.</S.ExecuteCall>
      <Field label="Quantidade" name="date" type="number" value={fields.date.value} setFields={setFields} />
      <Button name="cancel" label="Cancelar" variation="danger" onClick={() => setModal({ isOpen: false })}/> 
      <Button 
        name="submit" 
        label="Imprimir"
        disabled={fields.date.value < 1}
        variation="primary" 
        onClick={ () => {
            http.get(`${item.url}/qrcode/${item.id}/${fields.date.value}`)
            .then(({ data }) => {
              const linkSource = `data:application/octet-stream;base64,${data.data.report}`;
              const downloadLink = document.createElement('a');
              const fileName = `${data.data.name}`;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();

              setModal({ isOpen: false })
            })
            .catch((err) => setModal({ isOpen: true, text: err.message }))  
          }  

        }/> 
    </S.ExecuteModal>
  )
}