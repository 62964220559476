import styled from 'styled-components';

export const PostponeModal = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas: 
    "postponeCall postponeCall"
    "date date"
    "cancel submit";
`

export const PostponeCall = styled.p`
  grid-area: postponeCall;
`