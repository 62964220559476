import React, { useState, useEffect } from "react";
import http from '../../../service'
import DashboardTitle from "../DashboardTitle";
import * as S from "./DashboardOccupation.style";
import DashboardLosts from '../DashboardLosts';
import Button from "../../Button";
import { useHistory } from 'react-router-dom';

const DashboardOccupation = ({ setModal }) => {
  const history = useHistory();
  const [occupation, setOccupation] = useState([]);


  useEffect(() => {
    http.get(`greenhouse/greenhouseOccupacy`)
      .then(({ data }) => setOccupation(data.data))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  let totalOccupancyPercentual = 0;
  if (occupation?.length) {
    if (occupation[0].sectors?.length) {
      totalOccupancyPercentual = occupation[0].sectors[0].totalOccupancyPercentual;
    }
  }

  return (
    <S.DashboardOccupation>
      <DashboardTitle>
        Ocupação total das estufas
      </DashboardTitle>
      <S.DashboardOccupationTotalValue>{totalOccupancyPercentual}%</S.DashboardOccupationTotalValue>
      <S.DashboardOccupationList>
        {occupation.splice(0, 5).map((greenhouse) => (
          <S.DashboardOccupationInfo>
            <S.DashboardOccupationInfoLabel>{greenhouse.sectors[0].greenhouse_name}</S.DashboardOccupationInfoLabel>
            <S.DashboardOccupationInfoValue>{greenhouse.sectors[0].percentualOccupancy.toFixed(2)}%</S.DashboardOccupationInfoValue>
          </S.DashboardOccupationInfo>
        ))}
      </S.DashboardOccupationList>
      <Button label=". . . ver mais" onClick={() => history.push('/indicadores/ocupacao')} />
      <DashboardLosts setModal={setModal} />
    </S.DashboardOccupation>
  );
};

export default DashboardOccupation;
