import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from './Routes.constants';
import Header from '../Components/Header';
import Login from '../Pages/Login';
import Farm from '../Pages/Farm';
import Modal from '../Components/Modal';
import * as S from './Routes.style';
import { useHistory } from 'react-router-dom';

export default function Routes() {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')));
  const [modal, setModal] = useState({ isOpen: false });
  const { location } = useHistory();

  let qrCodeControl = false;
  let qrCodeId;
  let qrCodeType;

  if (location.pathname.includes('/hortalicas/validar')) {
    qrCodeId = location.pathname.split('/')[3];
    qrCodeControl = true;
    qrCodeType = 'expedition';
  }

  else if (location.pathname.includes('/hortalicas/pesquisarhortalica')) {
    qrCodeId = location.pathname.split('/')[3];
    qrCodeControl = true;
    qrCodeType = 'greenery';
  }

  if (qrCodeControl && qrCodeId) {
    return (
      <Login qrCode={qrCodeId} qrCodeType={qrCodeType} setCurrentUser={setCurrentUser} />
    )
  }
  else {
    return (
      <>
        { currentUser
          ? (
            <>
              <Header setCurrentUser={setCurrentUser} />
              <S.Routes>
                {!currentUser.organization
                  ? <Farm setCurrentUser={setCurrentUser} setModal={setModal} />
                  : (
                    <Switch>
                      { routes.map((route) => <Route key={route.path} path={route.path} setModal={setModal} render={props => <route.component {...props} setModal={setModal} setCurrentUser={setCurrentUser} />} />)}
                    </Switch>
                  )
                }
              </S.Routes>
              <Modal modal={modal} setModal={setModal} />
            </>
          ) : <Login setCurrentUser={setCurrentUser} />
        }
      </>
    )
  }
}