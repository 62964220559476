import React from 'react';
import Input from '../Input';
import * as S from './Field.style';

export default function Field({label, unity, color, tip, error, errorMessage, ...props}) {
  const { name } = props;
  return (
    <S.FieldBigNumber name={name} color={color}>
      { error && <S.FieldError>{errorMessage}</S.FieldError> }
      { label && <S.FieldBigNumberLabel>{label}</S.FieldBigNumberLabel> }
      <Input {...props} type="number" />
      { unity && <S.FieldBigNumberLabel>{unity}</S.FieldBigNumberLabel> }
    </S.FieldBigNumber>
  );
}
