import React, { useState } from "react";
import * as S from "./Tabs.style";

const Tabs = ({ color, onClick, tabs = [], label, name }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <S.Tabs name={name}>
      <S.TabsHeader>
        {label}
        { tabs.map((tab, index) => (
          <S.TabsHeaderItem
            key={tab}
            isActive={index === activeTab}
            color={color}
            onClick={() => {setActiveTab(index); onClick(index)}}
          >
            {tab}
          </S.TabsHeaderItem>
        ))}
      </S.TabsHeader>
    </S.Tabs>
  );
};

export default Tabs;
