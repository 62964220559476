import styled from 'styled-components';

export const ExecuteModal = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas: 
    "executeCall executeCall"
    "date date"
    "cancel submit";
`

export const ExecuteCall = styled.p`
  grid-area: executeCall;
`