import styled from 'styled-components';

export const Login = styled.div`
  min-width: 100vw;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({theme: { color: { brandGradient }}}) => brandGradient};
  flex-direction: column;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenS } }}) => screenS}) {
      flex-direction: row;
    }
  }
`

export const LoginSideA = styled.div`
  width: 100vw;
  height: 150px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 50px 0;
  position: relative;
  box-shadow: ${({theme: { boxShadow: { boxShadowLG }}}) => boxShadowLG};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenS } }}) => screenS}) {
      width: 40vw;
      height: 100%;    
    }
  }

`
export const LogoForm = styled.img`
  position: absolute;
  right: 20px;
  width: 118px;
  object-fit: cover;
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenS } }}) => screenS}) {
      right: 30px;
      width: 130px;  
    }
  }

`
export const LoginSideB = styled.div`
    /* width: 60vw; */
    /* max-width: ${({theme: { screen: { screenXS }}}) => screenXS}; */
    height: auto;
    position: relative;
    margin: auto 50px;
    width: 250px;


  >section{
    h1 {
      color: white;
    }

    input {
      background-color: white;
      border: none;
    }
  }


`
export const LoginWelcome = styled.p`
  position: absolute;
  margin-left: 60px;
  font-size: ${({theme: { fontSize: { fontSizeXS }}}) => fontSizeXS};
  font-size: 16px;
  color: ${({theme: { color: { mediumGray }}}) => mediumGray};
  
  @media screen {
    @media (min-width: ${({ theme: { screen: { screenS } }}) => screenS}) {
      font-size: ${({theme: { fontSize: { fontSizeS }}}) => fontSizeS};  
    }
  }

  ::before{
    content: '';
    width: 300px;
    height: 2px;
    background-color: ${({theme: { color: { brand }}}) => brand};
    position: absolute;
    left: -320px;
    top: .5em;
  }
  ::after{
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: ${({theme: { color: { brand }}}) => brand};
    position: absolute;
    left: -20px;
    top: 0.3em;
  }
  

`
export const TextForm = styled.p`
  position: absolute;
  z-index: 1;
  text-align: left;
  margin: 20px 16px 0;
  font-size: ${({theme: { fontSize: { fontSizeXXS }}}) => fontSizeXXS};
  color: ${({theme: { color: { brandDark }}}) => brandDark};
  text-align: center;
`
export const Signup = styled.div`
  font-size: ${({theme: { fontSize: { fontSizeXXXS }}}) => fontSizeXXXS};
  color: ${({theme: { color: { white }}}) => white};
  width: 250px;
  text-align: center;
  >p{
    margin: 30px;
    margin-bottom: 10px;
    font-weight: ${({theme: { fontWeight: {fontWeightBold}}}) => fontWeightBold};
  }
  Button{
    margin: auto;
    box-shadow: none;
    width: 200px;
  }
`