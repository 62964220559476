import React, { useEffect, useState } from 'react';
import http from '../../service'
import ItemPage from '../../Components/ItemPage';
import * as constants from './Shipping.constants';
import * as S from './Shipping.style';
import { useParams, useHistory } from 'react-router-dom';
import * as moment from 'moment';

export default function Shipping ({ setModal }) {
  const { id } = useParams();
  const { shippingFieldsState, shippingFields } = constants;
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(shippingFieldsState);
  const itemFields = shippingFields({fields});
  const history = useHistory();

  useEffect(() => {

    if(id) {
      http.get(`expedition/${id}`)
        .then(({ data }) => {
        let dataExp = moment(data.data.expeditionDate);
        setFields({
           customer: { value: { value: data.data.customer.id, label: data.data.customer.name }, disabled: true,},
           docNumber: { value: data.data.invoiceNumber },
           lot: { value: { value: data.data.lot.id, label: data.data.lot.id }, disabled: true},
           shippingDate: { value: new Date(dataExp) },
           quantity: { value: data.data.quantity },
           hortalica: { value: { value: data.data.lot.greenery.id, label: data.data.lot.greenery.name }, disabled: true}
        })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'put' : 'post'](`expedition${id ? `/${id}` : ''}`, {
      customerId: fields.customer.value.value,
      invoiceNumber: fields.docNumber.value,
      lotId: fields.lot.value.value,
      expeditionDate: fields.shippingDate.value,
      quantity: Number(fields.quantity.value)
    })
      .then(() => {
        setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
      })
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }

  const itemPageObject = { ...constants, item, fields: itemFields, setItem, setFields, onSubmit }

  return (
    <S.Shipping>
      <ItemPage {...itemPageObject} />
    </S.Shipping>
  );
};
