import React, { useEffect, useState } from 'react';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import ShippingsIcon from '../../images/ManufacturerIcon';
import * as S from './Shippings.style';
import * as constants from './Shippings.constants';
import * as moment from 'moment';

export default function Shippings ({setModal}) {
  const {shippingsSearchFieldsState, shippingsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(shippingsSearchFieldsState);
  const searchFields = shippingsSearchFields({fields, setList, setModal});
  const url = 'expedition'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: ShippingsIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url)
      .then(({ data }) => { 
        let obj = [];
        data.data.forEach( el => {
          obj.push({
            customerName: el.customer.name,
            invoiceNumber: el.invoiceNumber,
            lotId: el.lotId,
            quantity: el.quantity,
            id: el.id,
            expeditionDate: moment(el.expeditionDate).format('DD-MM-YYYY'),
            greeneryName: el.lot.greenery.name
          })
        })
        setList(obj.filter((item) => item))
      })
      .catch((err) => setError(err))
  }, [])

  return (
    <S.Shippings>
      <ListPage {...listPageObject} />
    </S.Shippings>
  );
};
