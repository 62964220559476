import React, { useEffect, useState } from 'react';
import http from '../../../service'
import DashboardCard from "../DashboardCard";
import DashboardTitle from "../DashboardTitle";
import * as S from "./DashboardIndicators.style";
import MaternityIcon from "../../../images/MaternityIcon";
import VivariumIcon from "../../../images/VivariumIcon";
import NurseryIcon from "../../../images/NurseryIcon";
import FinalPhaseIcon from "../../../images/FinalPhaseIcon";

const DashboardIndicators = ({ setModal }) => {
  const [dashboardCards, setDashboardCards] = useState([
    { title: "Maternidade", units: '-', color: "#16c4c4", icon: MaternityIcon, link: '/indicadores/maternidade' },
    { title: "Viveiro", units: '-', color: "#13739e", icon: VivariumIcon, link: '/indicadores/viveiro' },
    { title: "Berçario", units: '-', color: "#1e7c4d", icon: NurseryIcon, link: '/indicadores/bercario' },
    { title: "Fase final", units: '-', color: "#8bc53f", icon: FinalPhaseIcon, link: '/indicadores/fase-final' },
  ])

  useEffect(() => {
    http.get(`lot/stagesTotal`)
      .then(({ data }) => setDashboardCards((oldCards) => {
        const newCards = [...oldCards];
        newCards[0].units = data.data.maternidade.quantity;
        newCards[1].units = data.data.viveiro.quantity;
        newCards[2].units = data.data.bercario.quantity;
        newCards[3].units = data.data.final.quantity;
        return newCards
      }))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  return (
    <S.DashboardIndicators>
      <DashboardTitle>Indicadores hoje</DashboardTitle>
      <S.DashboardIndicatorsCards>
        {dashboardCards.map((dashboardCard) => (
          <DashboardCard key={dashboardCard.title} {...dashboardCard} />
        ))}
      </S.DashboardIndicatorsCards>
    </S.DashboardIndicators>
  );
};

export default DashboardIndicators;
