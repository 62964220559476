import React from 'react';

export default function CheckBoxIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <title>check_box</title>
      <desc>Created with Sketch.</desc>
      <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Outlined" transform="translate(-103.000000, -4323.000000)">
          <g id="Toggle" transform="translate(100.000000, 4266.000000)">
            <g id="Outlined-/-Toggle-/-check_box" transform="translate(0.000000, 54.000000)">
              <g>
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z M17.99,9 L16.58,7.58 L9.99,14.17 L7.41,11.6 L5.99,13.01 L9.99,17 L17.99,9 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
