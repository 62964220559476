import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'
export const pageLabel = {
  name: 'measures',
  singular: 'controle de PH & EC',
  plural: 'controles de PH & EC'
};

export const measuresSearchFieldsState = () => ({
  query: { value: '' },
});

export const measuresSearchFields = ({ fields }) => ({
  query: { 
    ...fields.query,
    name: 'query',
    label: 'Reservatório',
    placeholder: 'Digite',
    loadEmpty: true,
    isSearchable: true,
    loadOptions: (query, callback) => {
      http.get('watertank')
        .then(({data}) => callback(data.data.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch(({error}) => callback([]))
    },
  },
});

export const cardInfos = [
  { fieldLabel: 'Data', fieldType: 'measuredAt' },
  { fieldLabel: 'Reservatório', fieldType: 'watertank' },
  { fieldLabel: 'EC', fieldType: 'ec' },
  { fieldLabel: 'Temperatura', fieldType: 'temperature' },
  { fieldLabel: 'PH', fieldType: 'ph' },
  { fieldLabel: 'Ação', fieldType: 'action' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } }}) => screenXS}) {
      grid-template: ${`
        "query ."
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "measuredAt measuredAt watertank"
    "ec ph temperature"
    "action action action"
  ;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen {  
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "measuredAt watertank ec ph temperature action"
      ;
      grid-template-columns: 120px 2fr 1fr 1fr 1fr 140px;
    }
  }
`
