import React from "react";
import ListPageCard from './ListPageCard';
import * as S from './ListPageCards.style';

export default function ListPageCards({list, ...listPageProps}) {
  const query = Object.values(listPageProps.fields).reduce((bool, item) => item.value,false)

  return (
    <S.ListPageCards>
      { !listPageProps?.error || listPageProps?.error?.response?.status === 404
        ? !!list?.length 
          ? list?.map((card, index) => <ListPageCard key={card.id} {...card} {...listPageProps} index={index} />)
          : query ? 'Sem resultados para esta busca' : `Sem ${listPageProps.pageLabel.plural} cadastrados`
        : `Problemas para carregar os ${listPageProps.pageLabel.plural}`
      }
    </S.ListPageCards>
  );
}
