import React from 'react';
import { inputChange, inputValidate } from '../../helpers/fieldFunctions';
import * as S from './Input.style';

export default function InputText({ onChange, validate, setFields, onBlur, i, parentI, ...props }) {

  return (
    <S.InputText
      {...props}
      onChange={({ target }) => inputChange({ target: { ...target, i, parentI, name: props.name }, setFields, onChange })}
      onBlur={({ target }) => inputValidate({ target: { ...target, i, parentI, name: props.name }, setFields, validate, onBlur })}
      maskChar=""
    />
  );
}
