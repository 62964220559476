import React from 'react';
import * as S from './Field.style';

export default function FieldMeasureRef({label, value, minStd, maxStd, color, recomendation, tip, error, errorMessage, ...props}) {
  const { name } = props;
  const valueRef = !minStd || !maxStd ? 'Sem Valor de referência' : `${minStd} a ${maxStd}`

  if (value) {
    return (
      <S.FieldMeasureRef name={name}>
        { label && <S.FieldMeasureRefLabel color={color}>{label}</S.FieldMeasureRefLabel> }
        { value && <S.FieldMeasureRefLabel color={color}>{value}</S.FieldMeasureRefLabel> }
        { valueRef && (
          <S.FieldMeasureRefValueRef color={color}>
            <p>Valor de referência</p>
            <S.FieldMeasureRefValue color={color}>{valueRef}</S.FieldMeasureRefValue>
          </S.FieldMeasureRefValueRef>
        )}
        <S.FieldMeasureRefRecomendation color={color}>
          <p>Ação recomendada</p>
          <S.FieldMeasureRefValue color={color}>{recomendation || 'Sem ação recomendada'}</S.FieldMeasureRefValue>
        </S.FieldMeasureRefRecomendation>
      </S.FieldMeasureRef>
    );
  } else return null
}
