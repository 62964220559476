import React, { useState, useEffect } from 'react';
import LoginForm from '../../Components/LoginForm';
import * as constants from './Login.constants';
import Button from '../../Components/Button/Button'
import * as S from './Login.style';
import SohlLogo from '../../images/sohlLogo.svg'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { constant } from 'lodash';
import http from '../../../src/service';
import * as moment from 'moment';

export default function Login({ setCurrentUser, qrCode, qrCodeType }) {
  const [fields, setFields] = useState(constants.loginFieldsState());
  const [signUp, setSignUp] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const loginFields = constants.loginFields({ fields, qrCode, qrCodeType, setCurrentUser, setForgot, forgot, error, setError, setLoading, signUp, loading, recovered, setRecovered, setFields })
  const formObject = { ...constants, fields: loginFields, setFields, qrCode, qrCodeType, loading, error, signUp };

  const { location } = useHistory();


  useEffect(() => {
    if (location.pathname === '/login/token') {
      setRecovered(true)
    }

    if (qrCode) {

      if (qrCodeType === 'greenery') {
        http.get(`externalservices/getgreenerydetails/${qrCode}`)
          .then(({ data }) => {
            setFields({
              hortalica: { value: data.data.greenery_name },
              produzido: { value: data.data.farm_name },
              cpfCnpj: { value: data.data.cpfCnpj },
              endereco: { value: data.data.adreess },
            })
          })
          .catch((err) => console.log(err))
      }
      else {
        http.get(`externalservices/getlotdetails/${qrCode}`)
          .then(({ data }) => {
            setFields({
              lote: { value: data.data.lotId },
              hortalica: { value: data.data.greenery_name },
              data: { value: data.data.haverstDate ? moment(data.data.haverstDate).format('DD-MM-YYYY') : '' },
              produzido: { value: data.data.farm_name },
              cpfCnpj: { value: data.data.cpfCnpj },
              endereco: { value: data.data.adreess },
            })
          })
          .catch((err) => console.log(err))
      }


    }
  }, [qrCode])

  if (qrCode) {
    return (
      <S.Login>
        <S.LoginSideA>
          <S.LoginWelcome>Bem-vindo ao</S.LoginWelcome>
          <S.LogoForm src={SohlLogo} />
        </S.LoginSideA>
        <S.LoginSideB>
          <S.TextForm>
            Confira aqui os dados da hortaliça e do produto
          </S.TextForm>
          <LoginForm {...formObject} />
        </S.LoginSideB>
      </S.Login>
    );
  }

  else {
    return (
      <S.Login>
        <S.LoginSideA>
          <S.LoginWelcome>Bem-vindo ao</S.LoginWelcome>
          <S.LogoForm src={SohlLogo} />
        </S.LoginSideA>
        <S.LoginSideB>
          <S.TextForm>
            {recovered ? 'Verifique seu email e clique no link ou preencha abaixo' : forgot
              ? 'Informe seu email para recuperar a senha do sistema SOhL'
              : signUp
                ? 'Informe um email e senha para se cadastrar no sistema SOhL'
                : 'Informe seu email e senha para acessar o sistema SOhL'
            }
          </S.TextForm>
          <LoginForm {...formObject} />
          <S.Signup>
            {signUp ? <p>Já é cadastrado?<br />acesse a plataforma</p> : <p>Não é cadastrado?<br />Conheça nossos planos</p>}
            <Button
              label={signUp ? 'Faça login' : 'Cadastre-se'}
              variation='warning'
              onClick={() => { setRecovered(false); setForgot(false); setError(''); setSignUp(!signUp) }}
            />
          </S.Signup>
        </S.LoginSideB>
      </S.Login>
    );
  }
}
