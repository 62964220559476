import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'newActivity',
  singular: `atividade`,
  plural: `atividades`,
  newLabel: 'Nova',
  editLabel: 'Editar'
};

export const newActivityFieldsState = () => ({
  title: { value: '' },
  plannedDate: { value: new Date() },
  frequency: { value: '1' },
  occurrency: { value: ''},
  endDate: { value: '' },
  description: { value: '' },
});

export const newActivityFields = ({ fields }) => {
  const newFields = ({
    title: { 
      ...fields.title,
      name: 'title',
      label: 'Titulo',
      placeholder: 'Digite',
      validate: (value) => ({ empty: !value }), 
    },
    plannedDate: { 
      ...fields.plannedDate,
      name: 'plannedDate',
      label: 'Data planejada',
      type: 'date',
      validate: (value) => ({ empty: !value }), 
    },
    frequency: { 
      ...fields.frequency,
      name: 'frequency',
      label: 'Frequência',
      placeholder: 'Digite',
      type: 'number',
      suffix: ' dias',
      validate: (value) => ({ empty: !value,
                              invalid: Number(value.split(' ')[0]) < 1, 
                              invalidMessage: 'Preencher número maior que 0.'      
                            }), 
    },
    description: { 
      ...fields.description,
      name: 'description',
      label: 'Descrição',
      placeholder: 'Digite',
      type: 'textarea',
    }
  })

  const isOccurrency = fields.frequency.value > 1 && (fields.endDate.value === '' || !fields.endDate.value);
  const isEndDate = fields.frequency.value > 1 && fields.occurrency.value === '';
  if(!isOccurrency){
    fields.occurrency.value = '';
  }
  if(!isEndDate){
    fields.endDate.value = '';
  }
  if(isOccurrency) {
    newFields.occurrency = { 
      ...fields.occurrency,
      name: 'occurrency',
      label: 'Ocorrência',
      placeholder: 'Digite',
      type: 'number',
      suffix: ' vezes',
      validate: (value) => ({ empty: fields.frequency.value !== '' && !value && fields.isEndDate?.value !== '',
                              invalid: Number(value.split(' ')[0]) < 1, 
                              invalidMessage: 'Preencher número maior que 0.'
                            }), 
    }
  }

  if(isEndDate) {
    newFields.endDate = { 
      ...fields.endDate,
      name: 'endDate',
      label: 'Data de Encerramento',
      type: 'date',
      validate: (value) => ({ empty: fields.frequency.value !== '' && !value && fields.occurrency?.value !== '',
                              invalid: fields.plannedDate.value > value, 
                              invalidMessage: 'A data de encerramento deve maior que a data planejada.'       
                            }), 
    }
  }
  return newFields
};

export const gridTemplate = css`
  grid-template: ${`
    "title" 
    "plannedDate"
    "frequency"
    "occurrency"
    "endDate" 
    "description"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "title plannedDate plannedDate" 
        "frequency occurrency endDate"
        "description description description"
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`
