import SearchIcon from '../../images/SearchIcon';
import { css } from 'styled-components';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'waterTanks',
  singular: `caixa d'água`,
  plural: `caixas d'água`
};

export const waterTanksSearchFieldsState = () => ({
  query: { value: '' },
});

export const waterTanksSearchFields = ({ fields }) => ({
  query:{
    ...fields.query,
    name: 'query',
    label: 'Nome',
    placeholder: 'Digite',  
  },
});

export const cardInfos = [
  { fieldLabel: 'Nome', fieldType: 'name' },
  { fieldLabel: 'Capacidade', fieldType: 'capacity' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } }}) => screenXS}) {
      grid-template: ${`
        "query ."
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "name name capacity capacity"
    "history history delete delete"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr ;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "name capacity history delete"
      ;
      grid-template-columns: 1fr 1fr 40px 40px;
    }
  }
`
