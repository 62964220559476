import styled from "styled-components";
import { lighten } from 'polished'
import Button from '../Button';

export const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000000aa};
  z-index: 999;
`;

export const ModalContainer = styled.div`
  width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  background-color: white;
  transform: translateY(calc(${({ transform }) => transform} - 100px));
  margin: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.div`

`;

export const ModalText = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalButton = styled(Button)`

`;

export const ModalClose = styled.button`
  position: absolute;
  top: 4px;
  right: 12px;
  font-weight: bolder;
  font-size: 34px;
  transform: rotate(45deg);
`;



