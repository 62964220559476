import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';
import http from '../../service'
import { subDays, addDays } from 'date-fns';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'PlantingLotsReport',
  singular: 'colheita',
  plural: 'colheitas'
};

export const PlantingLotsReportSearchFieldsState = () => ({
  startDate: { value: undefined },
  endDate: { value: addDays(new Date(), 30) },
  greeneryId: { value: '' },
  greenhouseId: { value: '' },
});

export const PlantingLotsReportSearchFields = ({ fields, setList, setModal }) => ({
  startDate: {
    ...fields.startDate,
    name: 'startDate',
    label: 'De',
    type: 'date',
  },
  endDate: {
    ...fields.endDate,
    name: 'endDate',
    label: 'Até',
    type: 'date',
  },
  greeneryId: {
    ...fields.greeneryId,
    name: 'greeneryId',
    label: 'Hortaliça',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isClearable: true,
    isSearchable: false,
    loadOptions: (query, callback) => {
      http.get('greeneries')
        .then(({ data }) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    },
  },
  greenhouseId: {
    ...fields.greenhouseId,
    name: 'greenhouseId',
    label: 'Estufa',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    loadOptions: (query, callback, setFields) => {
      http.get('greenhouse')
        .then(({ data }) => {
          callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id })))
          setFields((oldFields) => {
            const newFields = { ...oldFields };
            newFields.greenhouseId.options = data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))
            return newFields
          })
        })
        .catch((err) => callback([]))
    },
  },
  submitButton: {
    name: 'submitButton',
    label: 'Pesquisar',
    type: 'button',
    variation: 'primary',
    rightIcon: SearchIcon,
    onClick: () => {
      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        greeneryId: fields.greeneryId.value.value,
        greenhouseId: fields.greenhouseId.value.value,
        isHarvestReport: true,
      }
      http.get('lot', { params })
        .then(({ data }) => setList(data.data.map(item => ({ ...item, greenhouse: fields.greenhouseId.options.find((option) => option.value === item.greenhouseId) }))))
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  },
  generatePDF: {
    name: 'generatePDF',
    label: 'PDF',
    type: 'button',
    variation: 'primary',
    onClick: () => {
      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        greeneryId: fields.greeneryId.value.value,
        greenhouseId: fields.greenhouseId.value.value,
        isHarvestReport: true,
      }
      http.get(`/lot/lotsreport`, { params })
        .then(({ data }) => {
          const linkSource = `data:application/octet-stream;base64,${data.data.report}`;
          const downloadLink = document.createElement('a');
          const fileName = `${data.data.name}`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }
});

export const cardInfos = [
  { fieldLabel: 'Hortaliça', fieldType: 'greenery' },
  { fieldLabel: 'Previsão', fieldType: 'haverstPlanDate' },
  { fieldLabel: 'Estufa', fieldType: 'greenHouse' },
  { fieldLabel: 'Setor', fieldType: 'sector' },
  { fieldLabel: 'Banca', fieldType: 'stand' },
  { fieldLabel: 'Quantidade', fieldType: 'actualQuantity' },
  { fieldLabel: 'Caixas', fieldType: 'boxes' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "startDate"
    "endDate"
    "greeneryId"
    "greenhouseId"
    "submitButton"
    "generatePDF"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } } }) => screenXS}) {
      grid-template: ${`
        "startDate endDate ."
        "greeneryId greenhouseId ."
        "submitButton generatePDF ."
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "greenery greenery haverstPlanDate greenHouse"
    "sector stand actualQuantity boxes"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template:
        "greenery haverstPlanDate greenHouse sector stand actualQuantity boxes"
      ;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
`
