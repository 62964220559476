import React from 'react';

export default function DashBoardIcon () {
  return (
    <svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M65.09 52.27H36.66C35.89 52.27 35.27 51.65 35.27 50.88V1.39C35.27 0.62 35.89 0 36.66 0H65.09C65.86 0 66.48 0.62 66.48 1.39V22.16C66.48 22.93 65.86 23.55 65.09 23.55C64.32 23.55 63.7 22.93 63.7 22.16V2.79H38.05V49.48H65.09C65.86 49.48 66.48 50.1 66.48 50.87C66.48 51.64 65.86 52.27 65.09 52.27Z" fill="#FFD255"/>
        <path d="M29.83 80.14H1.39C0.62 80.14 0 79.52 0 78.75V36.8C0 36.03 0.62 35.41 1.39 35.41H29.82C30.59 35.41 31.21 36.03 31.21 36.8V78.75C31.22 79.52 30.6 80.14 29.83 80.14ZM2.79 77.35H28.44V38.19H2.79V77.35Z" fill="#FFD255"/>
        <path d="M58.82 79.17C58.6 79.17 58.38 79.12 58.17 79.01L51.71 75.61L45.25 79.01C44.78 79.26 44.21 79.22 43.78 78.9C43.35 78.59 43.14 78.06 43.23 77.54L44.46 70.35L39.23 65.25C38.85 64.88 38.71 64.33 38.88 63.82C39.04 63.32 39.48 62.95 40.01 62.87L47.23 61.82L50.46 55.27C50.69 54.79 51.18 54.49 51.71 54.49C52.24 54.49 52.73 54.79 52.96 55.27L56.19 61.82L63.41 62.87C63.93 62.95 64.37 63.31 64.54 63.82C64.7 64.32 64.57 64.88 64.19 65.25L58.96 70.35L60.19 77.54C60.28 78.06 60.06 78.59 59.64 78.9C59.4 79.08 59.11 79.17 58.82 79.17ZM51.71 72.64C51.93 72.64 52.16 72.69 52.36 72.8L56.97 75.22L56.09 70.09C56.01 69.64 56.16 69.18 56.49 68.86L60.22 65.22L55.07 64.47C54.62 64.4 54.22 64.12 54.02 63.71L51.71 59.04L49.4 63.71C49.2 64.12 48.8 64.41 48.35 64.47L43.2 65.22L46.93 68.86C47.26 69.18 47.41 69.64 47.33 70.09L46.45 75.22L51.06 72.8C51.26 72.69 51.49 72.64 51.71 72.64Z" fill="#FFD255"/>
        <path d="M27.12 11.34C26.56 11.34 26.04 11 25.82 10.46C24.45 6.97 21.48 4.3 17.87 3.32C17.13 3.12 16.69 2.35 16.89 1.61C17.09 0.87 17.86 0.43 18.6 0.63C23.05 1.85 26.71 5.14 28.41 9.44C28.69 10.16 28.34 10.97 27.62 11.25C27.47 11.31 27.29 11.34 27.12 11.34Z" fill="#FFD255"/>
        <path d="M14.72 29.54C6.6 29.54 0 22.94 0 14.82C0 8.11 4.54 2.24 11.03 0.569999C11.78 0.379999 12.54 0.829999 12.73 1.57C12.92 2.32 12.47 3.08 11.73 3.27C6.46 4.63 2.79 9.38 2.79 14.82C2.79 21.4 8.14 26.75 14.72 26.75C21 26.75 26.23 21.85 26.63 15.59C26.68 14.82 27.34 14.24 28.11 14.29C28.88 14.34 29.46 15 29.41 15.77C28.91 23.49 22.46 29.54 14.72 29.54Z" fill="#FFD255"/>
      </g>
    </svg>
  )
}
