import SearchIcon from '../../images/SearchIcon';
import { css } from 'styled-components';
import http from '../../service'
import { subDays, addDays } from 'date-fns';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'ActivitiesReport',
  singular: 'atividade',
  plural: 'atividades'
};

export const ActivitiesReportSearchFieldsState = () => ({
  startDate: { value: subDays(new Date(), 30) },
  endDate: { value: addDays(new Date(), 30) },
  status: { value: '' },
  reportActivityType: { value: '' },
  greenhouseId: { value: '' },
  sectorId: { value: '' },
});

export const ActivitiesReportSearchFields = ({ fields, setList, setModal }) => ({
  startDate: {
    ...fields.startDate,
    name: 'startDate',
    label: 'De',
    type: 'date',
  },
  endDate: {
    ...fields.endDate,
    name: 'endDate',
    label: 'Até',
    type: 'date',
  },
  status: {
    ...fields.status,
    name: 'status',
    label: 'Status',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    options: [
      { value: 'OPEN', label: 'A fazer' },
      { value: 'REPLANNED', label: 'Remarcada' },
      { value: 'IN_PROGRESS', label: 'Fazendo' },
      { value: 'DONE', label: 'Feita' },
    ]
  },
  reportActivityType: {
    ...fields.reportActivityType,
    name: 'reportActivityType',
    label: 'Atividade',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    options: [
      { value: 'Colheita', label: 'Colheita' },
      { value: 'Transferência', label: 'Transferência' },
      { value: 'Lavagem de Caixa', label: 'Lavagem de Caixa' },
      { value: 'Manutenção Estufa', label: 'Manutenção Estufa' },
      { value: 'Aplicação Nutriente', label: 'Aplicação Nutriente' },
      { value: 'Aplicação Defensivo', label: 'Aplicação Defensivo' },
      { value: 'Aplicação de Defensivo para Pragas', label: 'Aplicação de Defensivo para Pragas' },
      { value: 'Tarefas', label: 'Tarefas' },
    ]
  },
  greenhouseId: {
    ...fields.greenhouseId,
    name: 'greenhouseId',
    label: 'Estufa',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    isClearable: true,
    loadOptions: (query, callback, setFields) => {
      http.get('greenhouse')
        .then(({ data }) => {
          callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id })))
          setFields((oldFields) => {
            const newFields = { ...oldFields };
            newFields.greenhouseId.options = data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))
            return newFields
          })
        })
        .catch((err) => callback([]))
    },
    onChange: ({ target: { value, name }, setFields }) => {
      setFields(oldFields => {
        const newFields = { ...oldFields }
        const sectors = value?.sectors?.map((sector) => ({ ...sector, label: sector.name, value: sector.id }));
        newFields[name].value = value
        newFields.sectorId.value = ''
        newFields.sectorId.options = sectors
        return newFields;
      })
    },
  },
  sectorId: {
    ...fields.sectorId,
    name: 'sectorId',
    label: 'Setor',
    placeholder: 'Selecione',
    loadEmpty: true,
    type: 'select',
    isSearchable: false,
    noOptionsMessage: 'Escolha uma estufa',
    isClearable: true,
  },
  submitButton: {
    name: 'submitButton',
    label: 'Pesquisar',
    type: 'button',
    variation: 'primary',
    rightIcon: SearchIcon,
    onClick: () => {
      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        status1: fields.status.value?.value,
        type: fields.reportActivityType.value?.value,
        greenhouseId: fields.greenhouseId.value?.value,
        sectorId: fields.sectorId.value?.value,
      }
      http.get('tasks', { params })
        .then(({ data }) => {
          setList(data.data.map((item) => {
            const sector = { name: item.sectorName };
            const greenhouse = { id: item.greenhouseId, name: item.greenhouseName };
            return ({ ...item, reportActivityType: item.type, sector, greenhouse })
          }))
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  },
  generatePDF: {
    name: 'generatePDF',
    label: 'PDF',
    type: 'button',
    variation: 'primary',
    onClick: () => {
      const params = {
        startDate: fields.startDate.value,
        endDate: fields.endDate.value,
        status1: fields.status.value?.value,
        type: fields.reportActivityType.value?.value,
        greenhouseId: fields.greenhouseId.value?.value,
        sectorId: fields.sectorId.value?.value,
      }
      http.get(`/tasks/taskreport`, { params })
        .then(({ data }) => {
          const linkSource = `data:application/octet-stream;base64,${data.data.report}`;
          const downloadLink = document.createElement('a');
          const fileName = `${data.data.name}`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }
});

export const cardInfos = [
  { fieldLabel: 'Status', fieldType: 'status' },
  { fieldLabel: 'Planejado', fieldType: 'plannedDate' },
  { fieldLabel: 'Realizado', fieldType: 'endDate' },
  { fieldLabel: 'Estufa', fieldType: 'greenhouse' },
  { fieldLabel: 'Setor', fieldType: 'sector' },
  { fieldLabel: 'Atividade', fieldType: 'reportActivityType' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "startDate"
    "endDate"
    "status"
    "reportActivityType"
    "greenhouseId"
    "sectorId"
    "submitButton"
    "generatePDF"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } } }) => screenXS}) {
      grid-template: ${`
        "startDate endDate status"
        "reportActivityType greenhouseId sectorId"
        "submitButton generatePDF ."
      `};
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "status status status status status"
    "plannedDate endDate greenhouse sector reportActivityType"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } } }) => screenMD}) {
      grid-template:
        "status plannedDate endDate greenhouse sector reportActivityType"
      ;
      grid-template-columns: 80px 1fr 1fr 1fr 1fr 220px;
    }
  }
`
