import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import * as constants from './Plan.constants';
import * as S from './Plan.style';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function Plan ({setModal}) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { planFieldsState, planFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(planFieldsState);
  const itemFields = planFields({fields});
  useEffect(() => {
    if(id) {
      http.get(`plano/${id}`)
      .then(({ data }) => setFields({
          name: { value: data.nome },
          recurrence: { value: itemFields.recurrence.options.find((option) => option.value === data.recorrencia) },
          price: { value: data.valor },
        }))
        .catch((err) => setModal({ isOpen: true, text: err.message }))

    }
  }, [id])

  const onSubmit = () => {
    http[id ? 'patch' : 'post'](`plano${id ? `/${id}` : ''}`, {
      organizacaoId: '7080d758-fe8a-11ea-adc1-0242ac120002',
      nome: fields.name.value,
      recorrencia: fields.recurrence.value.value,
      valor: fields.price.value,
    })
      .then(() => history.push(`/${router.url.split('/')[1]}`))
      .catch((err) => setModal({ isOpen: true, text: err.message }))

  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields,
    setLoading, setError, setItem, setFields, onSubmit
  }

  return (
    <S.Plan>
      <ItemPage {...itemPageObject} />
    </S.Plan>
  );
};
