import SearchIcon from '../../images/SearchIcon';
import GeneratePDFIcon from '../../images/DownloadIcon';
import exportCSVIcon from '../../images/ExportIcon';
import { css } from 'styled-components';

export const type = 'list'
export const pageColor = '#8bc53f'
export const pageLabel = {
  name: 'recipess',
  singular: 'receita',
  plural: 'receitas'
};

export const recipesSearchFieldsState = () => ({
  query: { value: '' },
});


export const recipesSearchFields = ({ fields }) => ({
  query:{
    ...fields.query,
    name: 'query',
    label: 'Nome',
    placeholder: 'Digite',  
  },
});

export const cardInfos = [
  { fieldLabel: 'Nome', fieldType: 'name' },
  { fieldLabel: '', fieldType: 'history' },
  { fieldLabel: '', fieldType: 'delete' },
]

export const searchGridTemplate = css`
  grid-template: ${`
    "query"
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenXS } }}) => screenXS}) {
      grid-template: ${`
        "query ."
      `};
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const listCardGridTemplate = css`
  grid-template: 
    "name name name name"
    "history history delete delete"
  ;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD} }}) => screenMD}) {
      grid-template:
        "name history delete"
      ;
      grid-template-columns: 1fr 40px 40px;
    }
  }
`
