import React, { useState, useEffect } from 'react';
import http from '../../service'
import IndicatorsDetails from '../../Components/IndicatorsDetails';
import VivariumIcon from "../../images/VivariumIcon";
import * as S from './VivariumDetails.style';

const VivariumDetails = ({ setModal }) => {
  const [data, setData] = useState();

  useEffect(() => {
    http.get(`lot/getLotDetailDashByGreenery?stage=VIVEIRO`)
      .then(({ data }) => setData(data.data.map((item) => ({
        ...item,
        label: item.greenery_name,
        actualPercentage: item.percentualPlanted,
        plannedPercentage: item.percentualPlan,
        percentualPlan: item.percentualPlan
      }))))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  const tableItems = [
    { label: 'Hortaliça', name: 'label' },
    { label: 'Plantado', name: 'actualQuantity' },
    { label: 'Percentual Plantado', name: 'actualPercentage' },
    { label: 'Percentual Planejado', name: 'percentualPlan' },
  ]

  const vivariumInfo = {
    tableItems,
    label: "viveiro",
    shortLabel: "Hortaliça",
    units: 485,
    color: "#13739e",
    icon: VivariumIcon,
    link: '/indicadores/viveiro',
    path: ['viveiro'],
    data: data || []
  }
  return (
    <S.VivariumDetails>
      <IndicatorsDetails {...vivariumInfo} />
    </S.VivariumDetails>
  );
};

export default VivariumDetails;
