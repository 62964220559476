import styled, { css } from "styled-components";

export const Field = styled.div`
  display: flex;
  border: 2px solid ${({ theme: { color: { white } } }) => white };
  border-radius: 8px;
  grid-area: ${({ name }) => name };
  position: relative;
  background: ${({ theme: { color: { white } } }) => white };

  &[name="splitLot"] {
    width: 120px;
  }
`;

export const FieldTextArea = styled.div`
  display: flex;
  border: 2px solid ${({ theme: { color: { cream } } }) => cream };
  background: ${({ theme: { color: { white } } }) => white };
  border-radius: 8px;
  grid-area: ${({ name }) => name };
  position: relative;
  margin-top: 10px;

  input {
    padding-top: 16px;
  }
`;

export const FieldLabel = styled.label`
  font-size: 12px;
  padding: 4px 8px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  color: ${({ theme: { color: { brandComplementarDark } } }) => brandComplementarDark };
  background: ${({ theme: { color: { lightGray } } }) => lightGray };
  border-radius: 6px 0 0 6px;
  align-self: flex-start;
`;

export const FieldError = styled.p`
  position: absolute;
  font-size: 12px;
  padding: 4px 8px;
  top: -28px;
  right: 0px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  color: ${({ theme: { color: { white } } }) => white };
  background: ${({ theme: { color: { danger } } }) => danger };
  border-radius: 6px;
  z-index: 99;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    border-right: 16px solid transparent;
    border-top: 16px solid transparent;
    border-left: 16px solid ${({ theme: { color: { danger } } }) => danger };
    transform: rotate(-45deg);
  }
`;

export const FieldTextAreaLabel = styled.label`
  font-size: 12px;
  padding: 5px 8px;
  white-space: nowrap;
  position: absolute;
  align-items: center;
  display: flex;
  top: -15px;
  left: -2px;
  background: white;
  border: 2px solid ${({ theme: { color: { cream } } }) => cream };
  border-radius: 8px;
  color: ${({ theme: { color: { brandComplementarDark } } }) => brandComplementarDark };
`;

export const FieldCaption = styled.p`
  grid-area: ${({ name }) => name };
  align-self: end;
  position: relative;
  top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FieldCallError = styled.p`
  grid-area: ${({ name }) => name };
  align-self: end;
  position: relative;
  top: 6px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const FieldBigNumber = styled.div`
  grid-area: ${({ name }) => name };
  background-color: ${({ color }) => color} !important;
  display: flex;
  padding: 8px 12px;
  border-radius: 50px;
  align-items: center;
  position: relative;

  input {
    background-color: black !important;
    color: white;
    width: 40px;
    height: 40px;
    margin: -30px 8px;
    padding: 4px;
    border-radius: 12px;
    font-size: 14px;
    text-align: center;
  }
`;

export const FieldBigNumberLabel = styled.label`
  white-space: nowrap;
  background: unset !important;
  color: white;
  font-size: 14px;
`;

export const FieldSubFieldsContainer = styled.div`
  grid-area: ${({ name }) => name };
`;

export const FieldSubFields = styled.div`
  display: grid;
  position: relative;
  gap: 16px;
  padding: 20px;
  background: rgba(90, 120, 90, 0.1);
  max-height: 66px;
  transition: max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s;
  overflow: hidden;

  ${({ gridTemplate }) => gridTemplate }
  ${({isOpen, opened}) => isOpen && css`
    max-height: 9999px;
    transition: max-height 0.8s cubic-bezier(0.5, 0, 1, 0) -0.1s;

    ${opened ? "overflow: initial;" : ""}
    }
  `}

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const FieldSubFieldsToggleButton = styled.button`
  position: absolute;
  height: 20px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px;
  top: 10px;
  right: 20px;
  background: ${({ theme: { color: { brandMedium } } }) => brandMedium };
  color: white;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      left: 20px;
    }
  }
`;

export const FieldMeasureRef = styled.div`
  grid-area: ${({ name }) => name };
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  align-items: center;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      flex-direction: row;
    }
  }
`

export const FieldMeasureRefLabel = styled.div`
  background-color: ${({ color, theme: { color: { [color]: clr } } }) => clr };
  width: 48px;
  min-width: 48px;
  height: 48px;
  color: ${({ theme: { color: { white } } }) => white };
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 45px;
  font-weight: bold;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 48px;
    width: 2px;
    height: 45px;
    background-color: ${({ color, theme: { color: { [color]: clr } } }) => clr };
  }

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      margin-right: 45px;
      margin-bottom: 0;

      &:after {
        left: 48px;
        top: unset;
        height: 2px;
        width: 45px;
      }
    }
  }
`

export const FieldMeasureRefValue = styled.div`
  color: ${({ color, theme: { color: { [color]: clr } } }) => clr };
  font-weight: bold;
  font-size: 14px;
  margin-top: 4px;
  text-align: center;
  line-height: 20px;
`

export const FieldMeasureRefValueRef = styled.div`
  border: 2px solid ${({ color, theme: { color: { [color]: clr } } }) => clr };
  border-radius: 8px;
  width: 140px;
  min-width: 140px;
  position: relative;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 8px;
  margin-bottom: 45px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 52px;
    right: 70px;
    width: 2px;
    height: 45px;
    background-color: ${({ color, theme: { color: { [color]: clr } } }) => clr };
  }

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      margin-right: 45px;
      margin-bottom: 0;

      &:after {
        left: 138px;
        top: unset;
        height: 2px;
        width: 45px;
      }
    }
  }
`

export const FieldMeasureRefRecomendation = styled.span `
  border: 2px solid ${({ color, theme: { color: { [color]: clr } } }) => clr };
  border-radius: 8px;
  width: 140px;
  position: relative;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 8px;

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      width: auto;
    }
  }
`