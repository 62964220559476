import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import GreeneryIcon from '../../images/GreeneryIcon';
import * as S from './Greeneries.style';
import * as constants from './Greeneries.constants';

export default function Greeneries ({setModal}) {
  const { greeneriesSearchFieldsState, greeneriesSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(greeneriesSearchFieldsState);
  const searchFields = greeneriesSearchFields({fields});
  const url = 'greeneries'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: GreeneryIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Greeneries>
      <ListPage {...listPageObject} />
    </S.Greeneries>
  );
};
