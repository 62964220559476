import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import PestIcon from '../../images/PestIcon';
import * as S from './Pests.style';
import * as constants from './Pests.constants';

export default function Pests ({ setModal }) {
  const { pestsSearchFieldsState, pestsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(pestsSearchFieldsState);
  const searchFields = pestsSearchFields({fields});
  const url = 'bugs'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: PestIcon, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.nomeVulgar.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.Pests>
      <ListPage {...listPageObject} />
    </S.Pests>
  );
};
