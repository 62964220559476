import React, { useState } from 'react';
import * as S from './ExecuteModal.style';
import Field from './../Field';
import Button from './../Button';
import { format } from 'date-fns';
import http from '../../service'

export default function PostponeModal({ setModal, item }) {
  const date = format(new Date(), "yyyy-MM-dd")
  const [fields, setFields] = useState({ date: { value: date } })

  return (
    <S.ExecuteModal>
      <S.ExecuteCall>Selecione uma data<br />para executar esta atividade</S.ExecuteCall>
      <Field label="Executar em" name="date" type="Date" value={fields.date.value} setFields={setFields} />
      <Button name="cancel" label="Cancelar" variation="danger" onClick={() => setModal({ isOpen: false })} />
      <Button
        name="submit"
        label="Executar"
        variation="primary"
        onClick={() => http
          .patch(`tasks/${item.id}`, {
            endDate: fields.date.value,
            status: "DONE",
            type: item.activityType
          })
          .then((res) => {
            if (item.reload) {
              item.reload();
            }
            else {
              item.setList((oldList) => {
                const newList = [...oldList]
                const index = newList.findIndex((listItem) => item.id === listItem.id);
                newList.splice(index, 1);
                return newList;
              })
            }

            setModal({ isOpen: false })
            setModal({ isOpen: true, text: 'Tarefa realizada com sucesso' })
          })
          .catch((err) => setModal({ isOpen: true, text: err.message }))
        } />
    </S.ExecuteModal>
  )
}