import React, { useState } from 'react';
import * as S from './PostponeModal.style';
import Field from './../Field';
import Button from './../Button';
import { format } from 'date-fns';
import http from '../../service'
import { compareAsc } from 'date-fns';

export default function PostponeModal ({ setModal, item }) {
  const date = format(new Date(), "yyyy-MM-dd")
  const [fields, setFields] = useState({ date: { value: date } })

  return (
    <S.PostponeModal>
      <S.PostponeCall>Selecione uma data<br/>para adiar esta atividade</S.PostponeCall>
      <Field label="Adiar para" name="date" type="Date" value={fields.date.value} setFields={setFields} />
      <Button name="cancel" label="Cancelar" variation="danger" onClick={() => setModal({ isOpen: false })}/> 
      <Button 
        name="submit" 
        label="Adiar" 
        variation="primary" 
        onClick={() => http
          .patch(`tasks/${item.id}`, { plannedDate: fields.date.value, status: "REPLANNED" })
          .then((res) => {
            item.setList((oldList) => {
              const newList = [...oldList]
              const index = newList.findIndex((listItem) => item.id === listItem.id );
              newList[index].plannedDate = fields.date.value;
              const sortedList = newList.sort((itemA, itemB) => compareAsc(new Date(itemA.plannedDate), new Date(itemB.plannedDate)))
              return sortedList;
            })
            setModal({ isOpen: false })
            setModal({ isOpen: true, text: 'Adiado com sucesso'})
          })
          .catch((err) => setModal({ isOpen: true, text: err.message }))
        }/> 
    </S.PostponeModal>
  )
}