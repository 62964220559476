import jwt_decode from "jwt-decode";
import sha256 from 'js-sha256';
import http from '../service';

export async function login ({data, setCurrentUser, setError, setLoading}) {
  setLoading(true);
  setError('');
  http.post('/auth/signin', data)
    .then(({data}) => {
      const { data: { accessToken } } = data;
      const currentUser = jwt_decode(accessToken);
      localStorage.setItem('currentUser', JSON.stringify({ ...currentUser, accessToken }));
      setCurrentUser({ ...currentUser, accessToken })
    })
    .catch((err) => setError(err.message.split(',')[0]))
    .finally(() => setLoading(false))
};

export async function signup ({data, setCurrentUser, setError, setLoading}) {
  setLoading(true);
  setError('');
  http.post('/auth/signup', data)
    .then((res) => {
      const { data } = res.data;
      const currentUser = jwt_decode(data?.accessToken);
      localStorage.setItem('currentUser', JSON.stringify({ ...currentUser, accessToken: data?.accessToken }));
      setCurrentUser({ ...currentUser, accessToken: data?.accessToken })
    })
    .catch((err) => setError(err.message.split(',')[0]))
    .finally(() => setLoading(false))
};

export async function recovery ({data, setError, setLoading, setForgot, setRecovered}) {
  setLoading(true);
  setError('');
  http.post('/auth/recovery', data)
    .then((res) =>  { setForgot(false); setRecovered(true) })
    .catch((err) => setError(err.message.split(',')[0]))
    .finally(() => setLoading(false))
};

export async function recoveryStep2 ({data, token, setCurrentUser, setError, setLoading}) {
  setLoading(true);
  setError('');
  http.post(`/auth/recovery/${token}`, data)
    .then((res) =>  { 
      const { data } = res.data;
      const currentUser = jwt_decode(data?.accessToken);
      localStorage.setItem('currentUser', JSON.stringify({ ...currentUser, accessToken: data?.accessToken }));
      setCurrentUser({ ...currentUser, accessToken: data?.accessToken })
     })
    .catch((err) => setError(err.message.split(',')[0]))
    .finally(() => setLoading(false))
};

export function logout (props) {
  props?.setCurrentUser()
  window.location.reload();
  return localStorage.removeItem('currentUser');
};