import React, { useEffect, useState } from 'react';
import mapFieldsToData from '../../helpers/mapFieldsToData';
import http from '../../service'
import ListPage from '../../Components/ListPage';
import NutritiveSolution from '../../images/NutritiveSolution';
import * as S from './NutritiveSolutions.style';
import * as constants from './NutritiveSolutions.constants';

export default function NutritiveSolutions ({ setModal }) {
  const { nutritiveSolutionsSearchFieldsState, nutritiveSolutionsSearchFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [fields, setFields] = useState(nutritiveSolutionsSearchFieldsState);
  const searchFields = nutritiveSolutionsSearchFields({fields});
  const url = 'nutritive-solution'
  const listPageObject = {
    ...constants, loading, error, page, totalPages, totalCount, list, fields: searchFields, url, setModal,
    setLoading, setError, setPage, setTotalPages, setTotalCount, icon: NutritiveSolution, setList, setFields, 
  }

  useEffect(() => {
    const query = searchFields.query.value;
    http.get(url).then(({ data }) => setList(data.data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))))
      .catch((err) => setError(err))
  }, [searchFields.query.value])

  return (
    <S.NutritiveSolutions>
      <ListPage {...listPageObject} />
    </S.NutritiveSolutions>
  );
};
