import { css } from 'styled-components';
import http from '../../service';

export const type = 'list'
export const pageColor = '#13739e'

export const pageLabel = {
  name: 'nutritiveSolution',
  singular: `solução nutritiva`,
  plural: `soluções nutritivas`,
  newLabel: 'Nova',
  editLabel: 'Salvar'
};

export const newCompositions = (fields, setFields, i) => ({
  compositionsLabel: { 
    name: 'compositionsLabel',
    label: `Insumo ${i + 1}`,
    type: 'caption',
  },
  input : {
    value: '',
    name: 'input',
    label: 'Insumo',
    placeholder: 'Digite',
    type: 'select',
    isSearchable: false,
    loadEmpty: true,
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i][name].error = false
      compositionsValue[i][name].errorMessage = ''
      compositionsValue[i].compositionsLabel.label = `${value.label} ${compositionsValue[i].amount.value ? `- ${compositionsValue[i].amount.value} ${compositionsValue[i].amount.suffix}` : ''}`
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    }),
    loadOptions: (query, callback) => {
      http.get(`input/?inputGroup1=NUTRIENTES${query ? `&search=${query}` : ''}`)
        .then(({data}) => callback(data?.data?.map((option) => ({ ...option, label: option.name, value: option.id }))))
        .catch((err) => callback([]))
    }, 
    validate: (value) => ({ empty: !value }),
    onBlur: ({target, setFields, validate}) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if(validation.empty) {
        setFields((oldFields) => {
          const compositionsValue = [...oldFields.compositions.value]
          compositionsValue[i][name].error = true;
          compositionsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
        });
      } else if(value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  amount: {
    value: '',
    name: 'amount',
    label: 'Quantidade',
    placeholder: 'Digite',
    type: 'number',
    suffix: ' g',
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i][name].error = false
      compositionsValue[i][name].errorMessage = ''
      compositionsValue[i].compositionsLabel.label = `${compositionsValue[i].input.value?.label || `Insumo ${i + 1}`} ${compositionsValue[i].amount.value ? `- ${compositionsValue[i].amount.value} ${compositionsValue[i].amount.suffix}` : ''}`
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({target, setFields, validate}) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if(validation.empty) {
        setFields((oldFields) => {
          const compositionsValue = [...oldFields.compositions.value]
          compositionsValue[i][name].error = true;
          compositionsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
        });
      } else if(value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  unit: {
    value: {label: 'gramas', value: ' g'},
    name: 'unit',
    label: 'Unidade',
    placeholder: 'Selecione',
    type: 'select',
    isSearchable: false,
    loadEmpty: true,
    options: [
      {label: 'gramas', value: ' g'},
      {label: 'miliLitros', value: ' ml'}
    ],
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value]
      compositionsValue[i][name].value = value
      compositionsValue[i].amount.suffix = value.value
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    })
  },
  removeButton: {
    name: 'removeButton',
    label: '-',
    type: 'button',
    variation: 'danger',
    onClick: (e, i) => { setFields((oldFields) => { 
      const compositionsValue = [ ...oldFields.compositions.value ]
      compositionsValue.splice(i, 1)
      compositionsValue.map((rec, i) => rec.compositionsLabel.label = `${compositionsValue[i].input.value?.label || `Insumo ${i + 1}`} ${compositionsValue[i].amount.value ? `- ${compositionsValue[i].amount.value} ${compositionsValue[i].amount.suffix}` : ''}`)
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    })}
  },
});

export const newPHRecomendation = (fields, setFields, i) => ({
  recomendationsLabel: { 
    name: 'recomendationsLabel',
    label: `PH 4.6 a 7.4`,
    type: 'caption',
    recomendationType: 'ph'
  },
  range: { 
    name: 'range',
    value: [4.6, 7.4],
    type: 'range',
    min: 4.5,
    max: 7.5,
    marks: 0.5,
    step: 0.1,
    toFixed: 1,
    onChange: ({ target: { value, name, i } }) => {
      if(value[0] !== value[1]) {
        setFields((oldFields) => { 
          const recomendationsValue = [...oldFields.phRecomendations.value]
          recomendationsValue[i][name].value = value;
          recomendationsValue[i].recomendationsLabel.label = `PH ${value[0]} a ${value[1]} ${recomendationsValue[i].recomendation.value ? ` - ${recomendationsValue[i].recomendation.value}` : '' }`
          return { ...oldFields, recomendations: { ...oldFields.phRecomendations, value: recomendationsValue } } 
        }
      )}
    },
  },
  recomendation: { 
    value: '',
    name: 'recomendation',
    label: 'Observação',
    placeholder: 'Digite',
    type: 'textarea',
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const recomendationsValue = [...oldFields.phRecomendations.value]
      recomendationsValue[i][name].value = value
      recomendationsValue[i][name].error = false
      recomendationsValue[i][name].errorMessage = ''
      recomendationsValue[i].recomendationsLabel.label = `PH ${Number(recomendationsValue[i].range.value[0]).toFixed(1)} a ${Number(recomendationsValue[i].range.value[1]).toFixed(1)} ${value ? ` - ${value}` : '' }`
      return { ...oldFields, phRecomendations: { ...oldFields.phRecomendations, value: recomendationsValue } } 
    }),
    validate: (value) => ({ empty: !value }),
    onBlur: ({target, setFields, validate}) => {
      const { name, value, i } = target;
      const validation = validate(value);
      if(validation.empty) {
        setFields((oldFields) => {
          const phRecomendationsValue = [...oldFields.phRecomendations.value]
          phRecomendationsValue[i][name].error = true;
          phRecomendationsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
          return { ...oldFields, phRecomendations: { ...oldFields.phRecomendations, value: phRecomendationsValue } } 
        });
      } else if(value && validation.invalid) {
        setFields((oldFields) => {
          const sectorsValue = [...oldFields.sectors.value]
          sectorsValue[i][name].error = true;
          sectorsValue[i][name].errorMessage = validation.invalidMessage;
          return ({ ...oldFields });
        });
      }
    }
  },
  removeButton: {
    name: 'removeButton',
    label: '-',
    type: 'button',
    variation: 'danger',
    onClick: (e, i) => { setFields((oldFields) => { 
      const recomendationsValue = [ ...oldFields.phRecomendations.value ]
      recomendationsValue.splice(i, 1)
      recomendationsValue.map((rec, i) => rec.recomendationsLabel.label = `PH ${Number(recomendationsValue[i].range.value[0]).toFixed(1)} a ${Number(recomendationsValue[i].range.value[1]).toFixed(1)} ${recomendationsValue[i].recomendation.value ? ` - ${recomendationsValue[i].recomendation.value}` : '' }`)
      return { ...oldFields, phRecomendations: { ...oldFields.phRecomendations, value: recomendationsValue }, phRecomendationsCaption: { ...oldFields.phRecomendationsCaption, label: !!oldFields.phRecomendations.length ? 'Recomendações de pH' : 'Sem recomendações de pH' } } 
    })}
  },
});

export const newECRecomendation = (fields, setFields) => {
  const minValue = Number((Number(fields.ec.value[0]).toFixed(1) - (fields.ecRecomendations.value.length + 1) / 10).toFixed(1));
  const maxValue = Number((Number(fields.ec.value[0]).toFixed(1) - (fields.ecRecomendations.value.length) / 10).toFixed(1));
  return ({
    recomendationsLabel: { 
      name: 'recomendationsLabel',
      label: `EC ${minValue} a ${maxValue}`,
      type: 'caption',
      recomendationType: 'ec',
    },
    range: { 
      name: 'range',
      value: [minValue, maxValue],
      type: 'range',
      min: .4,
      max: 3.6,
      marks: .4,
      step: .1,
      toFixed: 1,
      onChange: ({ target: { value, name, i } }) => {
        if(value[0] !== value[1]) {
          setFields((oldFields) => {
            const recomendationsValue = [...oldFields.ecRecomendations.value]
            recomendationsValue[i][name].value = value
            recomendationsValue[i].recomendationsLabel.label = `EC ${Number(value[0]).toFixed(1)} a ${Number(value[1]).toFixed(1)} ${recomendationsValue[i].recomendation.value ? ` - ${recomendationsValue[i].recomendation.value}` : '' }`
            return { ...oldFields, ecRecomendations: { ...oldFields.ecRecomendations, value: recomendationsValue } } 
          }) 
        }
      }
    },
    recomendation: { 
      value: '',
      name: 'recomendation',
      label: 'Recomendação',
      placeholder: 'Digite',
      type: 'textarea',
      onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
        const recomendationsValue = [...oldFields.ecRecomendations.value]
        recomendationsValue[i][name].value = value
        recomendationsValue[i][name].error = false
        recomendationsValue[i][name].errorMessage = ''
        recomendationsValue[i].recomendationsLabel.label = `EC ${Number(recomendationsValue[i].range.value[0]).toFixed(1)} a ${Number(recomendationsValue[i].range.value[1]).toFixed(1)} ${value ? ` - ${value}` : '' }`
        return { ...oldFields, ecRecomendations: { ...oldFields.ecRecomendations, value: recomendationsValue } } 
      }),
      validate: (value) => ({ empty: !value }),
      onBlur: ({target, setFields, validate}) => {
        const { name, value, i } = target;
        const validation = validate(value);
        if(validation.empty) {
          setFields((oldFields) => {
            const ecRecomendationsValue = [...oldFields.ecRecomendations.value]
            ecRecomendationsValue[i][name].error = true;
            ecRecomendationsValue[i][name].errorMessage = validation.emptyMessage || 'Este campo é obrigatório';
            return { ...oldFields, ecRecomendations: { ...oldFields.ecRecomendations, value: ecRecomendationsValue } } 
          });
        } else if(value && validation.invalid) {
          setFields((oldFields) => {
            const sectorsValue = [...oldFields.sectors.value]
            sectorsValue[i][name].error = true;
            sectorsValue[i][name].errorMessage = validation.invalidMessage;
            return ({ ...oldFields });
          });
        }
      }
    },
    removeButton: {
      name: 'removeButton',
      label: '-',
      type: 'button',
      variation: 'danger',
      onClick: (e, i) => { setFields((oldFields) => { 
        const recomendationsValue = [ ...oldFields.ecRecomendations.value ]
        recomendationsValue.splice(i, 1)
        recomendationsValue.map((rec, i) => rec.recomendationsLabel.label = `EC ${Number(recomendationsValue[i].range.value[0]).toFixed(1)} a ${Number(recomendationsValue[i].range.value[1]).toFixed(1)} ${recomendationsValue[i].recomendation.value ? ` - ${recomendationsValue[i].recomendation.value}` : '' }`)
        return { 
          ...oldFields, 
          ecRecomendations: { ...oldFields.ecRecomendations, value: recomendationsValue },
          ecRecomendationsCaption: { ...oldFields.ecRecomendationsCaption, label: !!oldFields.ecRecomendations.length ? 'Recomendações de EC' : 'Sem recomendações de EC' }
         } 
      })}
    },
  })
};

export const nutritiveSolutionFieldsState = () => ({
  name: { value: '' },
  description: { value: '' },
  compositions: { value: [] },
  ecCaption: { label: 'Intervalo de EC ideal - 0.5 a 3.5' },
  ec: { value: [.5, 3.5] },
  phCaption: { label: 'Intervalo de PH ideal - 4.6 a 7.4' },
  ph: { value: [4.6, 7.4]  },
  temperatureCaption: { label: 'Intervalo de Temperatura ideal - 11° a 39°' },
  temperature: { value: [11, 39] },
  ecRecomendations: { value: [] },
  phRecomendations: { value: [] },
});

export const nutritiveSolutionFields = ({ fields, setFields }) => ({
  name: { 
    name: 'name',
    label: 'Nome',
    placeholder: 'Digite',
    validate: (value) => ({
      empty: !value
    }), 
    ...fields.name,
  },
  description: { 
    name: 'description',
    label: 'Observação',
    placeholder: 'Digite',
    ...fields.description,
  },
  ecCaption: {
    name: 'ecCaption',
    type: 'caption',
    ...fields.ecCaption,
  },
  ec: { 
    name: 'ec',
    label: '',
    placeholder: 'Digite',
    type: 'range',
    min: .4,
    max: 3.6,
    marks: .4,
    step: .1,
    toFixed: 1,
    onChange: ({ target: { value, name, i } }) => {
      setFields((oldFields) => { 
        const newFields = { ...oldFields };
      if(value[0] !== value[1]) {
          newFields[name].value = value
          newFields.ecCaption.label = `Intervalo de EC ideal - ${value[0]} a ${value[1]}`
        }
        return newFields 
      })
    },
    ...fields.ec,
  },
  phCaption: {
    name: 'phCaption',
    type: 'caption',
    ...fields.phCaption,
  },
  ph: { 
    name: 'ph',
    placeholder: 'Digite',
    type: 'range',
    min: 4.5,
    max: 7.5,
    marks: 0.5,
    step: 0.1,
    toFixed: 1,
    onChange: ({ target: { value, name, i } }) => setFields((oldFields) => { 
      const newFields = { ...oldFields };
      if(value[0] !== value[1]) {
        newFields[name].value = value
        newFields.phCaption.label = `Intervalo de pH ideal - ${value[0]} a ${value[1]}`
      }
      return newFields 
    }),
    ...fields.ph,
  },
  temperatureCaption: {
    name: 'temperatureCaption',
    type: 'caption',
    ...fields.temperatureCaption,
  },
  temperature: { 
    name: 'temperature',
    placeholder: 'Digite',
    type: 'range',
    min: 10,
    max: 40,
    marks: 5,
    step: 1,
    suffix: '°',
    onChange: ({ target: { value, name, i } }) => {
      setFields((oldFields) => { 
        const newFields = { ...oldFields };
        if(value[0] !== value[1]) {
          newFields[name].value = value
          newFields.temperatureCaption.label = `Intervalo de temperatura ideal - ${value[0]}° a ${value[1]}°`
        }
        return newFields 
      })
    },
    ...fields.temperature,
  },
  compositionsCaption: {
    name: 'compositionsCaption',
    label: 'Composição',
    type: 'caption',
  },
  addCompositionsButton: {
    name: 'addCompositionsButton',
    label: '+',
    type: 'button',
    variation: 'primary',
    onClick: () => setFields((oldFields) => { 
      const compositionsValue = [...oldFields.compositions.value, newCompositions(fields, setFields, oldFields.compositions.value.length)]
      return { ...oldFields, compositions: { ...oldFields.compositions, value: compositionsValue } } 
    })
  },
  compositions: { 
    name: 'compositions',
    label: 'composição',
    placeholder: 'Digite',
    type: 'subFields',
    gridTemplate: css`
      grid-template-columns: "1fr 1fr 65px";
      grid-template: ${`
        "compositionsLabel" 
        "input" 
        "amount" 
        "unit" 
        "removeButton"
        `};
      
      @media screen {
        @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
          grid-template: ${`
            "compositionsLabel compositionsLabel compositionsLabel compositionsLabel compositionsLabel compositionsLabel compositionsLabel removeButton"
            "input input input input amount amount unit unit"
            `};
          grid-template-columns: 1fr 1fr 1fr 1fr 80px 80px 100px 65px;
        }
      };
    `,
    validate: (value) => ({
      empty: value.some((fields) => {
        return Object.values(fields).some((field) => {
          return field.validate && field.validate(field.value).empty;
        })
      })
    }),
  ...fields.compositions,
  },
  ececRecomendationsCaption: { 
    name: 'ecRecomendationsCaption',
    label: 'Sem recomendações de EC',
    type: 'caption',
    ...fields.ecRecomendationsCaption
  },
  ecRecomendations: { 
    name: 'ecRecomendations',
    placeholder: 'Digite',
    type: 'subFields',
    gridTemplate: css`
      grid-template-columns: "1fr 1fr 65px";
      grid-template: ${`
        "recomendationsLabel" 
        "range" 
        "recomendation" 
        "removeButton"
        `};
      
      @media screen {
        @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
          grid-template: ${`
            "recomendationsLabel recomendationsLabel recomendationsLabel removeButton"
            "range range range range"
            "recomendation recomendation recomendation recomendation"
            `};
          grid-template-columns: 1fr 180px 180px 65px;
        }
      };
    `,
    validate: (value) => ({
      empty: value.some((fields) => {
        return Object.values(fields).some((field) => {
          return field.validate && field.validate(field.value).empty;
        })
      })
    }),
    ...fields.ecRecomendations,
  },
  addECRecomendationButton: {
    name: 'addECRecomendationButton',
    label: 'Nova recomendação de EC',
    type: 'button',
    variation: 'warning',
    onClick: () => setFields((oldFields) => { 
      const recomendationsValue = [...oldFields.ecRecomendations.value, newECRecomendation(fields, setFields)]
      return { 
        ...oldFields, 
        ecRecomendations: { ...oldFields.ecRecomendations, value: recomendationsValue },
        ecRecomendationsCaption: { ...oldFields.ecRecomendationsCaption, label: 'Recomendações de EC' }
      } 
    })
  },
  phRecomendationsCaption: { 
    name: 'phRecomendationsCaption',
    label: 'Sem recomendações de pH',
    type: 'caption',
    ...fields.phRecomendationsCaption
  },
  phRecomendations: { 
    name: 'phRecomendations',
    placeholder: 'Digite',
    type: 'subFields',
    gridTemplate: css`
      grid-template-columns: "1fr 1fr 65px";
      grid-template: ${`
        "recomendationsLabel" 
        "range" 
        "recomendation" 
        "removeButton"
        `};
      
      @media screen {
        @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
          grid-template: ${`
            "recomendationsLabel recomendationsLabel recomendationsLabel removeButton"
            "range range range range"
            "recomendation recomendation recomendation recomendation"
            `};
          grid-template-columns: 1fr 180px 180px 65px;
        }
      };
    `,
    validate: (value) => ({
      empty: value.some((fields) => {
        return Object.values(fields).some((field) => {
          return field.validate && field.validate(field.value).empty;
        })
      })
    }),
    ...fields.phRecomendations,
  },
  addPHRecomendationButton: {
    name: 'addPHRecomendationButton',
    label: 'Nova recomendação de pH',
    type: 'button',
    variation: 'danger',
    onClick: () => setFields((oldFields) => { 
      const recomendationsValue = [...oldFields.phRecomendations.value, newPHRecomendation(fields, setFields)];
      return { 
        ...oldFields, 
        phRecomendations: { ...oldFields.phRecomendations, value: recomendationsValue }, 
        phRecomendationsCaption: { ...oldFields.phRecomendationsCaption, label: 'Recomendações de pH' }
      } 
    })
  },
});

export const gridTemplate = css`
  grid-template: ${`
    "name" 
    "description" 
    "compositionsCaption"
    "compositions" 
    "addCompositionsButton"
    "ecCaption" 
    "ec" 
    "phCaption" 
    "ph" 
    "temperatureCaption" 
    "temperature" 
    "ecRecomendationsCaption"
    "ecRecomendations" 
    "addECRecomendationButton" 
    "phRecomendationsCaption"
    "phRecomendations" 
    "addPHRecomendationButton" 
  `};

  @media screen {
    @media (min-width: ${({ theme: { screen: { screenMD } }}) => screenMD}) {
      grid-template: ${`
        "name name description description description description description description" 
        "compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption compositionsCaption"
        "compositions compositions compositions compositions compositions compositions compositions compositions" 
        "addCompositionsButton . . . . . . ." 
        "ecCaption ecCaption ecCaption ecCaption ecCaption ecCaption ecCaption ecCaption"
        "ec ec ec ec ec ec ec ec"
        "phCaption phCaption phCaption phCaption phCaption phCaption phCaption phCaption"
        "ph ph ph ph ph ph ph ph"
        "temperatureCaption temperatureCaption temperatureCaption temperatureCaption temperatureCaption temperatureCaption temperatureCaption temperatureCaption"
        "temperature temperature temperature temperature temperature temperature temperature temperature"
        "ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption ecRecomendationsCaption"
        "ecRecomendations ecRecomendations ecRecomendations ecRecomendations ecRecomendations ecRecomendations ecRecomendations ecRecomendations"
        "addECRecomendationButton addECRecomendationButton addECRecomendationButton . . . . ."
        "phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption phRecomendationsCaption"
        "phRecomendations phRecomendations phRecomendations phRecomendations phRecomendations phRecomendations phRecomendations phRecomendations"
        "addPHRecomendationButton addPHRecomendationButton addPHRecomendationButton . . . . ." 
      `};
      grid-template-columns: 65px calc(25% - 105px) repeat(6, calc(12.5% - 12px));
    }
  }
`
