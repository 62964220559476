import React from 'react';
import InputText from './InputText';
import InputSelect from './InputSelect';
import InputTextArea from './InputTextArea';
import InputDate from './InputDate';
import InputNumber from './InputNumber';
import InputRange from './InputRange';
import InputSwitch from './InputSwitch';

export default function Input(props) {
  const { type } = props;

  switch (type) {
    case 'select':
      return <InputSelect {...props} />
    case 'textarea':
      return <InputTextArea {...props} />
    case 'date':
      return <InputDate {...props} />
    case 'number':
      return <InputNumber {...props} />
    case 'range':
      return <InputRange {...props} />
    case 'switch':
      return <InputSwitch {...props} />
    default:
      return <InputText {...props} />
  }
};
