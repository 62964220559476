import React from "react";

export default function GreenHouseIcon() {
  return (
    <svg width="123" height="92" viewBox="0 0 123 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M59.06 91.25C58.66 91.25 58.26 91.12 57.94 90.86C57.51 90.52 57.26 90 57.26 89.46V70.44C57.29 70.02 59.85 42.03 90.54 36.47C117.15 31.65 122.14 56.97 122.19 57.23C122.21 57.34 122.22 57.44 122.22 57.55L122.23 75.29C122.23 76.13 121.65 76.85 120.84 77.04L59.46 91.21C59.33 91.24 59.19 91.25 59.06 91.25ZM96.94 39.46C95.16 39.46 93.25 39.63 91.18 40.01C63.26 45.07 60.93 69.55 60.85 70.59V87.21L118.64 73.87L118.63 57.74C118.24 55.82 114.33 39.46 96.94 39.46Z" fill="#3BBC7B"/>
      <path d="M1.79 55.39C0.8 55.39 0 54.58 0 53.59V34.57C0.03 34.15 2.59 6.16 33.28 0.600002C40.42 -0.679998 46.68 0.0900023 51.86 2.91C52.73 3.39 53.05 4.47 52.58 5.34C52.11 6.22 51.02 6.53 50.15 6.06C45.7 3.63 40.24 2.99 33.92 4.13C6 9.19 3.67 33.67 3.58 34.71L3.59 53.59C3.59 54.58 2.78 55.39 1.79 55.39Z" fill="#3BBC7B"/>
      <path d="M17.15 65C16.16 65 15.36 64.2 15.36 63.21V44.18C15.39 43.76 17.95 15.77 48.64 10.22C55.77 8.93001 62.03 9.7 67.22 12.53C68.09 13 68.41 14.09 67.94 14.96C67.47 15.82 66.38 16.15 65.51 15.68C61.05 13.25 55.59 12.59 49.28 13.75C21.28 18.82 18.97 44.07 18.95 44.32L18.96 63.2C18.94 64.19 18.14 65 17.15 65Z" fill="#3BBC7B"/>
      <path d="M38.55 78.47C37.56 78.47 36.76 77.67 36.76 76.68V57.65C36.79 57.23 39.35 29.24 70.04 23.69C77.17 22.4 83.43 23.17 88.62 26C89.49 26.47 89.81 27.56 89.34 28.43C88.87 29.29 87.78 29.61 86.91 29.15C82.46 26.72 76.99 26.07 70.68 27.22C42.76 32.27 40.42 56.76 40.34 57.8L40.35 76.68C40.34 77.67 39.54 78.47 38.55 78.47Z" fill="#3BBC7B"/>
      <path d="M59.06 91.25C58.73 91.25 58.4 91.16 58.11 90.98L0.84002 55.11C1.9908e-05 54.59 -0.24998 53.48 0.27002 52.64C0.79002 51.8 1.90002 51.55 2.74002 52.07L57.26 86.22V71.43L0.84002 36.09C1.9908e-05 35.56 -0.24998 34.45 0.27002 33.62C0.79002 32.78 1.90002 32.53 2.74002 33.05L60.01 68.92C60.53 69.25 60.85 69.82 60.85 70.44V89.46C60.85 90.11 60.5 90.71 59.92 91.03C59.66 91.18 59.36 91.25 59.06 91.25Z" fill="#3BBC7B"/>
      <path d="M109.42 42.83C109.1 42.83 108.77 42.74 108.47 42.56L51.22 6.7C50.38 6.17 50.13 5.07 50.65 4.23C51.18 3.39 52.28 3.14 53.12 3.66L110.38 39.52C111.22 40.05 111.47 41.15 110.95 41.99C110.6 42.53 110.02 42.83 109.42 42.83Z" fill="#3BBC7B"/>
      <path d="M79.96 86.4C79.56 86.4 79.16 86.27 78.84 86.01C78.41 85.67 78.16 85.15 78.16 84.61V58.28C78.16 57.45 78.74 56.72 79.55 56.53L100.42 51.71C100.96 51.6 101.51 51.72 101.94 52.05C102.37 52.39 102.62 52.91 102.62 53.45V79.8C102.62 80.63 102.04 81.36 101.23 81.55L80.37 86.33C80.23 86.38 80.1 86.4 79.96 86.4ZM81.76 59.71V82.35L99.04 78.39V55.72L81.76 59.71Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="122.23" height="91.25" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
