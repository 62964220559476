import React, { useEffect, useState } from 'react';
import ItemPage from '../../Components/ItemPage';
import http from '../../service'
import MeasuresIcon from '../../images/MeasureIcon';
import * as constants from './Measure.constants';
import * as S from './Measure.style';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function Measure ({ setModal }) {
  const { id } = useParams();
  const history = useHistory();
  const router = useRouteMatch();
  const { measureFieldsState, measureFields } = constants;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [item, setItem] = useState([]);
  const [fields, setFields] = useState(measureFieldsState);
  const itemFields = measureFields({fields, id});

  useEffect(() => {
    if(id) {
      http.get(`telemetry/${id}`)
        .then(({ data }) => {
          setFields({
            reservatory: { value: { value: data.data?.watertank.id, label: data.data?.watertank.name } },
            oldEc: { value: data.data?.ec, minStd: data.data?.ecMinStd, maxStd: data.data?.ecMaxStd, recomendation: data.data?.ecRecommendation },
            ec: { value: 0 },
            oldPh: { value: data.data?.ph, minStd: data.data?.phMinStd, maxStd: data.data?.phMaxStd, recomendation: data.data?.pHRecommendation },
            ph: { value: 0 },
            temperature: { value: 0 },
            comments: { value: '' },
          })
        })
        .catch((err) => console.log(err))
    }
  }, [id])
  
  const onSubmit = () => {
    if (id) {
      http.put(`telemetry/${id}`, {
        corretedEc: Number(fields.ec.value),
        correctedPh: Number(fields.ph.value),
        correctedtemperature: Number(fields.temperature.value),
        notes: fields.comments.value,
      })
        .then(() => {
          setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    } else {
      http.post('telemetry', {
        watertankId: fields.reservatory.value.value,
        measuredAt: new Date(),
        ec: Number(fields.ec.value),
        ph: Number(fields.ph.value),
        temperature: Number(fields.temperature.value),
        notes: fields.comments.value,
      })
        .then(() => {
          setModal({ isOpen: true, text: 'Informações salvas com sucesso!', callBack: () => history.goBack() })
        })
        .catch((err) => setModal({ isOpen: true, text: err.message }))
    }
  }

  const itemPageObject = {
    ...constants, loading, error, item, fields: itemFields, onSubmit,
    setLoading, setError, icon: MeasuresIcon, setItem, setFields, onsubmit
  }

  return (
    <S.Measure>
      <ItemPage {...itemPageObject} />
    </S.Measure>
  );
};
