import React from 'react';
import * as S from './ImageModal.style';

export default function PostponeModal({ setModal, image, width, height, item }) {

  return (
    <S.ImageModal>
      <S.ImageCall></S.ImageCall>
      <S.ImageDiv>
        <S.Image src={image} width={width} height={height}  >

        </S.Image>
      </S.ImageDiv>
    </S.ImageModal>
  )
}