import React from 'react';

export default function RegisterIcon () {
  return (
    <svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M60.39 80.14H6.25C2.8 80.14 0 77.34 0 73.89C0 70.44 2.8 67.64 6.25 67.64H63.66V59.55C63.66 58.77 64.3 58.13 65.08 58.13C65.86 58.13 66.5 58.77 66.5 59.55V69.06C66.5 69.84 65.86 70.48 65.08 70.48H6.25C4.37 70.48 2.84 72.01 2.84 73.89C2.84 75.77 4.37 77.3 6.25 77.3H60.39C61.17 77.3 61.81 77.94 61.81 78.72C61.81 79.51 61.18 80.14 60.39 80.14Z" fill="#419645"/>
        <path d="M58.12 60.25H39.64C38.86 60.25 38.22 59.61 38.22 58.83C38.22 58.05 38.86 57.41 39.64 57.41H58.11C58.89 57.41 59.53 58.05 59.53 58.83C59.53 59.61 58.9 60.25 58.12 60.25Z" fill="#419645"/>
        <path d="M58.12 54.28H39.64C38.86 54.28 38.22 53.64 38.22 52.86C38.22 52.08 38.86 51.44 39.64 51.44H58.11C58.89 51.44 59.53 52.08 59.53 52.86C59.53 53.64 58.9 54.28 58.12 54.28Z" fill="#419645"/>
        <path d="M1.42 75.31C0.64 75.31 0 74.67 0 73.89V9.67C0 4.34 4.34 0 9.67 0H64.65C65.43 0 66.07 0.64 66.07 1.42V22.59C66.07 23.37 65.43 24.01 64.65 24.01C63.87 24.01 63.23 23.37 63.23 22.59V2.84H9.67C5.9 2.84 2.84 5.9 2.84 9.67V73.89C2.84 74.67 2.21 75.31 1.42 75.31Z" fill="#419645"/>
        <path d="M10.52 70.48C9.74 70.48 9.1 69.84 9.1 69.06V1.42C9.09 0.64 9.73 0 10.52 0C11.31 0 11.94 0.64 11.94 1.42V69.06C11.94 69.84 11.3 70.48 10.52 70.48Z" fill="#419645"/>
      </g>
    </svg>
  )
}
