import React from 'react';
import Field from "../Field";
import * as S from './LoginForm.style';

export default function LoginForm({ title, gridTemplate, gridTemplateExpedition, gridTemplateGreenery, qrCode, qrCodeType, fields, setFields }) {

  return (
    <>
      <S.LoginForm gridTemplate={(qrCode && qrCodeType === 'greenery' ? gridTemplateGreenery : (qrCode && qrCodeType === 'expedition' ? gridTemplateExpedition : gridTemplate))} title={title}>
        {Object.values(fields).map((field) => <Field key={field.name} {...field} setFields={setFields} />)}
      </S.LoginForm>
    </>
  )
}
