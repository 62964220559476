import React, { useState } from 'react';
import * as S from './Field.style';
import Field from '../Field';

export default function FieldSubFieldsContainer(props) {
  const { name, value, i: parentI } = props;

  return (
    <S.FieldSubFieldsContainer name={name}>
      {!!value?.length && value.map((fields, i) => (
        <FieldSubFields {...props} parentI={parentI} fields={fields} i={i} />)
      )}
    </S.FieldSubFieldsContainer>
  );
}

function FieldSubFields({ name, gridTemplate, setFields, fields, i, parentI }) {
  const [isOpen, setIsOpen] = useState(true)
  const [opened, setIsOpened] = useState(true)

  return (
    <S.FieldSubFields key={`${name}${parentI || ''}${i}`} gridTemplate={gridTemplate} isOpen={isOpen} opened={opened}>
      <S.FieldSubFieldsToggleButton
        onClick={() => {
          setTimeout(() => {
            setIsOpened(!opened)
          }, 800);
          setIsOpen(!isOpen)
        }}
      >
        {isOpen ? "ocultar detalhes" : "ver detalhes"}
      </S.FieldSubFieldsToggleButton>
      {Object.values(fields).map((field) => (
        <Field key={field.name} {...field} setFields={setFields} i={i} parentI={parentI} />
      ))}
    </S.FieldSubFields>
  );
}
