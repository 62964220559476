import React, { useState, useEffect } from 'react';
import http from '../../service'
import IndicatorsDetails from '../../Components/IndicatorsDetails';
import NurseryIcon from "../../images/NurseryIcon";
import * as S from './NurseryDetails.style';

const NurseryDetails = ({ setModal }) => {
  const [data, setData] = useState();

  useEffect(() => {
    http.get(`lot/getLotDetailDashByGreenery?stage=BERÇARIO`)
      .then(({ data }) => setData(data.data.map((item) => ({
        ...item,
        label: item.greenery_name,
        actualPercentage: item.percentualPlanted,
        plannedPercentage: item.percentualPlan,
        percentualPlan: item.percentualPlan
      }))))
      .catch((err) => setModal({ isOpen: true, text: err.message }))
  }, [])

  const tableItems = [
    { label: 'Hortaliça', name: 'label' },
    { label: 'Plantado', name: 'actualQuantity' },
    { label: 'Percentual Plantado', name: 'actualPercentage' },
    { label: 'Percentual Planejado', name: 'percentualPlan' },
  ]

  const nurseryInfo = {
    tableItems,
    label: "berçario",
    shortLabel: "Hortaliça",
    units: 485,
    color: "#1e7c4d",
    icon: NurseryIcon,
    link: '/indicadores/bercario',
    path: ['berçario'],
    data: data || []
  }
  return (
    <S.NurseryDetails>
      <IndicatorsDetails {...nurseryInfo} />
    </S.NurseryDetails>
  );
};

export default NurseryDetails;
