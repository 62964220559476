import React from "react";

export default function ManufacturerIcon() {
  return (
    <svg width="114" height="103" viewBox="0 0 114 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M111.58 102.52H24.9C24.25 102.52 23.64 102.16 23.33 101.59L5.38999 68.92C5.07999 68.35 5.09999 67.66 5.43999 67.11L23.38 38.07C23.71 37.54 24.28 37.22 24.91 37.22H111.6C112.59 37.22 113.39 38.02 113.39 39.01V100.72C113.38 101.72 112.57 102.52 111.58 102.52ZM25.96 98.93H109.79V40.81H25.9L9.03999 68.11L25.96 98.93Z" fill="#3BBC7B"/>
      <path d="M30.87 80.63C24.94 80.63 20.11 75.8 20.11 69.87C20.11 63.94 24.94 59.11 30.87 59.11C36.8 59.11 41.63 63.94 41.63 69.87C41.63 75.8 36.81 80.63 30.87 80.63ZM30.87 62.7C26.91 62.7 23.69 65.92 23.69 69.88C23.69 73.84 26.91 77.06 30.87 77.06C34.83 77.06 38.05 73.84 38.05 69.88C38.05 65.92 34.83 62.7 30.87 62.7Z" fill="#3BBC7B"/>
      <path d="M33.86 72.34C20.79 72.34 8.08997 58.61 2.23997 38.16C-1.49003 25.1 -0.540027 13.51 4.83997 6.37C7.98997 2.2 12.34 0 17.42 0C30.26 0 44.62 16.11 44.62 37.66C44.62 38.65 43.82 39.45 42.83 39.45C41.84 39.45 41.04 38.65 41.04 37.66C41.04 18.47 28.34 3.58 17.42 3.58C13.51 3.58 10.15 5.29 7.70997 8.52C3.07997 14.67 2.32997 25.38 5.69997 37.17C11.01 55.77 22.6 68.75 33.87 68.75C36.03 68.75 37.62 68.31 38.72 67.41C39.48 66.78 40.62 66.89 41.24 67.66C41.87 68.42 41.76 69.55 40.99 70.18C39.22 71.64 36.89 72.34 33.86 72.34Z" fill="#3BBC7B"/>
      <path d="M99.63 87.28H54.79C53.8 87.28 53 86.48 53 85.49C53 84.5 53.8 83.7 54.79 83.7H99.63C100.62 83.7 101.42 84.5 101.42 85.49C101.42 86.48 100.62 87.28 99.63 87.28Z" fill="#3BBC7B"/>
      <path d="M99.63 78.32H54.79C53.8 78.32 53 77.52 53 76.53V52.61C53 51.62 53.8 50.82 54.79 50.82H99.63C100.62 50.82 101.42 51.62 101.42 52.61V76.52C101.42 77.51 100.62 78.32 99.63 78.32ZM56.58 74.73H97.83V54.4H56.58V74.73Z" fill="#3BBC7B"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="113.38" height="102.52" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}
